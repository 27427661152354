import React, { FC, useState } from "react";
import { FadeUpIn } from "components/Animations";
import { CircleIconHeader } from "components/CircleIconHeader";
import { ScreenTitle } from "context/ScreenTitle";
import { CaseLookupForm } from "./CaseLookupForm";
import { useCallback } from "react";
import { CaseLookupParams } from "./model";
import { ExternalCaseViewEdit } from "./ExternalCaseViewEdit";
import { useHistory } from "react-router-dom";
import { Container } from "components/Container";

interface CaseInfoStepProps { }

export const CaseInfoStep: FC<CaseInfoStepProps> = (props) => {
  const [
    caseLookupParams,
    setCaseLookupParams,
  ] = useState<CaseLookupParams | null>(null);
  const clearQuery = useCallback(() => setCaseLookupParams(null), [
    setCaseLookupParams,
  ]);

  const history = useHistory();
  const onAppealDraftCreated = useCallback(
    (appealRequestId: string) => {
      return history.push(`/ip/drafts/${appealRequestId}/questions`);
    },
    [history]
  );

  return (
    <>
      <ScreenTitle title={["New Post-Decision Request", "Case Lookup"]} />

      <Container className="_CaseInfoStep px-4 pt-6">
        <FadeUpIn show>
          <div className="rounded-xl w-full max-w-5xl p-4 mx-auto mb-8 text-left bg-white shadow-lg">
            <CircleIconHeader icon="search">Case Lookup</CircleIconHeader>

            {caseLookupParams ? (
              <ExternalCaseViewEdit
                caseLookupParams={caseLookupParams}
                onClear={clearQuery}
                onAppealDraftCreated={onAppealDraftCreated}
              />
            ) : null}
            <section className={`mt-8 ${caseLookupParams ? "hidden" : ""}`}>
              <CaseLookupForm onSubmit={setCaseLookupParams} />
            </section>
          </div>
        </FadeUpIn>
      </Container>
    </>
  );
};
