import { FC, InputHTMLAttributes } from "react";
import { Spinner } from "../Spinner/Spinner";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  align-items: center;
  box-sizing: content-box;
`;

const HiddenCheckbox = styled.input<{ size: number }>`
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;

  + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
    padding: 2px;
    width: ${(props) => props.size * 2 - 6}px;
    height: ${(props) => props.size - 4}px;
    background-color: #d6dee6;
    border-radius: ${(props) => props.size - 2}px;
    overflow: hidden;
  }

  + label:before,
  + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: "";
  }

  + label:before {
    right: 1px;
    background-color: #e0e6eb;
    border-radius: ${(props) => props.size - 4}px;
    transition: background 400ms;
    z-index: 1;
  }

  + label:after {
    width: ${(props) => props.size - 6}px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transform: translateX(0);
    transition: transform 350ms cubic-bezier(0.15, 0.32, 0.23, 1.01);
    z-index: 2;
  }

  &:checked + label:before {
    background-color: #94ebb4;
  }
  &:checked + label:after {
    transform: translateX(${(props) => props.size - 2}px);
  }
`;

// const Switch = styled.label``;

interface LabelProps {
  size: number;
  fontSize?: number;
  checked: boolean;
}

const Label = styled.span<LabelProps>`
  margin-left: ${(props) => props.size / 4}px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.size / 2)}px;
  user-select: none;
  color: ${(props) => (props.checked ? "#42aea3" : "#aebdcb")};
`;

const SpinnerContainer = styled.div<{ size: number; show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.size - 2}px;
  overflow: hidden;
  background: rgba(204, 232, 255, 0.56);
  z-index: 3;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: scale(${(props) => (props.show ? 1 : 1.5)});
  transition: opacity 300ms, transform 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface ToggleSwitchProps {
  id: string;
  checked: boolean;
  size?: number;
  fontSize?: number;
  loading?: boolean;
  onChange: () => void;
  showLabel?: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = (props) => {
  const {
    id,
    checked,
    loading = false,
    size = 32,
    fontSize,
    onChange,
    showLabel = false,
    checkedLabel = "On",
    uncheckedLabel = "Off",
    inputProps = {},
  } = props;

  return (
    <Container>
      <HiddenCheckbox
        size={size}
        type="checkbox"
        id={id}
        onChange={onChange}
        checked={checked}
        {...inputProps}
      />
      <label id={`label-${id}`} htmlFor={id}>
        <SpinnerContainer show={loading} size={size}>
          <Spinner />
        </SpinnerContainer>
      </label>
      {showLabel && (
        <Label checked={checked} size={size} fontSize={fontSize}>
          {checked ? checkedLabel : uncheckedLabel}
        </Label>
      )}
    </Container>
  );
};
