import { ComponentType, FC, useState } from "react";
import eviCoreLogoSrc from "images/eviCore_small_logo.png";
import { useLayout } from "./Layout";
import { Link, useRouteMatch } from "react-router-dom";
import {
  HomeIcon,
  UsersIcon,
  FolderIcon,
  CalendarIcon,
  InboxIcon,
  ChartBarIcon,
  XMarkIcon as XIcon,
  ArrowLeftOnRectangleIcon as LogoutIcon,
  BoltIcon,
  CogIcon,
  ExclamationCircleIcon,
  CommandLineIcon,
  SignalIcon,
} from '@heroicons/react/24/outline'
import { FadeInOut, SlideInRightOut } from "components/Animations";
import { useAuth } from "context/AuthContext";
import avatarSrc from "images/default_avatar.png";
import { maybePluralize } from "utils/maybePluralize";
import { OnlineAgentsDrawer } from "components/OnlineAgentsDrawer";
import { useOnlineAgents } from "hooks/useOnlineAgents";
import { LogStreamDrawer } from "components/LogStreamDrawer";
import { Tooltip } from "components/Tooltip";

export type IconName =
  | "home"
  | "users"
  | "folder"
  | "calendar"
  | "inbox"
  | "chartBar"
  | "lightningBolt"
  | "cog"
  | "exclamationCircle"
  | "signal";

const iconMap: Record<IconName, ComponentType<{ className?: string }>> = {
  home: HomeIcon,
  users: UsersIcon,
  folder: FolderIcon,
  calendar: CalendarIcon,
  inbox: InboxIcon,
  chartBar: ChartBarIcon,
  lightningBolt: BoltIcon,
  cog: CogIcon,
  exclamationCircle: ExclamationCircleIcon,
  signal: SignalIcon
};

/**
 * SidebarLink.
 */
interface SidebarLinkProps {
  to: string;
  icon: IconName | ComponentType<{ className?: string }>;
}

export const SidebarLink: FC<SidebarLinkProps> = (props) => {
  const { to, icon, children } = props;
  const Icon = typeof icon === "string" ? iconMap[icon] : icon;
  const match = useRouteMatch(to);
  const isActive = !!match;

  // {/* <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" --> */}
  const linkClassNames = isActive
    ? "bg-gray-100 text-gray-900"
    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900";

  // {/* <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" --> */}
  const iconClassNames = isActive
    ? "text-gray-500"
    : "text-gray-400 group-hover:text-gray-500";

  return (
    <Link
      to={to}
      className={`${linkClassNames} group flex items-center px-2 py-2 text-base md:text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-100`}
    >
      {/* <!-- Heroicon name: home --> */}
      <Icon className={`${iconClassNames} mr-4 md:mr-3 h-6 w-6`} />
      {children}
    </Link>
  );
};

/**
 * Sidebar.
 */

interface SidebarProps {
  userDisplayName: string;
}

type ActiveDrawer = "ONLINE_AGENTS" | "LOG_STREAM";

export const Sidebar: FC<SidebarProps> = (props) => {
  const { userDisplayName, children } = props;
  const { sidebarOpen, toggleSidebar } = useLayout();
  const { logout } = useAuth();

  const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer | null>(null);

  const agents = useOnlineAgents();
  const onlineAgentCount = agents.length;

  return (
    <>
      <OnlineAgentsDrawer
        isOpen={activeDrawer === "ONLINE_AGENTS"}
        onClose={() => setActiveDrawer(null)}
      />
      <LogStreamDrawer
        isOpen={activeDrawer === "LOG_STREAM"}
        onClose={() => setActiveDrawer(null)}
      />
      <div className="md:hidden">
        <div
          className={`${sidebarOpen ? "pointer-events-auto" : "pointer-events-none"
            } md:hidden fixed inset-0 flex z-40`}
        >
          <FadeInOut show={sidebarOpen} className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </FadeInOut>
          <SlideInRightOut
            show={sidebarOpen}
            className="relative flex flex-col flex-1 w-full max-w-xs bg-white"
          >
            <div className="absolute top-0 right-0 pt-2 -mr-12">
              {sidebarOpen ? (
                <button
                  className="focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white flex items-center justify-center w-10 h-10 ml-1 rounded-full"
                  onClick={toggleSidebar}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="w-6 h-6 text-white" />
                </button>
              ) : null}
            </div>
            <div className="flex-1 h-0 pt-2 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-auto h-16 mx-auto"
                  src={eviCoreLogoSrc}
                  alt="eviCore PDRI"
                />
              </div>
              <nav className="px-2 mt-5 space-y-1">{children}</nav>
            </div>
            <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
              <div className="group flex-shrink-0 block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block w-10 h-10 rounded-full"
                      src={avatarSrc}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="group-hover:text-gray-900 text-base font-medium text-gray-700">
                      {userDisplayName}
                    </p>
                    <button
                      type="button"
                      onClick={logout}
                      className="group-hover:text-gray-700 block text-sm font-medium text-gray-500"
                    >
                      Sign Out <LogoutIcon className="inline-block h-3 ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SlideInRightOut>

          <div className="w-14 flex-shrink-0">
            {/* <!-- Force sidebar to shrink to fit close icon --> */}
          </div>
        </div>
      </div>

      {/* <!-- Static sidebar for desktop --> */}
      <div className="md:flex md:flex-shrink-0 hidden">
        <div className="flex flex-col w-64">
          {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
          <div className="flex flex-col flex-1 h-0 bg-white border-r border-gray-200">
            <div className="flex flex-col flex-1 pt-2 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="w-auto h-16 mx-auto"
                  src={eviCoreLogoSrc}
                  alt="eviCore PDRI"
                />
              </div>
              <nav className="flex-1 px-2 mt-5 space-y-1 bg-white">
                {children}
              </nav>
            </div>
            <div className="flex items-center justify-center flex-shrink-0 p-3">
              <button
                type="button"
                className="hover:bg-gray-50 flex items-center p-2 space-x-3 transition-colors duration-100 border rounded-md shadow-md"
                onClick={() => setActiveDrawer("ONLINE_AGENTS")}
              >
                <span
                  className="flex items-center justify-center w-4 h-4 bg-green-100 rounded-full"
                  aria-hidden="true"
                >
                  <span className="w-2 h-2 bg-green-400 rounded-full"></span>
                </span>

                <span className="block text-sm font-medium">
                  {onlineAgentCount} Online{" "}
                  {maybePluralize("Agent", onlineAgentCount)}
                </span>
              </button>
              <Tooltip tip="Log Stream">
                <button
                  type="button"
                  className="hover:bg-gray-50 flex items-center p-2 ml-2 space-x-3 transition-colors duration-100 border rounded-md shadow-md"
                  onClick={() => setActiveDrawer("LOG_STREAM")}
                >
                  <CommandLineIcon className="w-5 h-5" />
                </button>
              </Tooltip>
            </div>
            <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
              <div className="group flex-shrink-0 block w-full">
                <div className="flex items-center">
                  <div>
                    <img
                      className="h-9 w-9 inline-block rounded-full"
                      src={avatarSrc}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="group-hover:text-gray-900 text-sm font-medium text-gray-700">
                      {userDisplayName}
                    </p>
                    <button
                      type="button"
                      onClick={logout}
                      className="group-hover:text-gray-700 flex items-center text-xs font-medium text-gray-500"
                    >
                      Sign Out <LogoutIcon className="inline-block h-3 ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
