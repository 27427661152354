import { FC } from "react";
import { FAIcon } from "components/FAIcon";
import { Link } from "react-router-dom";

interface BackLinkProps {
  to: string;
  label: string
};

export const BackLink: FC<BackLinkProps> = (props) => {
  const { to, label } = props;

  return (
    <Link
      to={to}
      className="text-blue-600 border-b border-blue-600 border-dashed"
    >
      <span className="mr-2">
        <FAIcon icon="chevron-left" />
      </span>{" "}
      {label}
    </Link>
  );
};
