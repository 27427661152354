import { FC } from "react";
import { Button } from "components/Button";

interface SSOSignInFormProps {
  onPasswordClick(): void;
}

export const SSOSignInForm: FC<SSOSignInFormProps> = (props) => {
  const { onPasswordClick } = props;

  return (
    <>
      <div className="_SSOSignInForm sm:rounded-2xl sm:px-10 px-4 py-8 bg-white shadow-xl">
        <span className="block w-full rounded-md shadow-sm">
          <Button type="button" color="blue" className="justify-center w-full" onClick={() => window.location.href = "/oauth/evicore"}>
            Sign in with SSO
          </Button>
        </span>
      </div>
      <div className="mt-4 text-sm">
        <p className="max-w mt-1 text-sm leading-5 text-center text-gray-600">
          <button
            type="button"
            onClick={onPasswordClick}
            className="hover:text-blue-700 inline-block ml-2 font-semibold text-blue-500"
          >
            Sign in with email
          </button>
        </p>
      </div>
    </>
  );
};
