import { useState, useCallback } from "react";

interface Actions<T extends string = string> {
  setState: React.Dispatch<React.SetStateAction<T[]>>;
  activate(switchName: T): void;
  deactivate(switchName: T): void;
  toggle(switchName: T): void;
  isActive(switchName: T): boolean;
}

export function useToggles<T extends string = string>(
  initialState: T[] = []
): [T[], Actions<T>] {
  const [activeSwitches, setState] = useState<T[]>(initialState);

  const activate = useCallback(
    (switchName: T) => {
      return setState((state) => {
        if (state.includes(switchName)) {
          return state;
        } else {
          return [...state, switchName];
        }
      });
    },
    [setState]
  );

  const deactivate = useCallback(
    (switchName: T) => {
      return setState((state) => state.filter((s) => s !== switchName));
    },
    [setState]
  );

  const toggle = useCallback(
    (switchName: T) => {
      return setState((state) =>
        state.includes(switchName)
          ? state.filter((s) => s !== switchName)
          : [...state, switchName]
      );
    },
    [setState]
  );

  const isActive = useCallback(
    (switchName: T) => activeSwitches.includes(switchName),
    [activeSwitches]
  );

  return [
    activeSwitches,
    {
      setState,
      activate,
      deactivate,
      toggle,
      isActive,
    },
  ];
}
