import { FC } from "react";
import { useField, ErrorMessage } from "formik";
import { ToggleSwitch, ToggleSwitchProps } from "components/ToggleSwitch";
import { VerticalField, HorizontalField } from "components/FieldStructure";

/**
 * ToggleSwitchInput.
 */

interface ToggleSwitchInputProps {
  id?: string;
  name: string;
  toggleSwitchProps?: Partial<ToggleSwitchProps>;
}

export const ToggleSwitchInput: FC<ToggleSwitchInputProps> = (props) => {
  const { id, name, toggleSwitchProps = {} } = props;

  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  function handleChange() {
    setValue(!value);
  }

  return (
    <>
      <ToggleSwitch
        id={id || name}
        checked={!!value}
        onChange={handleChange}
        {...toggleSwitchProps}
      />
      <ErrorMessage component="p" name={name} className="help is-danger" />
    </>
  );
};

/**
 * ToggleSwitchField.
 */

interface ToggleSwitchFieldProps extends ToggleSwitchInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const ToggleSwitchField: FC<ToggleSwitchFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <ToggleSwitchInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalToggleSwitchField: FC<ToggleSwitchFieldProps> = (
  props
) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <ToggleSwitchInput {...rest} />
    </HorizontalField>
  );
};
