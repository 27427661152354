export function sortBy<T extends unknown>(
  initArray: T[],
  attrOrFn: keyof T | ((elem: T) => number)
): T[] {
  // Clone so we can mutate in place
  const array = [...initArray];
  return array.sort((a, b) => {
    const left = typeof attrOrFn === "function" ? attrOrFn(a) : a[attrOrFn];
    const right = typeof attrOrFn === "function" ? attrOrFn(b) : b[attrOrFn];
    return left <= right ? -1 : 1;
  });
}
