import React, { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { DownloadSLAComplianceForm } from "./DownloadSLAComplianceForm";

interface DownloadSLAComplianceModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const DownloadSLAComplianceModal: FC<DownloadSLAComplianceModalProps> = (
  props
) => {
  const { isOpen, onClose } = props;

  return (
    <Modal
      className="_DownloadSLAComplianceModal"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalHeader
        icon="file-download"
        title="Export SLA Compliance Report"
        onClose={onClose}
      />
      <div className="p-4">
        <DownloadSLAComplianceForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
