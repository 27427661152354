import React, { FC } from "react";

interface ProfileSettingsScreenProps {}

export const ProfileSettingsScreen: FC<ProfileSettingsScreenProps> = (
  props
) => {
  return (
    <section aria-labelledby="payment_details_heading">
      <form action="#" method="POST">
        <div className="sm:rounded-md sm:overflow-hidden shadow">
          <div className="sm:p-6 px-4 py-6 bg-white">
            <div>
              <h2
                id="payment_details_heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Profile details
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Update your profile information.
              </p>
            </div>

            <div className="grid grid-cols-4 gap-6 mt-6">
              <div className="sm:col-span-2 col-span-4">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="cc-given-name"
                  className="focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm"
                />
              </div>

              <div className="sm:col-span-2 col-span-4">
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  autoComplete="cc-family-name"
                  className="focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm"
                />
              </div>

              <div className="sm:col-span-2 col-span-4">
                <label
                  htmlFor="email_address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  type="text"
                  name="email_address"
                  id="email_address"
                  autoComplete="email"
                  className="focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 sm:px-6 px-4 py-3 text-right">
            <button
              type="submit"
              className="hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-800 border border-transparent rounded-md shadow-sm"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};
