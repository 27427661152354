import { FC, useCallback } from "react";
import { gql, useQuery } from '@apollo/client';
import { ConfirmationModal } from "components/ConfirmationModal";
import { Spinner } from "components/Spinner";
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Badge } from "components/Badge";

const APPEAL_REQUEST_PD_OPTIONS = gql`
  query AppealRequestPDOptions($id: UUID4!) {
    appealRequest(id: $id) {
      id
      postDecisionOptions
    }
  }
`;

interface Data {
  appealRequest: {
    id: string;
    postDecisionOptions: null | JSONObject;
  }
}


interface PostDecisionOptionsModalProps {
  appealRequestId: string;
  isOpen: boolean;
  onClose(): void;
};

export const PostDecisionOptionsModal: FC<PostDecisionOptionsModalProps> = props => {
  const { appealRequestId, isOpen, onClose } = props;

  const { data, loading, error } = useQuery<Data>(APPEAL_REQUEST_PD_OPTIONS, { variables: { id: appealRequestId } });

  const onSubmit = useCallback(() => {
    return Promise.resolve().then(() => onClose());
  }, [onClose]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      color="blue"
      icon={ExclamationCircleIcon}
      title="Post-Decision Options"
      prompt="Continue"
      onSubmit={onSubmit}
    >
      {
        loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.appealRequest ? (
          <p>Failed to load.</p>
        ) : !data.appealRequest.postDecisionOptions ? (
          <p>Unknown post-decision options eligibility.</p>
        ) : (
          <>
            <p className="text-sm text-gray-500">
              Please review the post-decision options for this request below.
            </p>
            <div className="pt-3">
              {formatOptions(data.appealRequest.postDecisionOptions).map((option, i) => (
                <div key={option.label} className="flex py-1">
                  <p>{option.label}</p>
                  <div className="flex-grow border-b border-dotted" />
                  {option.value ? (
                    <Badge color="green">Yes</Badge>
                  ) : (
                    <Badge color="red">No</Badge>
                  )}
                </div>
              ))}
            </div>
          </>

        )
      }
    </ConfirmationModal>
  );
};

const labels = {
  "Appeal": "Appeal",
  "HealthplanAppeal": "Health Plan Appeal",
  "LevelTwoDelegated": "Level 2 Delegated",
  "MemberAppeal": "Member Appeal",
  "P2PL1AppealAvailable": "P2P Level 1 Appeal",
  "P2PL2AppealAvailable": "P2P Level 2 Appeal",
  "P2PReconsideration": "P2P Reconsideration",
  "ProviderAppeal": "Provider Appeal",
  "ReOpenHP": "Re-Open Health Plan",
  "Restart": "Restart",
  "VerbalAppeal": "Verbal Appeal",
  "VerbalReconsideration": "Verbal Reconsideration",
  "WebAppealL1Available": "Web Appeal Level 1",
  "WebAppealL2Available": "Web Appeal Level 2",
  "WebReconAvailable": "Web Reconsideration",
  "WrittenReconsideration": "Written Reconsideration",
}

function formatOptions(options: JSONObject | null) {
  let results = [];
  for (let key in options) {
    if (labels[key]) {
      results.push({ label: labels[key], value: toBool(options[key]) });
    }
  }
  return results;
}

function toBool(value: any) {
  if (typeof value === "string") {
    return value === "true" || value.includes("Y") || value.includes("y");
  } else if (typeof value === "boolean") {
    return value;
  } else {
    return false
  }
}
