import { FC } from "react";
import { useScreenTitle } from "context/ScreenTitle";
import { useLayout } from "./Layout";
import { Bars3Icon } from '@heroicons/react/24/outline'

interface PageContentProps { }

export const PageContent: FC<PageContentProps> = (props) => {
  const { children } = props;
  const { toggleSidebar } = useLayout();
  const { titleString } = useScreenTitle();

  return (
    <div className="flex flex-col flex-1 w-0 overflow-hidden">
      <div className="md:hidden sm:pl-3 sm:pt-3 pt-1 pl-1">
        <button
          className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={toggleSidebar}
        >
          <span className="sr-only">Open sidebar</span>
          {/* <!-- Heroicon name: menu --> */}
          <Bars3Icon className="inline-block w-6 h-6" />
        </button>
      </div>
      <main
        className="focus:outline-none relative z-0 flex-1 overflow-y-auto"
        tabIndex={0}
      >
        <div className="py-6">
          <div className="max-w-7xl sm:px-6 md:px-8 px-4 mx-auto">
            <h1 className="text-2xl font-semibold text-gray-900">
              {titleString}
            </h1>
          </div>
          {/* <div className="max-w-7xl sm:px-6 md:px-8 px-4 mx-auto"> */}
          <div>
            {/* <!-- Replace with your content --> */}
            <div className="py-4">
              {/* <div className="h-96 border-4 border-gray-200 border-dashed rounded-lg"></div> */}
              {children}
            </div>
            {/* <!-- /End replace --> */}
          </div>
        </div>
      </main>
    </div>
  );
};
