import { FC } from "react";
import ReactModal from "react-modal";
import cx from "classnames";

type DrawerWidth =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "full";

interface DrawerProps {
  isOpen: boolean;
  onRequestClose(): void;
  width: DrawerWidth;
  className?: string;
  overlayClassName?: string;
  closeTimeoutMS?: number;
}

export const Drawer: FC<DrawerProps> = (props) => {
  const {
    isOpen,
    onRequestClose,
    width = "sm",
    overlayClassName,
    className,
    children,
  } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={{
        base: cx(
          className,
          "z-10 fixed inset-y-0 right-0 m-0 w-full shadow-lg transform transition duration-500 sm:duration-700 ease-in-out overflow-y-auto translate-x-full",
          {
            "bg-white": !(className && className.includes("bg-")),
            "max-w-xs": width === "xs",
            "max-w-sm": width === "sm",
            "max-w-md": width === "md",
            "max-w-lg": width === "lg",
            "max-w-xl": width === "xl",
            "max-w-2xl": width === "2xl",
            "max-w-3xl": width === "3xl",
            "max-w-4xl": width === "4xl",
            "max-w-5xl": width === "5xl",
            "max-w-6xl": width === "6xl",
            "max-w-full": width === "full",
          }
        ),
        afterOpen: isOpen ? "seriously-translate-x-0" : "",
        beforeClose: "translate-x-full",
      }}
      overlayClassName={cx("Overlay", overlayClassName)}
      closeTimeoutMS={300}
    >
      <div className="relative z-10">
        <div>{children}</div>
      </div>
    </ReactModal>
  );
};
