import { FC, useState } from "react";
import { PasswordSignInForm } from "./PasswordSignInForm";
import { SSOSignInForm } from "./SSOSignInForm";
import logoSrc from "images/eviCore_big_logo.png";
import { useFriendlyForward } from "./useFriendlyForward";
import { useCallback } from "react";

interface SignInScreenProps { }

type SignInVersion = "SSO" | "PASSWORD";

export const SignInScreen: FC<SignInScreenProps> = () => {
  const [version, setVersion] = useState<SignInVersion>("SSO");

  const goToSSO = useCallback(() => setVersion("SSO"), []);
  const goToPassword = useCallback(() => setVersion("PASSWORD"), []);

  useFriendlyForward();

  return (
    <div className="sm:px-6 lg:px-8 flex flex-col justify-center min-h-screen py-12 bg-gray-100">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="w-48 mx-auto" src={logoSrc} alt="Preferral Logo" />
        <h2 className="mt-6 text-3xl font-extrabold leading-9 text-center text-gray-900">
          Post-Decision Requests Inventory
        </h2>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md mt-8">
        {version === "PASSWORD" ? (
          <PasswordSignInForm onSSOClick={goToSSO} />
        ) : (
          <SSOSignInForm onPasswordClick={goToPassword} />
        )}
      </div>
    </div>
  );
};
