import { useEffect } from "react";
import { Handler } from "utils/reactHelpers";

const ESCAPE = 27;

/**
 * Mutable global state to keep track of all the callbacks that are triggered after an 'Escape' press.
 * We always want to call *only* the latest registered callback.
 *
 * Consider an open dropdown in a modal.
 * The first 'escape' press should close the dropdown,
 * and the modal should only be closed on a second 'Escape' press (assuming no other dropdowns opened in the meantime).
 */
const escapeHookCallbacks: Array<Handler> = [];

export function useOnEscape(isOpen: boolean, cb: Handler) {
  useEffect(() => {
    if (!isOpen) return;

    function onKeydown(evt: KeyboardEvent) {
      if (evt.keyCode !== ESCAPE) return;

      const isLastCallback =
        escapeHookCallbacks.length > 0 &&
        escapeHookCallbacks[escapeHookCallbacks.length - 1] === cb;

      if (isLastCallback) cb();
    }

    window.addEventListener("keydown", onKeydown);
    escapeHookCallbacks.push(cb);

    return () => {
      window.removeEventListener("keydown", onKeydown);
      const index = escapeHookCallbacks.indexOf(cb);
      if (index >= 0) escapeHookCallbacks.splice(index, 1);
    };
  }, [isOpen, cb]);
}
