import { Button } from "components/Button";
import { CircleIcon } from "components/CircleIcon";
import { FAIcon } from "components/FAIcon";
import { NoResults } from "components/NoResults";
import React, { FC } from "react";

interface AppealVolumeProps { }

export const AppealVolume: FC<AppealVolumeProps> = (props) => {
  return (
    <div className="_AppealVolume bg-white border rounded-lg shadow-xl">
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CircleIcon icon="chart-line" />
            <p className="ml-4 text-xl font-semibold">Post-Decision Request Volume</p>
          </div>
          <Button kind="secondary" color="gray" className="border">
            Export CSV
            <span className="ml-2">
              <FAIcon icon="file-download" />
            </span>
          </Button>
        </div>

        <div className="pt-8 text-center">
          <NoResults icon="chart-line" text="Not enough data to display" />
        </div>
      </div>
    </div>
  );
};
