import { useCallback, useEffect, useRef, useState } from "react";
import { stringify } from "utils/stringify";
import { useTimeout } from "./useTimeout";

// const cache =

interface FakeQueryOptionsModel<T extends any> {
  data?: T;
  timeout?: number;
  variables?: JSONObject;
}

const defaultOpts = {
  data: {},
  timeout: 250,
  variables: {},
};

export function useFakeQuery<T extends JSONObject>(
  query: string,
  argOpts: FakeQueryOptionsModel<T> = {}
) {
  const opts = { ...defaultOpts, ...argOpts };
  const { data: resData, timeout, variables } = opts;

  const [loading, setLoading] = useState(true);
  const cache = useRef<Record<string, JSONObject>>({});
  const [data, setData] = useState<T | null>(null);
  const setTimeout = useTimeout();

  const cacheKey = stringify({
    query,
    variables,
  });

  useEffect(() => {
    if (!!cache.current[cacheKey]) {
      setLoading(false);
      setData(resData as T);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setData(() => resData as T);
        cache.current[cacheKey] = resData;
      }, timeout);
    }
  }, [cacheKey, setTimeout, timeout, resData]);

  return { data, loading };
}

export function useFakeLazyQuery<T extends JSONObject>(
  query: string,
  argOpts: FakeQueryOptionsModel<T> = {}
) {
  const opts = { ...defaultOpts, ...argOpts };
  const { data: resData, timeout, variables } = opts;

  const [loading, setLoading] = useState(false);
  const cache = useRef<Record<string, JSONObject>>({});
  const [data, setData] = useState<T | null>(null);
  const setTimeout = useTimeout();

  const cacheKey = stringify({
    query,
    variables,
  });

  const doLoad = useCallback(() => {
    if (!!cache.current[cacheKey]) {
      setLoading(false);
      setData(resData as T);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setData(() => resData as T);
        cache.current[cacheKey] = resData;
      }, timeout);
    }
  }, [cacheKey, setTimeout, timeout, resData]);

  return [doLoad, { data, loading }];
}
