import { createContext, FC, useContext, useMemo, useReducer } from "react";
import uuid from "uuid-random";
import { useComponentId } from "../../hooks/useComponentId";
import { ServerFileModel, UploadModel } from "./model";

type InstanceId = string;

interface InstanceState {
  uploads: Record<string, UploadModel>;
}

type CoreState = Record<InstanceId, InstanceState>;

interface DerivedInstanceState extends InstanceState {
  isUploading: boolean;
}

type DerivedState = Record<InstanceId, DerivedInstanceState>;

interface UpdateFactory {
  (instanceId: string): InstanceUpdate;
}

interface InstanceUpdate {
  filesAdded(files: File[]): void;
  uploadStarted(fileId: string): void;
  uploadProgressed(fileId: string, progress: number): void;
  uploadCompleted(fileId: string, remoteRef?: ServerFileModel): void;
  uploadFailed(fileId: string): void;
  uploadAborted(fileId: string): void;
  uploadRemoved(fileId: string): void;
}

const FileUploaderStateContext = createContext<DerivedState | undefined>(
  undefined
);
const FileUploaderUpdateContext = createContext<UpdateFactory | undefined>(
  undefined
);

const initialInstanceState: InstanceState = {
  uploads: {},
};

const initialState: CoreState = {};

type Action =
  | { instanceId: string; type: "FILES_ADDED"; files: File[] }
  | { instanceId: string; type: "UPLOAD_STARTED"; fileUploadId: string }
  | {
      instanceId: string;
      type: "UPLOAD_PROGRESSED";
      fileUploadId: string;
      progress: number;
    }
  | {
      instanceId: string;
      type: "UPLOAD_COMPLETED";
      fileUploadId: string;
      remoteRef?: ServerFileModel;
    }
  | { instanceId: string; type: "UPLOAD_FAILED"; fileUploadId: string }
  | { instanceId: string; type: "UPLOAD_ABORTED"; fileUploadId: string }
  | { instanceId: string; type: "UPLOAD_REMOVED"; fileUploadId: string };

function putIn(
  obj: any = {},
  fields: string[] = [],
  value: any = null,
  setRecursively: boolean = true
) {
  return fields.reduce((a, b, level) => {
    const isEnd = level + 1 === fields.length;
    if (setRecursively && typeof a[b] === "undefined" && !isEnd) {
      a[b] = {};
      return a[b];
    }

    if (isEnd) {
      a[b] = value;
      return value;
    }
    return a[b];
  }, obj);
}

function getIn(obj: any = {}, fields: string[] = []): any {
  const [head, ...rest] = fields;
  return !rest.length ? obj[head] : getIn(obj[head], rest);
}

function fileUploaderReducer(prevState: CoreState, action: Action): CoreState {
  const { instanceId } = action;

  function updateFileUpload(
    fileUploadId: string,
    attrs: Partial<UploadModel>
  ): CoreState {
    let nextState = { ...prevState };
    let prevUploadState =
      getIn(nextState, [instanceId, "uploads", fileUploadId]) || {};
    putIn(nextState, [instanceId, "uploads", fileUploadId], {
      ...prevUploadState,
      ...attrs,
    });
    return nextState;
  }

  switch (action.type) {
    case "FILES_ADDED": {
      let nextState = { ...prevState };
      for (const file of action.files) {
        const fileUploadId = uuid();
        putIn(nextState, [instanceId, "uploads", fileUploadId], {
          localId: fileUploadId,
          remoteRef: null,
          file,
          status: "ADDED",
          progress: 0,
        });
      }
      return nextState;
    }
    case "UPLOAD_STARTED": {
      return updateFileUpload(action.fileUploadId, {
        status: "IN_PROGRESS",
        progress: 0,
      });
    }
    case "UPLOAD_PROGRESSED": {
      return updateFileUpload(action.fileUploadId, {
        progress: action.progress,
      });
    }
    case "UPLOAD_COMPLETED": {
      return updateFileUpload(action.fileUploadId, {
        status: "COMPLETED",
        progress: 1,
        remoteRef: action.remoteRef,
      });
    }
    case "UPLOAD_FAILED": {
      return updateFileUpload(action.fileUploadId, {
        status: "FAILED",
      });
    }
    case "UPLOAD_REMOVED": {
      const nextState = { ...prevState };
      const uploadsState = nextState[instanceId].uploads;
      delete uploadsState[action.fileUploadId];
      putIn(nextState, [instanceId, "uploads"], uploadsState);
      return nextState;
    }
    default:
      return prevState;
  }
}

function deriveInstanceState(
  instanceStateArg: InstanceState | undefined
): DerivedInstanceState {
  const instanceState = instanceStateArg || initialInstanceState;
  const uploads = Object.values(instanceState.uploads);

  const isUploading = uploads.some((u) =>
    ["ADDED", "IN_PROGRESS"].includes(u.status)
  );

  return {
    ...instanceState,
    isUploading,
  };
}

/**
 * FileUploaderProvider
 */

export const FileUploaderProvider: FC = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(fileUploaderReducer, initialState);

  const updateFactory: UpdateFactory = useMemo(() => {
    return (instanceId: string) => ({
      filesAdded(files: File[]) {
        dispatch({ instanceId, type: "FILES_ADDED", files });
      },
      uploadStarted(fileUploadId: string) {
        dispatch({ instanceId, type: "UPLOAD_STARTED", fileUploadId });
      },
      uploadProgressed(fileUploadId: string, progress: number) {
        dispatch({
          instanceId,
          type: "UPLOAD_PROGRESSED",
          fileUploadId,
          progress,
        });
      },
      uploadCompleted(fileUploadId: string, remoteRef?: ServerFileModel) {
        dispatch({
          instanceId,
          type: "UPLOAD_COMPLETED",
          fileUploadId,
          remoteRef,
        });
      },
      uploadFailed(fileUploadId: string) {
        dispatch({ instanceId, type: "UPLOAD_FAILED", fileUploadId });
      },
      uploadAborted(fileUploadId: string) {
        dispatch({ instanceId, type: "UPLOAD_ABORTED", fileUploadId });
      },
      uploadRemoved(fileUploadId: string) {
        dispatch({ instanceId, type: "UPLOAD_REMOVED", fileUploadId });
      },
    });
  }, [dispatch]);

  let derivedState: DerivedState = {};
  for (const instanceId in state) {
    derivedState[instanceId] = deriveInstanceState(state[instanceId]);
  }

  return (
    <FileUploaderStateContext.Provider value={derivedState}>
      <FileUploaderUpdateContext.Provider value={updateFactory}>
        {children}
      </FileUploaderUpdateContext.Provider>
    </FileUploaderStateContext.Provider>
  );
};

/**
 * useFileUploaderCtx()
 */
export function useFileUploaderCtx() {
  const stateCtx = useContext(FileUploaderStateContext);
  const updateCtx = useContext(FileUploaderUpdateContext);
  if (stateCtx === undefined || updateCtx === undefined) {
    throw new Error(
      "useFileUploaderCtx must be used within a FileUploaderProvider"
    );
  }
  return {
    state: stateCtx,
    updaterFactory: updateCtx,
  };
}

interface FileUploaderHookOptions {
  instanceId?: InstanceId;
}

/**
 * useFileUploader()
 */
export function useFileUploader(options: FileUploaderHookOptions) {
  const componentId = useComponentId();
  const instanceId = (options.instanceId || componentId) as InstanceId;
  const { state, updaterFactory } = useFileUploaderCtx();

  const derivedState = deriveInstanceState(state[instanceId]);
  const actions = useMemo(() => updaterFactory(instanceId), [
    instanceId,
    updaterFactory,
  ]);

  return {
    state: derivedState,
    actions,
  };
}
