import React, { FC } from "react";
import { useChannelPresence } from "context/ChannelContext";
import { Avatar } from "components/Avatar";

interface CurrentViewingProps {
  appealRequestId: string;
}

const MAX_BUBBLES = 4;

export const CurrentViewing: FC<CurrentViewingProps> = (props) => {
  const { appealRequestId } = props;
  const presence = useChannelPresence(`appeal_request:${appealRequestId}`) || {};
  const agents = Object.keys(presence).map((id) => presence[id].agent);

  const agentBubbles = agents.slice(0, MAX_BUBBLES);
  const moreCount = agents.length - agentBubbles.length;

  return (
    <div className="CurrentViewing">
      <p className="p-2 text-xs font-semibold text-gray-500">CurrentViewing</p>

      <div className="flex items-center space-x-3">
        <div className="relative z-0 flex -space-x-1 overflow-hidden">
          {agentBubbles.map((a) => (
            <Avatar
              key={a.id}
              name={`${a.first_name} ${a.last_name}`}
              imageClassName="h-8 w-8"
            />
          ))}
        </div>
        {moreCount ? <p>+ {moreCount} more</p> : null}
      </div>
      <pre className="text-sm">{JSON.stringify(presence, null, 2)}</pre>
    </div>
  );
};
