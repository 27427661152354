import { FC, useCallback } from "react";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextAreaField } from "components/formik/TextAreaField";
import { Formik } from "formik";
import { Button } from "components/Button";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";

const IMPORT_SS_RULES = gql`
  mutation ImportSimilarSpecialtyRules($tsv: String!) {
    importSimilarSpecialtyRules(tsv: $tsv) {
      errors {
        key
        message
      }
      success
    }
  }
`;

interface MutationData {
  importSimilarSpecialtyRules: {
    errors?: InputError[];
    success?: boolean;
  };
}

interface FormValues {
  tsv: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    tsv: Yup.string().required("Required"),
  })
  .required();

interface ImportSSRulesFormProps {
  onSuccess(): void;
}

export const ImportSSRulesForm: FC<ImportSSRulesFormProps> = props => {
  const { onSuccess } = props;

  const [importSSRulesMut] = useMutation<MutationData>(IMPORT_SS_RULES);

  const onSubmit = useCallback(
    async (values: FormValues, { setStatus }) => {
      setStatus({ errors: null });

      const { data } = await importSSRulesMut({
        variables: {
          tsv: values.tsv,
        },
      });

      if (data?.importSimilarSpecialtyRules.errors) {
        setStatus({ errors: data.importSimilarSpecialtyRules.errors });
      } else if (data?.importSimilarSpecialtyRules.success) {
        toast.success("Rules imported successfully");
        onSuccess();
      }
    },
    [importSSRulesMut, onSuccess]
  );

  return (
    <Formik
      initialValues={{ tsv: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <div className="mt-3">
            <TextAreaField
              label="TSV Data"
              name="tsv"
              placeholder="Paste TSV data here"
              minRows={10}
            />
          </div>

          <div className="p-4 text-center">
            <Button
              type="submit"
              color="gold"
              size="lg"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Import Rules
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
