import { FC, ComponentType } from "react";
import {
  ClipboardDocumentListIcon,
  UserIcon,
  PencilSquareIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline'

type IconName =
  | "user"
  | "search"
  | "pencilAlt"
  | "clipboardList"
  | "calendar"
  | "checkCircle"
  | "check";

const iconMap: Record<IconName, ComponentType<{ className?: string }>> = {
  user: UserIcon,
  search: MagnifyingGlassIcon,
  pencilAlt: PencilSquareIcon,
  clipboardList: ClipboardDocumentListIcon,
  calendar: CalendarIcon,
  checkCircle: CheckCircleIcon,
  check: CheckIcon,
};

/**
 * NavigationSteps.
 */

export interface NavigationStepModel {
  label: string;
  icon: IconName;
}

interface NavigationStepsProps {
  currentStep: number;
  steps: NavigationStepModel[];
}

export const NavigationSteps: FC<NavigationStepsProps> = (props) => {
  const { currentStep, steps } = props;

  function getStepState(stepIndex: number) {
    if (currentStep === stepIndex) return "selected";
    if (stepIndex < currentStep) return "active";
    return "inactive";
  }

  return (
    <div className="_NavigationSteps flex items-center pb-8">
      {steps.map((step, idx) => (
        <NavigationStep
          key={step.label}
          state={getStepState(idx)}
          step={step}
          showSeparator={idx < steps.length - 1}
        />
      ))}
    </div>
  );
};

/**
 * NavigationStepBackground.
 */

type NavigationStepState = "inactive" | "active" | "selected";

interface NavigationStepBackgroundProps {
  state: NavigationStepState;
}

const bgClassNames: Record<NavigationStepState, string> = {
  active: "bg-green-100 text-green-500 shadow-inner border-green-300",
  selected: "bg-gold-600 text-white",
  inactive: "bg-gray-100 text-gray-400",
};

const NavigationStepBackground: FC<NavigationStepBackgroundProps> = (props) => {
  const { state, children } = props;

  const cn = [bgClassNames[state], state !== "active" && "border-transparent"]
    .filter(Boolean)
    .join(" ");

  return (
    <div
      className={`_NavigationStepBackground ${state} h-10 w-10 flex items-center justify-center rounded-full border transition ease-in-out duration-300 ${cn}`}
    >
      {children}
    </div>
  );
};

/**
 * NavigationStepLine.
 */

const NavigationStepLine: FC = () => {
  return (
    <div className="_NavigationStepLine lg:mx-4 flex-1 mt-1 border-t-2 border-gray-400" />
  );
};

/**
 * NavigationStepLabel.
 */

const NavigationStepLabel: FC = ({ children }) => {
  return (
    <div
      className="whitespace-nowrap ml-1/2 absolute mt-2 text-xs font-semibold transform -translate-x-1/2"
      style={{ marginLeft: "50%" }}
    >
      {children}
    </div>
  );
};

/**
 * Individual NavigationStep.
 */

interface NavigationStepProps {
  step: NavigationStepModel;
  state: NavigationStepState;
  showSeparator: boolean;
}

const NavigationStep: FC<NavigationStepProps> = (props) => {
  const { step, state, showSeparator } = props;

  const cn = [
    state === "active" && "shadow-lg",
    (state === "active" || state === "selected") &&
    "text-gray-800 cursor-pointer",
    state === "inactive" && "text-gray-500",
  ]
    .filter(Boolean)
    .join(" ");

  const Icon = iconMap[state === "active" ? "check" : step.icon];

  return (
    <>
      <div
        className={`_NavigationStep ${state} flex-0 h-10 w-10 relative rounded-full ${cn}`}
      >
        <NavigationStepBackground state={state}>
          <Icon className="w-6 h-6" />
        </NavigationStepBackground>
        <NavigationStepLabel>{step.label}</NavigationStepLabel>
      </div>
      {showSeparator && <NavigationStepLine />}
    </>
  );
};
