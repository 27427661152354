import React, { FC } from "react";

type Status = "online" | "away" | "offline";

interface AvatarProps {
  className?: string;
  imageClassName?: string;
  status?: Status;
  src?: string;
  name: string;
}

const statusClassName: Record<Status, string> = {
  online: "bg-green-400",
  away: "bg-red-400",
  offline: "bg-gray-300",
};

export const Avatar: FC<AvatarProps> = (props) => {
  const { className = "", imageClassName = "", status, name } = props;
  return (
    <span className={`inline-block relative ${className}`}>
      <NameAvatar name={name} className={imageClassName} />
      {status ? (
        <span
          className={`absolute bottom-0 right-0 block h-1.5 w-1.5 rounded-full ring-2 ring-white ${statusClassName[status]}`}
        ></span>
      ) : null}
    </span>
  );
};

/**
 * NameAvatar.
 */

interface NameAvatarProps {
  name: string;
  className?: string;
}

const NameAvatar: FC<NameAvatarProps> = (props) => {
  const { name, className = "" } = props;
  const initial = name.slice(0, 1);
  const color = stringToColor(name);

  return (
    <div
      style={{ backgroundColor: color }}
      className={`${className} inline-flex items-center justify-center rounded-full ring-2 ring-white text-white font-semibold`}
    >
      {initial}
    </div>
  );
};

function stringToColor(str: string): string {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
