import { FC, ChangeEvent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useField, ErrorMessage } from "formik";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { VerticalField, HorizontalField } from "components/FieldStructure";
import { Spinner } from "components/Spinner";
import { IconContainer } from "../IconContainer";
import { SpinnerContainer } from "../SpinnerContainer";
import { FAIcon } from "components/FAIcon";

interface InputTextAreaProps {
  value?: string;
  icon?: IconProp;
  isLoading?: boolean;
  inputProps?: TextareaAutosizeProps;
  className?: string;
  onChange?(newValue: string): void;
  onBlur?(e: any): void;
  onFocus?(e: any): void;
  minRows?: number;
  maxRows?: number;
}

export const InputTextArea: FC<InputTextAreaProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    onFocus,
    icon,
    isLoading = false,
    inputProps = {},
    className = "",
    minRows = 3,
    maxRows,
  } = props;

  function handleChange(evt: ChangeEvent<HTMLTextAreaElement>) {
    if (onChange) onChange(evt.target.value);
  }

  /**
   * NB: `className` can be overridden by `inputProps`
   */

  return (
    <div className={!!icon ? "has-icons-left" : ""}>
      <TextareaAutosize
        value={value as any}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${className}`}
        minRows={minRows}
        maxRows={maxRows}
        {...inputProps}
      />
      {icon && (
        <IconContainer className="text-gray-500">
          <FAIcon icon={icon} />
        </IconContainer>
      )}
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </div>
  );
};

/**
 * TextAreaInput.
 */

export interface TextAreaInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  minRows?: number;
  maxRows?: number;
  className?: string;
  inputProps?: TextareaAutosizeProps;
}

export const TextAreaInput: FC<TextAreaInputProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    autoFocus = false,
    icon,
    minRows,
    maxRows,
    className = "",
    inputProps = {},
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value = "", onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputTextArea
        inputProps={{
          id: id || name,
          placeholder,
          name,
          autoFocus,
          ...inputProps,
        }}
        icon={icon}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        minRows={minRows}
        maxRows={maxRows}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * TextAreaField.
 */
interface TextAreaFieldProps extends TextAreaInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const TextAreaField: FC<TextAreaFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <TextAreaInput {...rest} placeholder={rest.placeholder || label} />
    </VerticalField>
  );
};

export const HorizontalTextAreaField: FC<TextAreaFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <TextAreaInput {...rest} placeholder={rest.placeholder || label} />
    </HorizontalField>
  );
};
