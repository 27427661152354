import { FC, useCallback, useState } from "react";
import { gql, useMutation } from '@apollo/client';
import toast from "react-hot-toast";
import { ConfirmationModal } from "components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { InputTextArea } from "components/formik/TextAreaField";
import { VerticalField } from "components/FieldStructure";

const CANCEL_PANEL_APPOINTMENT = gql`
  mutation CancelPanelAppointment($id: UUID4!, $cancellationReason: String!) {
    cancelPanelAppointment(id: $id, cancellationReason: $cancellationReason) {
      errors {
        key
        message
      }
      panelAppointment {
        id
        status
      }
    }
  }
`;

interface Data {
  cancelPanelAppointment: {
    errors: InputError[] | null;
    panelAppointment: null | {
      id: string;
      status: string;
    };
  }
}

interface Variables {
  id: string;
  cancellationReason: string;
}

interface CancelPanelAppointmentModalProps {
  panelAppointmentId: string | null;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

export const CancelPanelAppointmentModal: FC<CancelPanelAppointmentModalProps> = props => {
  const { panelAppointmentId, isOpen, onClose, onSuccess } = props;

  const [cancelAppointment] = useMutation<Data, Variables>(CANCEL_PANEL_APPOINTMENT);

  const [cancellationReason, setCancellationReason] = useState<string>("");

  const onSubmit = useCallback(async () => {
    if (!panelAppointmentId) return;

    const { data } = await cancelAppointment({ variables: { id: panelAppointmentId, cancellationReason: "Cancelled by user" } });
    if (data?.cancelPanelAppointment.errors) {
      data.cancelPanelAppointment.errors.forEach(error => {
        toast.error(error.message);
      });
    } else {
      toast.success("Appointment cancelled");
      onSuccess();
    }
  }, [panelAppointmentId, cancelAppointment, onSuccess]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      color="red"
      icon={XMarkIcon}
      title="Cancel Panel Appointment"
      prompt="Cancel Appointment"
      onSubmit={onSubmit}
    >
      <p className="text-sm text-gray-500">
        Are you sure you want to cancel this panel review appointment?
      </p>
      <div className="mt-6">
        <VerticalField label="Cancellation Reason">
          <InputTextArea
            value={cancellationReason}
            onChange={setCancellationReason}
          />
        </VerticalField>
      </div>
    </ConfirmationModal>
  );
};
