import "core-js/features/string/pad-start";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import "@fontsource/inter/latin.css";
import "./index.css";
import App from "./App";
import ReactModal from "react-modal";
import { AppProviders } from "./context";
import reportWebVitals from "./reportWebVitals";
import createIconLibrary from "./createIconLibrary";

// NB: This is for https://github.com/streamich/react-use/blob/481943247131f71086266228d7876a36fbf68c5f/docs/useMeasure.md
if (!window.ResizeObserver) {
  import("resize-observer-polyfill").then((module) => {
    window.ResizeObserver = module.default;
  });
}

createIconLibrary();

ReactModal.setAppElement("#root");

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
