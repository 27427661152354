import { useQuery } from "@apollo/client";
import { Button } from "components/Button";
import { Container } from "components/Container";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import { Table, TableContainer, TD, TH } from "components/Table";
import { ScreenTitle } from "context/ScreenTitle";
import { makeAppendItems } from "lib/makeAppendItems";
import { FC } from "react";
import { Link } from "react-router-dom";
import { distanceInWords, mmDdYyyy } from "utils/dateFormatters";
import {
  ListData,
  LIST_UM_REQUESTS,
  Status,
  trClassNames,
} from "../UMRequestsScreen";
import { FilterModel } from "../UMRequestsScreen/FilterPanel";

const PAGE_SIZE = 50;

interface UMWarningsScreenProps { }

const filter: FilterModel = { hasWarning: true };

export const UMWarningsScreen: FC<UMWarningsScreenProps> = (props) => {
  const { data, loading, error, refetch, fetchMore } = useQuery<ListData>(
    LIST_UM_REQUESTS,
    { variables: { filter, first: PAGE_SIZE } }
  );

  return (
    <Container className="_UMWarningsQueue">
      <ScreenTitle title="UM Review Warnings" />

      <div className="py-6">
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : error || !data?.umRequests ? (
          <div className="p-8 text-center">
            <p className="py-4 text-gray-700">Failed to load</p>
            <Button type="button" size="sm" onClick={() => refetch()}>
              Retry
            </Button>
          </div>
        ) : data.umRequests.items.length === 0 ? (
          <NoResults
            icon="check"
            text="No UM Reviews with warnings"
          />
        ) : (
          <TableContainer>
            <Table className="text-left">
              <thead>
                <tr>
                  <TH>Case #</TH>
                  <TH>Member</TH>
                  <TH>Warning</TH>
                  <TH>Due At</TH>
                </tr>
              </thead>
              <tbody>
                {data.umRequests.items.map((item) => {
                  const memberName = [
                    item.caseProfile.memberFirstName,
                    item.caseProfile.memberLastName,
                  ]
                    .filter(Boolean)
                    .join(" ");

                  const link = `/ip/cases/${item.caseProfile.id}`;

                  return (
                    <tr
                      key={item.id}
                      className={`${trClassNames[item.timingStatus]
                        } transition-colors duration-300`}
                    >
                      <TD className="w-12 px-4">
                        <Link
                          to={link}
                          className="hover:text-blue-700 font-mono font-semibold text-blue-600 underline"
                        >
                          <p>{item.caseProfile.caseNumber}</p>
                        </Link>
                        <p className="font-sm mt-1 font-mono text-gray-500">
                          {item.caseProfile.episodeId}
                        </p>
                        {/* <div className="whitespace-nowrap pt-1">
                          <Tooltip tip="Preview" placement="top">
                            <Button type="button" size="xs">
                              <EyeIcon className="w-4 h-4" />
                            </Button>
                          </Tooltip>
                          <Tooltip tip="Take" placement="top">
                            <Button
                              type="button"
                              className="ml-1"
                              icon="highlighter"
                              size="xs"
                            >
                              <HandIcon className="w-4 h-4" />
                            </Button>
                          </Tooltip>
                        </div> */}
                      </TD>
                      <TD>
                        <p className="text-base">{memberName || "-"}</p>
                        <p className="text-xs">
                          <span className="mr-1 italic font-thin text-gray-500">
                            DOB:
                          </span>
                          {mmDdYyyy(item.caseProfile.memberDob)}
                        </p>
                      </TD>
                      <TD>
                        {
                          !!item.warningMessage && item.warningAtString ? (
                            <>
                              <p className="py-2 text-base font-semibold text-red-800">{item.warningMessage || "-"}</p>
                              <p className="text-xs">
                                <span className="mr-1 italic font-thin text-gray-500">
                                  At:
                                </span>
                                {item.warningAtString}
                              </p>
                            </>
                          ) : null
                        }
                      </TD>
                      <TD>
                        {item.dueAtString ? (
                          <>
                            <p>{item.dueAtString}</p>
                            <p>
                              {item.isPastDue ? (
                                <span className="font-semibold">{distanceInWords(item.dueAt)} ago</span>
                              ) : (
                                <span className="font-semibold">in {distanceInWords(item.dueAt)}</span>
                              )}
                              <Status
                                status={item.timingStatus}
                                className="ml-2"
                              />
                            </p>
                          </>
                        ) : null}
                      </TD>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {data.umRequests.items.length > 0 ? (
              <div className="p-2 text-center text-gray-500 bg-white border-t border-gray-300">
                {data.umRequests.endOfList ? (
                  <p>End of List</p>
                ) : (
                  <Button
                    type="button"
                    size="sm"
                    kind="secondary"
                    color="blue"
                    className="w-full"
                    onClick={() => {
                      fetchMore({
                        query: LIST_UM_REQUESTS,
                        variables: {
                          first: PAGE_SIZE,
                          after: data.umRequests.cursor,
                          filter,
                        },
                        updateQuery,
                      });
                    }}
                  >
                    Load More
                  </Button>
                )}
              </div>
            ) : null}
          </TableContainer>
        )}
      </div>
    </Container>
  );
};

const updateQuery = makeAppendItems<ListData>("umRequests");
