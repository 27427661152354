import React, {
  Children,
  useCallback,
  createContext,
  FocusEvent,
  forwardRef,
  useState,
  useMemo,
  useRef,
  useContext,
  useEffect,
  ReactElement,
} from "react";
import { Icon } from "components/Icon";
import styled from "styled-components";

const ChannelGroupElement = styled.div`
  padding: 0;
  list-style: none;
  max-width: 16rem;

  &:focus {
    outline: none;
  }
`;

const ChannelTileElement = styled.div`
  width: 72px;
  height: 64px;
  padding: 0.125em 0.5em;
  cursor: default;
  outline: none;

  &:hover::before {
    border-color: hsl(216, 94%, 65%);
  }

  &[aria-checked="false"]:hover &:after {
    transform: scale(1.1);
  }

  &[aria-checked="true"] {
    border-color: #225cff;
  }

  &[data-preferral-radio-focus="true"] {
    border-color: hsl(216, 94%, 73%);
    background-color: hsl(216, 80%, 97%);
  }

  &:hover {
    background-color: hsl(216, 80%, 92%);
    cursor: pointer;
  }
`;

const codes = {
  RETURN: 13,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export interface ChannelGroupCtx<V, Siblings = V[]> {
  value: V;
  otherValues: Siblings;
  setChecked(value: any): void;
}

const ChannelGroupContext = createContext<ChannelGroupCtx<any>>({} as any);

export interface ChannelProps<V> {
  value: V;
  children: any;
  onFocus?(e: FocusEvent<any>): void;
  onBlur?(e: any): void;
  className?: string;
}

export interface ChannelGroupProps<V> {
  labelledBy?: string;
  children: ReactElement<ChannelProps<V>>[];
  value: V;
  onChange(value: V): void;
  className?: string;
}

export function ChannelGroup<V>(props: ChannelGroupProps<V>) {
  const { labelledBy, children, value, className = "", onChange } = props;

  const setChecked = useCallback(
    (v) => {
      if (onChange) onChange(v);
    },
    [onChange]
  );

  const otherValues = Children.map<any, any>(
    children,
    (child) => child.props.value
  );

  const ctx = useMemo(
    () => ({
      value,
      otherValues,
      setChecked,
    }),
    [setChecked, otherValues, value]
  );

  return (
    <ChannelGroupContext.Provider value={ctx}>
      <ChannelGroupElement
        role="radiogroup"
        aria-labelledby={labelledBy}
        data-preferral-radio-group
        className={`grid grid-cols-3 grid-rows-2 gap-1 ${className}`}
      >
        {children}
      </ChannelGroupElement>
    </ChannelGroupContext.Provider>
  );
}

const iconNames = {
  phone: "phone-incoming",
  fax: "fax",
  mail: "mail",
  email: "at",
  web: "application",
  other: "dots-horizontal",
};

export const Channel = forwardRef<HTMLDivElement | null, ChannelProps<any>>(
  ({ children, className = "", ...props }, maybeOuterRef: any) => {
    const [focus, setFocus] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    const ctx = useContext(ChannelGroupContext);
    const { otherValues, value, setChecked } = ctx;
    const index = otherValues.findIndex((i) => i === props.value);
    const count = otherValues.length - 1;

    useEffect(() => {
      if (
        value === props.value &&
        document.activeElement?.tagName !== "INPUT"
      ) {
        if (maybeOuterRef && maybeOuterRef.current !== null) {
          maybeOuterRef.current.focus();
        } else if (ref.current !== null) {
          ref.current.focus();
        }
      }
    }, [value, props.value, maybeOuterRef]);

    const handleKeyDown = useCallback(
      (event) => {
        event.persist();
        let flag = false;
        function setPrevious() {
          if (index === 0) {
            setChecked(otherValues[count]);
          } else {
            setChecked(otherValues[index - 1]);
          }
        }

        function setNext() {
          if (index === count) {
            setChecked(otherValues[0]);
          } else {
            setChecked(otherValues[index + 1]);
          }
        }

        switch (event.keyCode) {
          case codes.SPACE:
          case codes.RETURN:
            setChecked(props.value);
            flag = true;
            break;
          case codes.UP:
          case codes.LEFT:
            setPrevious();
            flag = true;
            break;
          case codes.DOWN:
          case codes.RIGHT:
            setNext();
            flag = true;
            break;
          default:
            break;
        }

        if (flag) {
          event.stopPropagation();
          event.preventDefault();
        }
      },
      [setChecked, otherValues, props.value, count, index]
    );

    const handleClick = useCallback(() => {
      setChecked(props.value);
    }, [setChecked, props.value]);

    const { onFocus, onBlur } = props;
    const handleBlur = useCallback(
      (e) => {
        if (onBlur) {
          onBlur(e);
        }
        setFocus(false);
      },
      [onBlur]
    );

    const handleFocus = useCallback(
      (e) => {
        if (onFocus) {
          onFocus(e);
        }
        setFocus(true);
      },
      [onFocus, setFocus]
    );

    const iconName = iconNames[props.value] || "fax";
    const isChecked = value === props.value;

    return (
      <ChannelTileElement
        {...props}
        role="radio"
        tabIndex={isChecked || (!value && index === 0) ? 0 : -1}
        aria-checked={isChecked}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        data-preferral-radio
        data-preferral-radio-focus={focus}
        ref={(el) => {
          if (maybeOuterRef) {
            maybeOuterRef.current = el;
          }
          ref.current = el;
        }}
        className={`border-2 rounded-md flex flex-col items-center gap-1 font-semibold ${className}`}
      >
        {/* <ChannelIcon channel={props.value} /> */}
        <Icon
          icon={iconName}
          size={32}
          className="mx-auto"
          color={isChecked ? "blue" : "gray"}
        />
        {props.value}
      </ChannelTileElement>
    );
  }
);

// interface ChannelSelectProps {
//   id?:
//   value: string;
//   onChange(value: string): void;
// };

// export const ChannelSelect: FC<ChannelSelectProps> = props => {
//   return (
//     <div className="ChannelSelect">
//       <p>ChannelSelect</p>
//     </div>
//   );
// };
