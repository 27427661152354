import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "components/Spinner";
import { DetailItem, DetailList } from "components/DetailList";
import { distanceInWords, mmDdYyyy } from "utils/dateFormatters";
import { ActivityEligibility } from "components/ActivityEligibility";
import { levelOfReviewLabel } from "utils/levelOfReview";
import { translateLOR } from "utils/kentuckyWording";
import { P2PAppointmentList } from "components/P2PAppointmentList";

const APPEAL_REQUEST = gql`
  query AppealRequest($id: UUID4!) {
    appealRequest(id: $id) {
      id
      isDraft
      urgency
      channel
      episodeId
      requestedAt
      fileUploads {
        id
        url
      }
      caseProfile {
        id
        memberFirstName
        memberLastName
        memberDob
        memberId
        memberState {
          id
          name
        }
        healthPlan {
          id
          name
        }
        caseNumber
        episodeId
        p2pValidUntilDate
        appealValidUntilDate
        modality {
          id
          name
        }
        levelOfReview
        caseSystem
        insurancePlanCode
        sameStateLicensureState {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  appealRequest: AppealRequestModel;
}

interface AppealRequestModel {
  id: string;
  isDraft: boolean;
  urgency: string;
  episodeId: null | string;
  channel?: string;
  requestedAt?: string;
  fileUploads: FileUploadModel[];
  caseProfile: CaseProfileModel;
}

interface CaseProfileModel {
  id: string;
  memberFirstName: string;
  memberLastName: string;
  memberDob: string;
  memberId: null | string;
  memberState: {
    id: string;
    name: string;
  };
  healthPlan: {
    id: string;
    name: string;
  };
  caseNumber: string;
  episodeId?: string;
  p2pValidUntilDate?: string;
  appealValidUntilDate?: string;
  modality: {
    id: string;
    name: string;
  };
  levelOfReview: string;
  caseSystem: string;
  insurancePlanCode: string;
  sameStateLicensureState?: {
    id: string;
    name: string;
  };
}

interface FileUploadModel {
  id: string;
  url: string;
}

interface Variables {
  id: string;
}

interface AppealRequestInfoPanelProps {
  appealRequestId: string;
  displayAppealRequest?: boolean;
}

export const AppealRequestInfoPanel: FC<AppealRequestInfoPanelProps> = (
  props
) => {
  const { appealRequestId, displayAppealRequest = false } = props;
  const { data, loading, error } = useQuery<Data, Variables>(APPEAL_REQUEST, {
    variables: { id: appealRequestId },
    fetchPolicy: "network-only",
  });

  const appealRequest = data?.appealRequest;
  const caseProfile = appealRequest?.caseProfile;

  const reviewLevelLabel = translateLOR(
    caseProfile?.levelOfReview &&
    levelOfReviewLabel(caseProfile?.levelOfReview),
    caseProfile?.insurancePlanCode,
    caseProfile?.sameStateLicensureState?.name
  );

  return (
    <div className="AppealRequestInfoPanel">
      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !appealRequest ? (
        <p>Failed to load.</p>
      ) : (
        <div>
          {displayAppealRequest ? (
            <div className="p-2 mt-4 border rounded-lg shadow">
              <h4 className="px-2 pt-1 pb-5 text-sm font-bold text-gray-600">
                Post-Decision Request Information
              </h4>
              <DetailList>
                <DetailItem label="Channel">{appealRequest.channel}</DetailItem>
                <DetailItem label="Urgency">{appealRequest.urgency}</DetailItem>
                <DetailItem label="Requested At">
                  {appealRequest.requestedAt ? (
                    <>
                      {mmDdYyyy(appealRequest.requestedAt)}
                      <span className="ml-2 text-xs italic font-light text-gray-500">
                        ({distanceInWords(appealRequest.requestedAt)} ago)
                      </span>
                    </>
                  ) : (
                    <p className="italic font-semibold text-purple-700">
                      Not yet submitted
                    </p>
                  )}
                </DetailItem>
              </DetailList>
            </div>
          ) : null}
          <div className="p-2 mt-4 border rounded-lg shadow">
            <h4 className="px-2 pt-1 pb-5 text-sm font-bold text-gray-600">
              Member Information
            </h4>
            <DetailList>
              <DetailItem label="Patient Name">
                {caseProfile?.memberFirstName} {caseProfile?.memberLastName}
              </DetailItem>
              <DetailItem label="DOB">
                {mmDdYyyy(caseProfile!.memberDob)}
              </DetailItem>
              <DetailItem label="State">
                {caseProfile?.memberState.name}
              </DetailItem>
              <DetailItem label="Health Plan">
                {caseProfile?.healthPlan.name}
              </DetailItem>
              <DetailItem label="Member ID">{caseProfile?.memberId || "-"}</DetailItem>
            </DetailList>
          </div>
          <div className="p-2 pt-4 mt-4 border rounded-lg shadow">
            {
              caseProfile ? (
                <>
                  <h4 className="px-2 pt-1 pb-3 text-sm font-bold text-gray-600">
                    P2P Appointments
                  </h4>
                  <P2PAppointmentList caseNumber={caseProfile?.caseNumber} />
                </>
              ) : null
            }

            <h4 className="px-2 py-5 pt-1 text-sm font-bold text-gray-600">
              Case Information
            </h4>
            <div className="flex justify-center pb-4">
              <div>
                {/* TODO: */}
                <ActivityEligibility allowed label="No open post-decision requests" />
              </div>
            </div>
            <DetailList>
              <DetailItem label="Case Ref #">
                {caseProfile?.caseNumber}
              </DetailItem>
              <DetailItem label="Episode ID">
                {caseProfile?.episodeId}
              </DetailItem>
              <DetailItem label="Can Appeal Until">
                {caseProfile?.p2pValidUntilDate
                  ? mmDdYyyy(caseProfile.p2pValidUntilDate)
                  : "-"}
              </DetailItem>
              <DetailItem label="Modality">
                {caseProfile?.modality?.name}
              </DetailItem>
              <DetailItem label="Level of Review">
                {reviewLevelLabel || "-"}
              </DetailItem>
              <DetailItem label="Case System">
                {caseProfile?.caseSystem}
              </DetailItem>
            </DetailList>
          </div>
          {/* <pre className="text-xs">{JSON.stringify(data, null, 2)}</pre> */}
        </div>
      )}
    </div>
  );
};
