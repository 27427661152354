import { FC } from "react";
import { FAIcon } from "components/FAIcon";

// const PAGE_SIZE = 20;

// export const LIST_EXPORTS = gql`
//   query ListDataExports($first: Int, $after: UUID4) {
//     dataExports(first: $first, after: $after) {
//       cursor
//       endOfList
//       items {
//         id
//         filename
//         windowStart
//         windowFinish
//         insertedAt
//         completedAt
//         status
//       }
//     }
//   }
// `;

/**
 * EmptyState.
 */
const EmptyState: FC = () => {
  return (
    <div className="text-center my-6 mx-auto">
      <div className="text-gray-400 text-3xl my-4 mx-auto">
        <FAIcon icon="file-download" />
      </div>
      <p className="text-gray-700">No exports yet</p>
    </div>
  );
};

interface ExportsWidgetProps {}

export const ExportsWidget: FC<ExportsWidgetProps> = (props) => {
  return (
    <div className="ExportsWidget">
      <EmptyState />
    </div>
  );
};
