import { FC } from "react";
import { CheckIcon } from '@heroicons/react/24/outline'

interface SequenceStepProps {
  isCurrent?: boolean;
  isComplete?: boolean;
  trailingLine?: boolean;
  label: string;
}

const SequenceStep: FC<SequenceStepProps> = props => {
  const { isCurrent = false, isComplete = false, label, children, trailingLine = true } = props;

  return (
    <li className="relative pb-6">
      {
        trailingLine ? (
          <div
            className={`-ml-px absolute ${isComplete || isCurrent ? "bg-blue-800" : "bg-gray-300"} h-full left-3 mt-0.5 top-4 w-0.5`}
            aria-hidden="true"
          ></div>
        ) : null
      }
      <span className="_link group relative flex items-start">
        <span className="flex items-center h-6">
          {
            isComplete ? (
              <span className="group-hover:bg-blue-800 relative z-10 flex items-center justify-center w-6 h-6 bg-blue-800 rounded-full">
                <CheckIcon className="inline-block w-4 h-4 text-white" />
              </span>
            ) : isCurrent ? (
              <span className="relative z-10 flex items-center justify-center w-6 h-6 bg-white border-2 border-blue-800 rounded-full">
                <span className="h-2.5 w-2.5 bg-blue-800 rounded-full"></span>
              </span>
            ) : (
              <span className="group-hover:border-gray-400 relative z-10 flex items-center justify-center w-6 h-6 bg-white border-2 border-gray-300 rounded-full">
                <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"></span>
              </span>
            )
          }
        </span>
        <span className="flex flex-col flex-grow min-w-0 ml-3">
          {
            isComplete ? (
              <span className="text-xs font-semibold tracking-wide uppercase">
                {label}
              </span>
            ) : isCurrent ? (
              <span className="text-xs font-semibold tracking-wide text-blue-800 uppercase">
                {label}
              </span>
            ) : (
              <span className="text-xs font-semibold tracking-wide text-gray-500 uppercase">
                {label}
              </span>
            )
          }
          {children}
        </span>
      </span>
    </li>
  );
}

interface SequenceContainerProps { }

const SequenceContainer: FC<SequenceContainerProps> = (props) => {
  const { children } = props;
  return (
    <nav aria-label="Progress">
      <ol className="overflow-hidden">
        {children}
      </ol>
    </nav>
  );
};

export const Sequence = { Container: SequenceContainer, Step: SequenceStep };
