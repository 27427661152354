import { forwardRef, HTMLAttributes } from "react";
import { Transition } from "react-transition-group";

interface FadeInOutProps extends HTMLAttributes<HTMLDivElement> {
  show?: boolean;
}

const stateClassNames = {
  enter: "transition-opacity ease-linear duration-300",
  leave: "transition-opacity ease-linear duration-300",
};

const stageClassNames = {
  entering: "opacity-0",
  entered: "opacity-100",
  exiting: "opacity-0",
};

export const FadeInOut = forwardRef<HTMLDivElement, FadeInOutProps>(
  (props, ref) => {
    const { show, className = "", children, ...rest } = props;

    const stateName = show ? "enter" : "leave";
    const stateClassName = stateClassNames[stateName];

    return (
      <Transition
        appear
        nodeRef={ref}
        in={show}
        timeout={{ enter: 0, exit: 300 }}
        unmountOnExit
      >
        {(stage) => (
          <div
            {...rest}
            ref={ref}
            className={`_FadeInOut _${stage} ${className} ${stateClassName} ${
              stageClassNames[stage] || ""
            }`}
          >
            {children}
          </div>
        )}
      </Transition>
    );
  }
);
