import { gql, useMutation } from "@apollo/client";
import { useInterval } from "hooks/useInterval";
import { useAuth } from "context/AuthContext";

const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface MutationData {
  refreshToken: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

// Refresh the token every minute
const REFRESH_INTERVAL = 1000 * 60;

export function TokenRefresher() {
  const { login } = useAuth();

  const [refreshToken, { loading }] = useMutation<MutationData>(REFRESH_TOKEN);

  useInterval(async () => {
    if (loading) return;

    try {
      const { data } = await refreshToken();
      if (data?.refreshToken?.session?.token) {
        login(data.refreshToken.session.token);
      }
    } catch (e) {
      console.error("Failed to refresh token", e);
    }
  }, REFRESH_INTERVAL);

  return null;
}
