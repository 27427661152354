import { FC } from "react";
import { useRouteMatch } from "react-router-dom";
import { useFakeQuery } from "hooks/useFakeQuery";
import { ScreenTitle } from "context/ScreenTitle";
import { Spinner } from "components/Spinner";
import { CircleIconHeader } from "components/CircleIconHeader";
import { Button } from "components/Button";
import { CurrentViewing } from "./CurrentlyViewing";
import { Container } from "components/Container";

const FETCH_APPEAL = "FETCH_APPEAL";

interface RouteParams {
  id: string;
}

interface AppealShowScreenProps { }

export const AppealShowScreen: FC<AppealShowScreenProps> = (props) => {
  const {
    params: { id: appealId },
  } = useRouteMatch<RouteParams>();

  const { data, loading } = useFakeQuery(FETCH_APPEAL, {
    data: appealData,
  });

  return (
    <Container className="_AppealShowScreen">
      <ScreenTitle title="View Post-Decision Request" />
      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : !data?.appeal ? (
        <p>Failed to load</p>
      ) : (
        <div className="flex">
          {/* <div className="w-48 pt-10 mr-4">
            <Sequence />
          </div> */}
          <div className="rounded-2xl flex-grow px-4 pt-4 pb-8 bg-white border shadow-2xl">
            <CircleIconHeader icon="envelope-open-text">
              Post-Decision Request
            </CircleIconHeader>

            <CurrentViewing appealRequestId={appealId} />

            <pre className="text-xs">
              {JSON.stringify(data.appeal, null, 2)}
            </pre>

            <div className="px-4 pt-8">
              <Button>Open in Case System</Button>
              <p className="pb-4">
                (Opens something like{" "}
                <pre>https://upx.evicore.com/:caseNumber</pre>
              </p>

              <h3 className="text-lg font-semibold list-disc">
                Info to display:
              </h3>
              <ul className="list-disc">
                <li>ID: {appealId}</li>
                <li>Case Info</li>
                <li>Requesting Provider Info</li>
                <li>Timing Info</li>
              </ul>

              <h3 className="mt-4 text-lg font-semibold">Actions to take:</h3>
              <ul className="list-disc">
                <li>Assign reviewing nurse</li>
                <li>Assign reviewing provider</li>
                <li>Appove / deny?</li>
                <li>"Close?"</li>
                <li>Send letter?</li>
                <li>Send email to health plan?</li>
              </ul>
            </div>
          </div>
          <div className="rounded-2xl w-56 px-4 pt-4 pb-8 ml-4 bg-white border shadow-2xl">
            Provider
          </div>
        </div>
      )}
    </Container>
  );
};

const appealData = {
  appeal: {
    id: "1233",
    caseProfile: {
      id: "1235",
      patientFirstName: "Sally",
      patientLastName: "Jones",
      dob: "1974-04-20",
    },
  },
};
