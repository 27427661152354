import React, { FC } from "react";
import Select, { Props as SelectProps } from "react-select";
import { FlatHealthPlanModel, HealthPlanOptionModel } from "./model";
import { useHealthPlanOptions } from "./useHealthPlanOptions";
import { HealthPlanOption } from "./HealthPlanOption";

export type OmitProps = "getOptionLabel" | "getOptionValue" | "options";

export type HealthPlanSelectProps = Omit<
  SelectProps<HealthPlanOptionModel>,
  OmitProps
>;

export const components = { Option: HealthPlanOption };

export const HealthPlanSelect: FC<HealthPlanSelectProps> = (props) => {
  const { healthPlanOptions, loading } = useHealthPlanOptions();

  return (
    <Select
      {...props}
      options={healthPlanOptions}
      getOptionLabel={getHealthPlanOptionLabel}
      getOptionValue={getHealthPlanOptionValue}
      isLoading={loading || props.isLoading}
      components={
        props.components ? { ...props.components, ...components } : components
      }
    />
  );
};

export function getHealthPlanOptionValue(hp: FlatHealthPlanModel) {
  return hp.id;
}

export function getHealthPlanOptionLabel(hp: FlatHealthPlanModel) {
  return hp.displayName;
}
