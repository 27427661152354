import React, { FC, useState } from "react";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import { startOfWeek, endOfWeek, addDays } from "date-fns";
import { ScreenTitle } from "context/ScreenTitle";
import { FAIcon } from "components/FAIcon";
import { TimeRange } from "./model";
import { DateRangeDropdown } from "./DateRangeDropdown";
import { ExportsWidget } from "./ExportsWidget";
import { AppealVolume } from "./reports/AppealVolume";
import { SLACompliance } from "./reports/SLACompliance";
import { ActivityInventory } from "./reports/ActivityInventory";
import { ReportsProvider } from "./ReportsContext";
import { Container } from "components/Container";

enum Reports {
  // NB: Report Request #1 from Mark's deck (2/2)
  SLACompliance = "sla_compliance",
  // NB: Report Request #2 from Mark's deck
  ActivityInventory = "activity_inventory",
  // NB: Report Request #3 from Mark's deck
  Production = "production",
  AppealVolume = "appeal_volume",
  AppealsByHealthPlan = "appeals_by_health_plan",
  InternalUserProduction = "internal_user_production",
  AppealOutcomes = "appeal_outcomes",
}

const startOfLastWeek = startOfWeek(addDays(new Date(), -7));
const endOfLastWeek = endOfWeek(addDays(new Date(), -7));

interface ReportsScreenProps { }

export const ReportsScreen: FC<ReportsScreenProps> = () => {
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: startOfLastWeek,
    finish: endOfLastWeek,
  });

  const match = useRouteMatch();

  return (
    <>
      <ScreenTitle title="Reports" />
      <Container className="_ReportsScreen flex text-left">
        <div className="_sidebar flex-1 max-w-sm px-4">
          <div className="_date-range pt-8">
            <h3 className="my-4 font-semibold text-gray-600">Date Range</h3>
            <DateRangeDropdown value={timeRange} onChange={setTimeRange} />
          </div>

          <div className="_report-nav pt-8">
            <h3 className="my-4 font-semibold text-gray-600">Reports</h3>
            <div className="p-2 bg-white border rounded-lg">
              <NavLink
                to={`/ip/reports/${Reports.SLACompliance}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                SLA Compliance
              </NavLink>

              <NavLink
                to={`/ip/reports/${Reports.ActivityInventory}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                Activity Inventory
              </NavLink>

              <NavLink
                to={`/ip/reports/${Reports.AppealVolume}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-line" />
                </span>
                Appeal Volume
              </NavLink>

              <NavLink
                to={`/ip/reports/${Reports.AppealsByHealthPlan}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                Post-Decision Requests by Health Plan
              </NavLink>

              <NavLink
                to={`/ip/reports/${Reports.InternalUserProduction}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                Internal User Production
              </NavLink>

              <NavLink
                to={`/ip/reports/${Reports.AppealOutcomes}`}
                className="transition-fast hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 relative block px-2 py-1 mt-1 text-sm font-medium text-gray-600 transition-colors rounded-lg"
                activeClassName="text-blue-700 bg-blue-200"
              >
                <span className="pl-2 pr-3">
                  <FAIcon icon="chart-bar" />
                </span>
                Post-Decision Request Outcomes
              </NavLink>
            </div>
          </div>

          <div className="_exports pt-8">
            <h3 className="my-4 font-semibold text-gray-600">Exports</h3>
            <ExportsWidget />
          </div>
        </div>
        <div className="flex-1 p-8">
          <ReportsProvider timeRange={timeRange}>
            <Switch>
              <Route path={`${match.path}/${Reports.SLACompliance}`}>
                <SLACompliance />
              </Route>

              <Route path={`${match.path}/${Reports.ActivityInventory}`}>
                <ActivityInventory />
              </Route>
              <Route path={`${match.path}/${Reports.AppealVolume}`}>
                <AppealVolume />
              </Route>
            </Switch>
          </ReportsProvider>
        </div>
      </Container>
    </>
  );
};
