import { FC, ChangeEvent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ErrorMessage, useField } from "formik";
import { HorizontalField, VerticalField } from "components/FieldStructure";
import { FAIcon } from "components/FAIcon";
import { IconContainer } from "../IconContainer";
import MaskedInput, { MaskedInputProps } from "react-text-mask";

export type Mask = (string | RegExp)[];

export interface Pipe {
  (conformedValue: string, config: any):
    | false
    | string
    | { value: string; indexesOfPipedChars: number[] };
}
export interface InputTextMaskProps {
  value: string;
  icon?: IconProp;
  inputProps?: Omit<MaskedInputProps, "mask">;
  className?: string;
  placeholder?: string;
  onChange(newValue: string): void;
  onBlur?(e: any): void;
  mask: Mask;
  pipe?: Pipe;
}

export const InputTextMask: FC<InputTextMaskProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    icon,
    inputProps = {},
    className = "",
    placeholder,
    mask,
    pipe,
  } = props;

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value);
  };

  /**
   * NB: `className` can both be overridden by `inputProps`.
   */

  return (
    <div
      className={`rounded-md shadow-sm relative ${!!icon ? "has-icons-left" : ""
        }`}
    >
      <MaskedInput
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${className}`}
        placeholder={placeholder}
        mask={mask}
        pipe={pipe}
        {...inputProps}
      />
      {icon && (
        <IconContainer className="text-gray-500">
          <FAIcon icon={icon} />
        </IconContainer>
      )}
    </div>
  );
};

export interface TextMaskInputProps {
  id?: string;
  name: string;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  className?: string;
  inputProps?: MaskedInputProps;
  mask: Mask;
  pipe?: Pipe;
}

export const TextMaskInput: FC<TextMaskInputProps> = (props) => {
  const {
    id,
    name,
    icon,
    autoFocus = false,
    className = "",
    inputProps = {},
    mask,
    pipe,
    placeholder,
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputTextMask
        inputProps={{
          id: id || name,
          name,
          autoFocus,
          placeholder,
          ...inputProps,
        }}
        icon={icon}
        className={
          meta && meta.touched && meta.error
            ? `border border-red-500 ${className}`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        mask={mask}
        pipe={pipe}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

export interface TextMaskFieldProps extends TextMaskInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const TextMaskField: FC<TextMaskFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <TextMaskInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalTextMaskField: FC<TextMaskFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <TextMaskInput {...rest} />
    </HorizontalField>
  );
};
