import React, { FC, useState } from "react";
import { Button } from "components/Button";
import { CircleIcon } from "components/CircleIcon";
import { FAIcon } from "components/FAIcon";
import { NoResults } from "components/NoResults";
import { DownloadSLAComplianceModal } from "./DownloadSLAComplianceModal";

interface SLAComplianceProps {}

type ActiveModal = "DOWNLOAD_REPORT";

export const SLACompliance: FC<SLAComplianceProps> = (props) => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = () => setActiveModal(null);
  const openDownloadModal = () => setActiveModal("DOWNLOAD_REPORT");

  return (
    <>
      <DownloadSLAComplianceModal
        isOpen={activeModal === "DOWNLOAD_REPORT"}
        onClose={closeModal}
      />
      <div className="_SLACompliance bg-white border rounded-lg shadow-xl">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <CircleIcon icon="chart-line" />
              <p className="ml-4 text-xl font-semibold">SLA Compliance</p>
            </div>
            <Button
              kind="secondary"
              color="gray"
              className="border"
              onClick={openDownloadModal}
            >
              Export Report
              <span className="ml-2">
                <FAIcon icon="file-download" />
              </span>
            </Button>
          </div>

          <div className="pt-8 text-center">
            <NoResults icon="chart-line" text="Not enough data to display" />
          </div>
        </div>
      </div>
    </>
  );
};
