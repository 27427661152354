import { FC } from "react";
import styled from "styled-components";

const SpinnerCircle = styled.div`
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 10px;
  width: 10px;
  border: 4px solid hsl(207, 68%, 66%);
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: content-box;
`;

interface SpinnerProps {}

export const Spinner: FC<SpinnerProps> = () => {
  return <SpinnerCircle className="Spinner" />;
};
