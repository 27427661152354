import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ConfirmationModal } from "components/ConfirmationModal";

const CLOSE_REQUEST = gql`
  mutation CloseAppealRequest($appealRequestId: UUID4!) {
    closeAppealRequest(appealRequestId: $appealRequestId) {
      errors {
        key
        message
      }
      appealRequest {
        id
        stage
      }
    }
  }
`;

interface Data {
  closeAppealRequest: {
    errors: InputError[] | null;
    appealRequest: AppealRequestModel | null;
  }
}

interface Variables {
  appealRequestId: string;
}

interface AppealRequestModel {
  id: string;
  stage: string;
}

interface CloseRequestModalProps {
  appealRequestId: string | null;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

export const CloseRequestModal: FC<CloseRequestModalProps> = props => {
  const { appealRequestId, isOpen, onClose, onSuccess } = props;

  const [closeRequest] = useMutation<Data, Variables>(CLOSE_REQUEST);

  const onSubmit = useCallback(async () => {
    if (!appealRequestId) return;

    const { data } = await closeRequest({ variables: { appealRequestId } });
    if (data?.closeAppealRequest.errors) {
      data.closeAppealRequest.errors.forEach(error => {
        toast.error(error.message);
      });
    } else {
      toast.success("Request closed");
      onSuccess();
    }
  }, [appealRequestId, closeRequest, onSuccess]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      color="red"
      icon={XMarkIcon}
      title="Close Request"
      prompt="Continue"
      onSubmit={onSubmit}
    >
      <p className="text-sm text-gray-500">
        Are you sure you want to close this post-decision request?
      </p>
    </ConfirmationModal>
  );
};
