import { FC } from "react";

interface NoMatchScreenProps {}

export const NoMatchScreen: FC<NoMatchScreenProps> = (props) => {
  return (
    <div className="NoMatchScreen">
      <p>NoMatchScreen</p>
    </div>
  );
};
