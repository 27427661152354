import { FC } from "react";
import { Drawer, DrawerHeader } from "components/Drawer";
import { useOnlineAgents } from "hooks/useOnlineAgents";

interface OnlineAgentsDrawerProps {
  isOpen: boolean;
  onClose(): void;
}

export const OnlineAgentsDrawer: FC<OnlineAgentsDrawerProps> = (props) => {
  const { isOpen, onClose } = props;

  const agents = useOnlineAgents();

  return (
    <Drawer isOpen={isOpen} onRequestClose={onClose} width="md">
      <DrawerHeader title="Online Agents" icon="users" onClose={onClose} />

      <div className="p-4">
        <div className="flex flex-col space-y-3">
          {agents.map((a: any) => (
            <div
              key={a.id}
              className="flex items-center px-4 py-2 space-x-3 border rounded-md"
            >
              <span
                className="flex items-center justify-center w-4 h-4 bg-green-100 rounded-full"
                aria-hidden="true"
              >
                <span className="w-2 h-2 bg-green-400 rounded-full"></span>
              </span>

              <div>
                <p className="font-semibold text-gray-800">
                  {a.first_name} {a.last_name}
                </p>
                <p className="text-sm text-gray-500">{a.email}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};
