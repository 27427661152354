import { FC } from "react";
import { Drawer, DrawerHeader } from "components/Drawer";
import { useLogStream } from "context/LogStreamContext";
import { useScrollToBottom } from "hooks/useScrollToBottom";
import { Formik } from "formik";
import { TextField } from "components/formik/TextField";
import { Button } from "components/Button";

interface Log {
  level: "debug" | "info" | "warning" | "error";
  timestamp: string;
  message: string;
}

const logColors: Record<Log["level"], string> = {
  debug: "text-gray-400",
  info: "text-gray-100",
  warning: "text-yellow-400",
  error: "text-red-400",
}

const LogLine: FC<{ log: Log }> = ({ log }) => {
  const className = [
    "_LogLine py-0.5",
    logColors[log.level]
  ].filter(Boolean).join(" ");

  return (
    <div className={className}>
      <span className="mr-3 font-light text-right" style={{ width: 100, fontSize: 9 }}>{log.timestamp}</span>
      <span>{log.message}</span>
    </div>
  )
};

interface LogStreamDrawerProps {
  isOpen: boolean;
  onClose(): void;
};

export const LogStreamDrawer: FC<LogStreamDrawerProps> = props => {
  const { isOpen, onClose } = props;

  const { logStreamId, logs, setLogStreamId } = useLogStream();

  const logsEndRef = useScrollToBottom<HTMLDivElement>([logs]);

  return (
    <Drawer isOpen={isOpen} onRequestClose={onClose} width="md">
      <div className="flex flex-col min-h-screen">
        <DrawerHeader title="Log Stream" icon="terminal" onClose={onClose} />

        <Formik
          initialValues={{ logStreamId: logStreamId || "" }}
          onSubmit={(values) => setLogStreamId(values.logStreamId)}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="flex items-end gap-2 px-4 py-6">
              <div className="flex-grow">
                <TextField name="logStreamId" label="Log Stream" />
              </div>
              <Button type="submit" kind="primary" color="blue">Stream</Button>
            </form>
          )}
        </Formik>

        <h3 className="px-4 py-6 font-semibold text-gray-700">Logs</h3>
        <pre className="flex-grow p-3 overflow-x-auto text-xs bg-gray-800 shadow-inner">
          {
            logs.length === 0 ? (
              <p className="font-mono text-xs text-center text-gray-300">Waiting for logs on "{logStreamId}" stream...</p>
            ) : (
              logs.map((log, i) => (<LogLine key={i} log={log} />))
            )
          }
          <div ref={logsEndRef} />
        </pre>
      </div>
    </Drawer>
  );
};
