import { FC } from "react";
import { useToggle } from "hooks/useToggle";

interface JsonDebuggerProps {
  label?: string;
  data: any;
};

export const JsonDebugger: FC<JsonDebuggerProps> = props => {
  const { data, label = "View Debugger Data" } = props;
  const [isOpen, toggleOpen] = useToggle();

  return (
    <div className="_JsonDebugger hover:opacity-100 p-2 text-left transition-opacity duration-150 bg-white rounded-md opacity-50">
      <button type="button" className="hover:bg-purple-100 p-2 text-xs text-purple-500 underline bg-transparent cursor-pointer" onClick={toggleOpen}>{label}</button>
      {
        isOpen ? (
          <pre className="p-2 mt-2 font-mono text-xs text-green-400 bg-gray-800 rounded-md shadow-inner">
            {JSON.stringify(data, null, 2)}
          </pre>
        ) : null
      }
    </div>
  );
};
