import React, { FC } from "react";
import { components, OptionProps } from "react-select";

const Badge: FC<{ className?: string }> = (props) => {
  const { className = "", children } = props;
  return <span className={`text-xs ${className}`}>{children}</span>;
};

export interface SkillOptionModel {
  id: string;
  name: string;
  iexName: string;
  caseSystem: string;
};

export const SkillOption: FC<OptionProps<SkillOptionModel, true>> = React.memo((props) => {
  return (
    <components.Option {...props}>
      <div className="flex justify-between">
        <p className="flex-grow">{props.data.name}</p>
        <Badge>{props.data.caseSystem}</Badge>
      </div>
      <p className="text-xs italic font-light text-gray-700">{props.data.iexName}</p>
    </components.Option>
  );
});
