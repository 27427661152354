import { FC, useState, useCallback } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "components/Tooltip";
import { useTimeout } from "hooks/useTimeout";

interface CopyTextProps {
  text: string;
  label?: string;
}

export const CopyText: FC<CopyTextProps> = (props) => {
  const { text, label } = props;
  const [copied, setCopied] = useState(false);
  const setTimeout = useTimeout();

  const clearMessage = useCallback(() => {
    setTimeout(() => setCopied(false), 150);
  }, [setTimeout, setCopied]);

  const handleCopyClick = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 4000);
  }, [setTimeout, setCopied]);

  return (
    <Tooltip
      tip={copied ? "Copied!" : "Click to Copy"}
      onMouseLeave={clearMessage}
      color="purple"
      style={{ width: 110 }}
    >
      <CopyToClipboard text={text} onCopy={handleCopyClick}>
        <span className="CopyText border-navy-700 text-navy-700 font-mono border-b border-b-2 border-dashed cursor-pointer">
          {label || text}
        </span>
      </CopyToClipboard>
    </Tooltip>
  );
};
