import { forwardRef, HTMLAttributes, ReactNode } from "react";

type FieldStructureProps = {
  id?: string;
  name?: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
  label: ReactNode;
  labelId?: string;
  className?: string;
  labelClassName?: string;
  htmlFor?: string;
} & HTMLAttributes<HTMLDivElement>;

export const VerticalField = forwardRef<HTMLDivElement, FieldStructureProps>(
  (props, ref) => {
    const {
      name,
      label,
      indicateOptional = false,
      indicateRequired = false,
      children,
      className = "",
      labelId,
      htmlFor,
      ...rest
    } = props;
    return (
      <div className={`text-left ${className}`} ref={ref} {...rest}>
        <label
          id={labelId}
          htmlFor={htmlFor}
          className="block mb-1 font-bold text-left text-gray-700"
        >
          {label}
          {indicateOptional && (
            <span className="ml-2 text-xs font-normal text-gray-500">
              Optional
            </span>
          )}
          {indicateRequired && (
            <span className="ml-2 text-xs italic font-normal text-purple-500">
              Required
            </span>
          )}
        </label>
        {children}
      </div>
    );
  }
);

export const HorizontalField = forwardRef<HTMLDivElement, FieldStructureProps>(
  (props, ref) => {
    const {
      name,
      label,
      indicateOptional = false,
      indicateRequired = false,
      children,
      className = "",
      labelId,
      htmlFor,
      ...rest
    } = props;

    return (
      <div
        className={`md:flex md:items-start ${className}`}
        ref={ref}
        {...rest}
      >
        <div className="md:w-1/3">
          <label
            id={labelId}
            htmlFor={htmlFor}
            className="md:py-2 md:text-right md:mb-0 block py-1 pr-4 mb-1 font-bold text-gray-700"
          >
            {label}
            {indicateOptional && (
              <span className="block ml-2 text-xs font-normal text-gray-500">
                Optional
              </span>
            )}
            {indicateRequired && (
              <span className="md:block ml-2 text-xs italic font-normal text-purple-500">
                Required
              </span>
            )}
          </label>
        </div>
        <div className="md:w-2/3 md:py-1">{children}</div>
      </div>
    );
  }
);
