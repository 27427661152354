import { FAIcon } from "components/FAIcon";
import { FC } from "react";

interface ReviewerModel {
  firstName: string;
  lastName: string;
}

interface ReviewerProps {
  reviewer: ReviewerModel;
  isOnline: boolean;
}

export const Reviewer: FC<ReviewerProps> = (props) => {
  const { reviewer, isOnline } = props;
  const initials = `${reviewer.firstName.slice(0, 1)}${reviewer.lastName.slice(
    0,
    1
  )}`;

  return (
    <div className="_Reviewer">
      <div className="gap-x-2 flex items-center">
        {isOnline ? (
          <span className="h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
        ) : (
          <span className="h-2.5 w-2.5 rounded-full ring-2 ring-white bg-gray-300"></span>
        )}
        <p className={`text-sm ${isOnline ? "text-gray-700 font-medium" : "text-gray-500"}`}>
          {reviewer.firstName} {reviewer.lastName}
        </p>
      </div>
      <div className="gap-x-2 flex items-center mt-1 text-xs">
        <span className="text-gray-500"><FAIcon icon="lock" /></span>
        <p className="font-medium text-gray-600">3h 23m</p>
      </div>
    </div>
  )

  // return (
  //   <div className="_Reviewer flex items-center">
  //     <div>
  //       <span className="relative inline-block">
  //         <span className="inline-flex items-center justify-center w-10 h-10 bg-gray-500 rounded-full">
  //           <span className="font-medium leading-none text-white">
  //             {initials}
  //           </span>
  //         </span>
  //         {isOnline ? (
  //           <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
  //         ) : (
  //           <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-gray-300"></span>
  //         )}
  //       </span>
  //     </div>
  //     <div className="ml-3">
  //       <p className="text-sm font-medium text-gray-700">
  //         {reviewer.firstName} {reviewer.lastName}
  //       </p>
  //       {isOnline ? (
  //         <p className="text-xs font-medium text-green-500">Online</p>
  //       ) : (
  //         <p className="text-xs font-medium text-gray-500">Offline</p>
  //       )}
  //     </div>
  //   </div>
  // );
};
