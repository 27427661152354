import React, { FC, useCallback } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { ScreenTitle } from "context/ScreenTitle";
import { AppealRequestInfoPanel } from "../AppealRequestInfoPanel";
import { CircleIcon } from "components/CircleIcon";
import { FadeUpIn } from "components/Animations";
import { CircleIconHeader } from "components/CircleIconHeader";
import { ConfirmationForm } from "./ConfirmationForm";
import { Container } from "components/Container";

interface ConfirmationStepProps { }

export const ConfirmationStep: FC<ConfirmationStepProps> = (props) => {
  const match = useRouteMatch<{ appealRequestId: string }>();
  const { appealRequestId } = match.params;

  const history = useHistory();

  const goBack = useCallback(() => history.goBack(), [history]);

  const onSuccess = useCallback(() => {
    return history.push(`/ip/appeals/${appealRequestId}`);
  }, [appealRequestId, history]);

  return (
    <>
      <ScreenTitle title={["New Post-Decision Request", "Please Confirm"]} />
      <Container className="_ConfirmationStep px-4 pt-6">
        <FadeUpIn show>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <div className="rounded-xl p-4 bg-white shadow-lg">
                <div className="flex items-center gap-3">
                  <CircleIcon size={32} icon="file-alt" />
                  <h4 className="text-xl font-black text-gray-600">
                    Case Info
                  </h4>
                </div>
                <AppealRequestInfoPanel
                  appealRequestId={appealRequestId}
                  displayAppealRequest
                />
              </div>
            </div>

            <div className="col-span-2">
              <div className="rounded-xl p-4 bg-white shadow-xl">
                <CircleIconHeader icon="file-alt">
                  Please Confirm
                </CircleIconHeader>
                <ConfirmationForm
                  appealRequestId={appealRequestId}
                  onBack={goBack}
                  onSuccess={onSuccess}
                />
              </div>
            </div>
          </div>
        </FadeUpIn>
      </Container>
    </>
  );
};
