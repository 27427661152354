import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CircleIcon } from "components/CircleIcon";
import { FAIcon } from "components/FAIcon";

interface ModalHeaderProps {
  icon: IconProp;
  title: string;
  onClose(): void;
  color?: AppColor;
}

export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { icon, title, onClose, color = "blue" } = props;
  return (
    <div className="ModalHeader">
      <div className="flex px-6 py-4 justify-between items-center">
        <div className="flex items-center">
          <div>
            <CircleIcon icon={icon} color={color} />
          </div>
          <div className="ml-4">
            <h4 className="text-gray-700 text-2xl">{title}</h4>
          </div>
        </div>
        <button
          className="text-gray-500 hover:text-gray-700 text-xl"
          onClick={onClose}
        >
          <FAIcon icon="times" />
        </button>
      </div>
    </div>
  );
};
