import { FC, useCallback, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { ScreenTitle } from "context/ScreenTitle";
import { StageTabs, StageTab } from "components/StageTabs";
import {
  FilterPanel,
  FilterModel,
  defaultValue,
  removeUnusedFilterKeys,
} from "./FilterPanel";
import { Table, TableContainer, TD, TH } from "components/Table";
import { Spinner } from "components/Spinner";
import { NoResults } from "components/NoResults";
import qs from "query-string";
import { Link, useLocation } from "react-router-dom";
import { Reviewer } from "./Reviewer";
import { Button, ButtonLink } from "components/Button";
import { Tooltip } from "components/Tooltip";
import {
  HandRaisedIcon as HandIcon,
  EyeIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { distanceInWords, isPast, mmDdYyyy } from "utils/dateFormatters";
import { removeVoidKeys } from "utils/removeVoidKeys";
import { AppealRequestModel } from "./FilterPanel/model";
import { useChannelEvent } from "context/ChannelContext";
import { FAIcon } from "components/FAIcon";
import { Container } from "components/Container";
import { useOnlineAgents } from "hooks/useOnlineAgents";
import { makeAppendItems } from "lib/makeAppendItems";
import { Badge } from "components/Badge";

const PAGE_SIZE = 50;

export const LIST_APPEAL_REQUESTS = gql`
  query ListAppealRequests(
    $first: Int
    $after: UUID4
    $filter: ListAppealRequestsFilter
  ) {
    appealRequests(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        episodeId
        stage
        timingStatus
        dueAt
        dueAtString(timeZone: "US/Eastern")
        unpendAt
        unpendAtString(timeZone: "US/Eastern")
        isPastDue
        requestedAt
        requestedAtString(timeZone: "US/Eastern")
        warningMessage
        warningAt
        warningAtString(timeZone: "US/Eastern")
        movedToClientPortal
        caseProfile {
          id
          caseNumber
          episodeId
          memberFirstName
          memberLastName
          memberDob
          healthPlan {
            id
            name
          }
        }
        assignedAgent {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export interface ListData {
  appealRequests: Paginated<AppealRequestModel>;
}

const GET_STAGE_COUNTS = gql`
  query GetStageCounts($filter: ListAppealRequestsFilter) {
    appealRequestStageCounts(filter: $filter) {
      stage
      assignedCount
      unassignedCount
    }
  }
`;

interface StageCountData {
  appealRequestStageCounts: {
    stage: string;
    assignedCount: number;
    unassignedCount: number;
  }[];
}

interface StageCountVariables {
  filter: FilterModel | { stage: string };
}

export const stageTabs = [
  { value: "intake", label: "Intake" },
  { value: "pending", label: "Pend" },
  { value: "nurse_review", label: "Nurse Review" },
  { value: "md_review", label: "MD Review" },
  { value: "wrap_up", label: "Wrap-Up" },
  { value: "verbal_notification", label: "Verbal Notification" },
  { value: "intent_to_deny", label: "Intent to Deny" },
  { value: "panel_review", label: "Panel Review" },
  { value: "external_review", label: "External Review" },
  { value: "closed", label: "Closed" },
];

// NB: These are the stages where agents actually take and work cases
const takeTabs = ["intake", "verbal_notification", "wrap_up"];

type AppealTimingStatus = "on_track" | "at_risk" | "missed";

export const Status: FC<{
  className?: string;
  status: AppealTimingStatus;
}> = (props) => {
  const { status, className = "" } = props;
  const statusClassName = {
    at_risk: "bg-yellow-100 border-yellow-300 text-yellow-700",
    on_track: "bg-green-100 border-green-300 text-green-700",
    missed: "bg-red-100 border-red-300 text-red-700",
  }[status];
  const label = {
    at_risk: "At Risk",
    on_track: "On Track",
    missed: "Missed",
  }[status];
  return (
    <span
      className={`${statusClassName} ${className} border font-bold inline-block leading-tight py-1 px-2 rounded-full text-xs`}
    >
      {label}
    </span>
  );
};

export const trClassNames: Record<AppealTimingStatus, string> = {
  on_track: "bg-white hover:bg-blue-50",
  at_risk: "bg-yellow-50 border-yellow-400 border-l-8",
  missed: "bg-red-50 border-red-400 border-l-8",
};

interface AppealQueuesProps { }

export const AppealQueuesScreen: FC<AppealQueuesProps> = (props) => {
  const [activeTab, setActiveTab] = useState<string>("ALL");
  const [isRefetching, setIsRefetching] = useState(false);

  const location = useLocation();
  const urlParams = qs.parse(location.search);

  const startingFilter = urlParams.healthPlanId
    ? { ...defaultValue, healthPlanId: urlParams.healthPlanId as string }
    : defaultValue;

  const [filter, setFilter] = useState<FilterModel>(startingFilter);

  const filterForServer = {
    stage: activeTab,
    ...removeUnusedFilterKeys(removeVoidKeys(filter)),
  };

  const {
    data: listData,
    loading: listLoading,
    error: listError,
    refetch,
    fetchMore,
  } = useQuery<ListData>(LIST_APPEAL_REQUESTS, {
    fetchPolicy: "network-only",
    variables: {
      first: PAGE_SIZE,
      filter: filterForServer,
    },
  });

  const {
    data: countData,
    refetch: refetchCounts,
    loading: countsLoading,
  } = useQuery<StageCountData, StageCountVariables>(GET_STAGE_COUNTS, {
    fetchPolicy: "network-only",
    variables: { filter: removeUnusedFilterKeys(removeVoidKeys(filter)) },
  });

  const onlineAgents = useOnlineAgents();

  function isOnline(agentId: string) {
    return !!onlineAgents.find((a) => a.id === agentId);
  }

  useChannelEvent("room:lobby", "manual", (data) => {
    console.log(data);
  });

  const refetchAll = useCallback(() => {
    if (isRefetching) return;
    setIsRefetching(true);
    Promise.all([refetch(), refetchCounts()]).finally(() =>
      setIsRefetching(false)
    );
  }, [refetch, refetchCounts, isRefetching, setIsRefetching]);

  return (
    <Container className="_AppealQueues">
      <ScreenTitle title="Post-Decision Requests" />

      <FilterPanel value={filter} onChange={setFilter} />

      <div className="flex pt-4">
        <button
          className={`${activeTab === "ALL" ? "bg-blue-200" : "bg-gray-100"
            } border border-gray-300 cursor-pointer font-medium hover:text-gray-900 mr-3 px-6 rounded-md text-gray-500 text-sm`}
          onClick={() => setActiveTab("ALL")}
        >
          All
        </button>
        <div className="flex-grow">
          <StageTabs activeTab={activeTab} onChange={setActiveTab}>
            {stageTabs.map((s) => {
              const { assignedCount, unassignedCount } = stageCounts(
                countData,
                s.value
              );
              return (
                <StageTab key={s.value} tab={s.value}>
                  {s.label}
                  {s.value !== "closed" ? (
                    <div
                      className="absolute bottom-0 block"
                      style={{
                        left: "50%",
                        transform: "translate(-50%, 50%)",
                      }}
                    >
                      <Tooltip
                        tip={
                          <>
                            <p className="whitespace-nowrap text-xs">
                              {assignedCount} Assigned
                            </p>
                            <p className="whitespace-nowrap text-xs">
                              {unassignedCount} Unassigned
                            </p>
                          </>
                        }
                      >
                        <p className="whitespace-nowrap p-1 text-xs leading-none whitespace-no-wrap bg-white border rounded-lg">
                          <span
                            className={`mr-1 ${assignedCount === 0
                              ? "text-gray-700"
                              : "text-green-600"
                              }`}
                          >
                            {assignedCount}
                            <FAIcon
                              icon="lock"
                              className={`ml-1 ${assignedCount === 0
                                ? "text-gray-400"
                                : "text-green-400"
                                }`}
                            />
                          </span>{" "}
                          |{" "}
                          <span
                            className={`ml-1 ${unassignedCount === 0
                              ? "text-gray-700"
                              : "text-orange-600"
                              }`}
                          >
                            {unassignedCount}
                            <FAIcon
                              icon="unlock"
                              className={`ml-1 ${unassignedCount === 0
                                ? "text-gray-400"
                                : "text-orange-400"
                                }`}
                            />
                          </span>
                        </p>
                      </Tooltip>
                    </div>
                  ) : null}
                </StageTab>
              );
            })}
          </StageTabs>
        </div>

        <div className="flex items-center ml-2">
          <Tooltip tip="Refresh" placement="top">
            <Button
              type="button"
              size="sm"
              disabled={isRefetching || countsLoading || listLoading}
              onClick={refetchAll}
            >
              <ArrowPathIcon
                className={`w-6 h-6 ${isRefetching || countsLoading || listLoading ? "spinning" : ""
                  }`}
              />
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="py-6">
        {listLoading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : listError || !listData?.appealRequests ? (
          <div className="p-8 text-center">
            <p className="py-4 text-gray-700">Failed to load</p>
            <Button type="button" size="sm" onClick={refetch}>
              Retry
            </Button>
          </div>
        ) : (
          <>
            {listData?.appealRequests.items.length === 0 ? (
              <NoResults
                icon="inbox"
                text="No matching post-decision requests"
              />
            ) : (
              <TableContainer>
                <Table className="text-left">
                  <thead>
                    <tr>
                      <TH>Case #</TH>
                      <TH>Member</TH>
                      <TH>Health Plan</TH>
                      <TH>Stage</TH>
                      {takeTabs.includes(activeTab) ? (
                        <TH>Checked Out By</TH>
                      ) : null}
                      <TH>Requested At</TH>
                      {activeTab === "pending" ||
                        activeTab === "intent_to_deny" ? (
                        <TH>Pend Until</TH>
                      ) : (
                        <TH>Due At</TH>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {listData?.appealRequests.items.map((item) => {
                      const memberName = [
                        item.caseProfile.memberFirstName,
                        item.caseProfile.memberLastName,
                      ]
                        .filter(Boolean)
                        .join(" ");

                      const link =
                        item.stage === "intake" && false
                          ? `/ip/drafts/${item.id}/questions`
                          : `/ip/cases/${item.caseProfile.id}`;
                      // : `${match.path}/${item.id}`;

                      return (
                        <tr
                          key={item.id}
                          className={`${trClassNames[item.timingStatus]
                            } transition-colors duration-300`}
                        >
                          <TD className="w-12 px-4">
                            <Link
                              to={link}
                              className="hover:text-blue-700 font-semibold text-blue-600 underline"
                            >
                              <p>{item.caseProfile.caseNumber}</p>
                            </Link>
                            <div className="whitespace-nowrap pt-1">
                              <Tooltip tip="Preview" placement="top">
                                <Button type="button" size="xs">
                                  <EyeIcon className="w-4 h-4" />
                                </Button>
                              </Tooltip>
                              <Tooltip tip="Take" placement="top">
                                <ButtonLink
                                  to={`/ip/drafts/${item.id}/questions`}
                                  className="ml-1"
                                  icon="highlighter"
                                  size="xs"
                                >
                                  <HandIcon className="w-4 h-4" />
                                </ButtonLink>
                              </Tooltip>
                            </div>
                          </TD>
                          <TD>
                            <p className="text-base">{memberName || "-"}</p>
                            <p className="text-xs">
                              <span className="mr-1 italic font-thin text-gray-500">
                                DOB:
                              </span>
                              {mmDdYyyy(item.caseProfile.memberDob)}
                            </p>
                          </TD>
                          <TD>
                            <p>{item.caseProfile.healthPlan.name}</p>
                          </TD>
                          <TD className="flex flex-col items-center text-center">
                            <p className="text-sm font-semibold text-gray-700 capitalize">{item.stage.replaceAll('_', ' ')}</p>
                            {
                              item.movedToClientPortal ? (
                                <Badge color="teal" className="mt-2 ml-0">
                                  <FAIcon icon="arrow-right" className="mr-1" />
                                  Client Portal
                                </Badge>
                              ) : null
                            }
                          </TD>
                          {takeTabs.includes(activeTab) ? (
                            <TD>
                              {item.assignedAgent ? (
                                <Reviewer
                                  reviewer={item.assignedAgent}
                                  isOnline={isOnline(item.assignedAgent.id)}
                                />
                              ) : (
                                <p className="font-semibold text-orange-600">
                                  Unassigned
                                </p>
                              )}
                            </TD>
                          ) : null}

                          <TD className="text-xs font-semibold">
                            {item.requestedAt && item.requestedAtString ? (
                              <>
                                <p className="whitespace-nowrap text-nowrap">{item.requestedAtString}</p>
                                <p>
                                  {isPast(item.requestedAt) ? (
                                    <span className="font-semibold">
                                      {distanceInWords(item.requestedAt)} ago
                                    </span>
                                  ) : (
                                    <span className="font-semibold">
                                      in {distanceInWords(item.requestedAt)}
                                    </span>
                                  )}
                                </p>
                              </>
                            ) : null}
                          </TD>
                          {activeTab === "pending" ||
                            activeTab === "intent_to_deny" ? (
                            <TD className="text-xs font-semibold">
                              {item.unpendAt && item.unpendAtString ? (
                                <>
                                  <p className="whitespace-nowrap text-nowrap">{item.unpendAtString}</p>
                                  <p>
                                    {isPast(item.unpendAt) ? (
                                      <span className="font-semibold">
                                        {distanceInWords(item.unpendAt)} ago
                                      </span>
                                    ) : (
                                      <span className="font-semibold">
                                        in {distanceInWords(item.unpendAt)}
                                      </span>
                                    )}
                                  </p>
                                </>
                              ) : null}
                            </TD>
                          ) : (
                            <TD className="text-xs font-semibold">
                              {item.dueAt && item.dueAtString ? (
                                <>
                                  <p className="whitespace-nowrap text-nowrap">{item.dueAtString}</p>
                                  <p>
                                    {item.isPastDue ? (
                                      <span className="font-semibold">
                                        {distanceInWords(item.dueAt)} ago
                                      </span>
                                    ) : (
                                      <span className="font-semibold">
                                        in {distanceInWords(item.dueAt)}
                                      </span>
                                    )}
                                    <Status
                                      status={item.timingStatus}
                                      className="ml-2"
                                    />
                                  </p>
                                </>
                              ) : null}
                            </TD>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {listData.appealRequests.items.length > 0 ? (
                  <div className="p-2 text-center text-gray-500 bg-white border-t border-gray-300">
                    {listData.appealRequests.endOfList ? (
                      <p>End of List</p>
                    ) : (
                      <Button
                        type="button"
                        size="sm"
                        kind="secondary"
                        color="blue"
                        className="w-full"
                        onClick={() => {
                          fetchMore({
                            query: LIST_APPEAL_REQUESTS,
                            variables: {
                              first: PAGE_SIZE,
                              after: listData.appealRequests.cursor,
                              filter: filterForServer,
                            },
                            updateQuery,
                          });
                        }}
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                ) : null}
              </TableContainer>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

interface StageCountModel {
  stage: string;
  assignedCount: number;
  unassignedCount: number;
}

function stageCounts(
  data: StageCountData | undefined,
  stage: string
): StageCountModel {
  return (
    data?.appealRequestStageCounts.find((s) => s.stage === stage) || {
      stage,
      assignedCount: 0,
      unassignedCount: 0,
    }
  );
}

const updateQuery = makeAppendItems<ListData>("appealRequests");
