import { FC, useCallback, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { ScreenTitle } from "context/ScreenTitle";
import {
  FilterPanel,
  FilterModel,
  defaultValue,
  removeUnusedFilterKeys,
} from "./FilterPanel"
import { Table, TableContainer, TD, TH } from "components/Table";
import { Spinner } from "components/Spinner";
import { NoResults } from "components/NoResults";
import qs from "query-string";
import { Link, useLocation } from "react-router-dom";
import { Button } from "components/Button";
import { distanceInWords, mmDdYyyy } from "utils/dateFormatters";
import { removeVoidKeys } from "utils/removeVoidKeys";
import { UMRequestModel } from "./FilterPanel/model";
import { Container } from "components/Container";
import { makeAppendItems } from "lib/makeAppendItems";

const PAGE_SIZE = 50;

export const LIST_UM_REQUESTS = gql`
  query ListUmRequests($first: Int, $after: UUID4, $filter: ListUmRequestsFilter) {
    umRequests(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        timingStatus
        dueAt
        dueAtString(timeZone: "US/Eastern")
        isPastDue
        warningMessage
        warningAt
        warningAtString(timeZone: "US/Eastern")
        caseProfile {
          id
          caseNumber
          episodeId
          memberFirstName
          memberLastName
          memberDob
          healthPlan {
            id
            name
          }
        }
      }
    }
  }
`;

export interface ListData {
  umRequests: Paginated<UMRequestModel>;
}


type TimingStatus = "on_track" | "at_risk" | "missed";

export const Status: FC<{
  className?: string;
  status: TimingStatus;
}> = (props) => {
  const { status, className = "" } = props;
  const statusClassName = {
    at_risk: "bg-yellow-100 border-yellow-300 text-yellow-700",
    on_track: "bg-green-100 border-green-300 text-green-700",
    missed: "bg-red-100 border-red-300 text-red-700",
  }[status];
  const label = {
    at_risk: "At Risk",
    on_track: "On Track",
    missed: "Missed",
  }[status];
  return (
    <span
      className={`${statusClassName} ${className} border font-bold inline-block leading-tight py-1 px-2 rounded-full text-xs`}
    >
      {label}
    </span>
  );
};

export const trClassNames: Record<TimingStatus, string> = {
  on_track: "bg-white hover:bg-blue-50",
  at_risk: "bg-yellow-50 border-yellow-400 border-l-8",
  missed: "bg-red-50 border-red-400 border-l-8",
};

interface UMRequestsScreenProps { }

export const UMRequestsScreen: FC<UMRequestsScreenProps> = (props) => {
  const [isRefetching, setIsRefetching] = useState(false);

  const location = useLocation();
  const urlParams = qs.parse(location.search);

  const startingFilter = urlParams.healthPlanId
    ? { ...defaultValue, healthPlanId: urlParams.healthPlanId as string }
    : defaultValue;

  const [filter, setFilter] = useState<FilterModel>(startingFilter);

  const filterForServer = {
    ...removeUnusedFilterKeys(removeVoidKeys(filter)),
  };

  const {
    data: listData,
    loading: listLoading,
    error: listError,
    refetch,
    fetchMore,
  } = useQuery<ListData>(LIST_UM_REQUESTS, {
    fetchPolicy: "network-only",
    variables: {
      first: PAGE_SIZE,
      filter: filterForServer,
    },
  });


  const refetchAll = useCallback(() => {
    if (isRefetching) return;
    setIsRefetching(true);
    Promise.all([refetch()]).finally(() =>
      setIsRefetching(false)
    );
  }, [refetch, isRefetching, setIsRefetching]);

  return (
    <Container className="_UMQueues">
      <ScreenTitle title="UM Reviews" />

      <FilterPanel value={filter} onChange={setFilter} />

      <div className="py-6">
        {listLoading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : listError || !listData?.umRequests ? (
          <div className="p-8 text-center">
            <p className="py-4 text-gray-700">Failed to load</p>
            <Button type="button" size="sm" onClick={refetch}>
              Retry
            </Button>
          </div>
        ) : (
          <>
            {listData?.umRequests.items.length === 0 ? (
              <NoResults
                icon="inbox"
                text="No matching UM reviews"
              />
            ) : (
              <TableContainer>
                <Table className="text-left">
                  <thead>
                    <tr>
                      <TH>Case #</TH>
                      <TH>Member</TH>
                      <TH>Health Plan</TH>
                      <TH>Due At</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {listData?.umRequests.items.map((item) => {
                      const memberName = [
                        item.caseProfile.memberFirstName,
                        item.caseProfile.memberLastName,
                      ]
                        .filter(Boolean)
                        .join(" ");

                      const link = `/ip/cases/${item.caseProfile.id}`;

                      return (
                        <tr
                          key={item.id}
                          className={`${trClassNames[item.timingStatus]
                            } transition-colors duration-300`}
                        >
                          <TD className="w-12 px-4">
                            <Link
                              to={link}
                              className="hover:text-blue-700 font-semibold text-blue-600 underline"
                            >
                              <p>{item.caseProfile.caseNumber}</p>
                            </Link>
                          </TD>
                          <TD>
                            <p className="text-base">{memberName || "-"}</p>
                            <p className="text-xs">
                              <span className="mr-1 italic font-thin text-gray-500">
                                DOB:
                              </span>
                              {mmDdYyyy(item.caseProfile.memberDob)}
                            </p>
                          </TD>
                          <TD>
                            <p>{item.caseProfile.healthPlan.name}</p>
                          </TD>

                          <TD className="text-xs font-semibold">
                            {item.dueAt && item.dueAtString ? (
                              <>
                                <p className="whitespace-nowrap text-nowrap">{item.dueAtString}</p>
                                <p>
                                  {item.isPastDue ? (
                                    <span className="font-semibold">
                                      {distanceInWords(item.dueAt)} ago
                                    </span>
                                  ) : (
                                    <span className="font-semibold">
                                      in {distanceInWords(item.dueAt)}
                                    </span>
                                  )}
                                  <Status
                                    status={item.timingStatus}
                                    className="ml-2"
                                  />
                                </p>
                              </>
                            ) : null}
                          </TD>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {listData.umRequests.items.length > 0 ? (
                  <div className="p-2 text-center text-gray-500 bg-white border-t border-gray-300">
                    {listData.umRequests.endOfList ? (
                      <p>End of List</p>
                    ) : (
                      <Button
                        type="button"
                        size="sm"
                        kind="secondary"
                        color="blue"
                        className="w-full"
                        onClick={() => {
                          fetchMore({
                            query: LIST_UM_REQUESTS,
                            variables: {
                              first: PAGE_SIZE,
                              after: listData.umRequests.cursor,
                              filter: filterForServer,
                            },
                            updateQuery,
                          });
                        }}
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                ) : null}
              </TableContainer>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

const updateQuery = makeAppendItems<ListData>("umRequests");
