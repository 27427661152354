import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faUnlock } from "@fortawesome/free-solid-svg-icons/faUnlock";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faHighlighter } from "@fortawesome/free-solid-svg-icons/faHighlighter";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faCrop } from "@fortawesome/free-solid-svg-icons/faCrop";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faInbox } from "@fortawesome/free-solid-svg-icons/faInbox";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faFax } from "@fortawesome/free-solid-svg-icons/faFax";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faHashtag } from "@fortawesome/free-solid-svg-icons/faHashtag";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons/faFileDownload";
// import { faComments } from '@fortawesome/free-solid-svg-icons/faComments'
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons/faUserEdit";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faHospitalAlt } from "@fortawesome/free-solid-svg-icons/faHospitalAlt";
import { faListAlt } from "@fortawesome/free-solid-svg-icons/faListAlt";
import { faStethoscope } from "@fortawesome/free-solid-svg-icons/faStethoscope";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons/faAddressCard";
import { faCalendarAlt as farCalendarAlt } from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import { faCreditCard as farCreditCard } from "@fortawesome/free-regular-svg-icons/faCreditCard";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons/faCalendarCheck";
import { faCalendarTimes } from "@fortawesome/free-regular-svg-icons/faCalendarTimes";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faUserMd } from "@fortawesome/free-solid-svg-icons/faUserMd";
import { faLevelUpAlt } from "@fortawesome/free-solid-svg-icons/faLevelUpAlt";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faShare } from "@fortawesome/free-solid-svg-icons/faShare";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons/faCommentDots";
import { faSitemap } from "@fortawesome/free-solid-svg-icons/faSitemap";
import { faSignature } from "@fortawesome/free-solid-svg-icons/faSignature";
import { faMask } from "@fortawesome/free-solid-svg-icons/faMask";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import { faTerminal } from "@fortawesome/free-solid-svg-icons/faTerminal";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";

/**
 * Warning: side-effects!
 * See: https://github.com/FortAwesome/react-fontawesome/tree/9ee4f58cfc07296f250b870249a66ed493f8589f#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
 * Building this "library" allows us to only import the SVG icons we care to use,
 * imported individually above, and then use them by string reference in components.
 * This function needs to be run in an initializing module of the app/site.
 */
export default function createIconLibrary() {
  library.add(
    faFileDownload,
    faSignature,
    faEye,
    faHighlighter,
    faFilter,
    faEnvelope,
    faFileAlt,
    faLock,
    faUnlock,
    faArrowRight,
    faArrowLeft,
    faBars,
    faHome,
    faGlobe,
    faCalendarAlt,
    faInbox,
    faChartBar,
    faCog,
    faCrop,
    faAngleDown,
    faCheck,
    faCheckCircle,
    faTimesCircle,
    faPlusCircle,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faTimes,
    faPlus,
    faPencilAlt,
    faUserEdit,
    faUserPlus,
    faBell,
    faUsers,
    faHospitalAlt,
    faMapMarkerAlt,
    faListAlt,
    faUserMd,
    faStethoscope,
    faAddressCard,
    faClipboardList,
    farCalendarAlt,
    faCalendarCheck,
    faCalendarTimes,
    faClock,
    faInfoCircle,
    faCode,
    faLevelUpAlt,
    faSearch,
    faChevronDown,
    faChevronLeft,
    faChartLine,
    faPhone,
    faFax,
    faHashtag,
    farCreditCard,
    faShare,
    faPaperPlane,
    faCommentDots,
    faSitemap,
    faTrash,
    faMask,
    faEnvelopeOpenText,
    faTerminal,
    faExternalLinkAlt
  );
}
