import { forwardRef, HTMLAttributes } from "react";
import { Transition } from "react-transition-group";

interface PopUpProps extends HTMLAttributes<HTMLDivElement> {
  show?: boolean;
}

const stateClassNames = {
  enter: "transition ease-out duration-100",
  leave: "transition ease-in duration-100",
};

const stageClassNames = {
  entering: "transform opacity-0 scale-95 translate-y-4",
  entered:
    "transition ease-out duration-100 transform opacity-100 scale-100 translate-y-0",
  exiting: "transform opacity-0 scale-95 translate-y-4",
};

export const PopUp = forwardRef<HTMLDivElement, PopUpProps>((props, ref) => {
  const { show, className = "", children, ...rest } = props;

  const stateName = show ? "enter" : "leave";
  const stateClassName = stateClassNames[stateName];

  return (
    <Transition
      appear
      nodeRef={ref}
      in={show}
      timeout={{ enter: 0, exit: 75 }}
      unmountOnExit
    >
      {(stage) => (
        <div
          {...rest}
          ref={ref}
          className={`_PopUp _${stage} ${className} ${stateClassName} ${stageClassNames[stage] || ""
            }`}
        >
          {children}
        </div>
      )}
    </Transition>
  );
});
