import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { Socket } from "phoenix";
import { useComponentId } from "hooks/useComponentId";
import { ChannelManager, Handler } from "./ChannelManager";

/**
 * SocketContext.
 */

interface SocketContextValue {
  socket: Socket;
  channelManager: ChannelManager;
}

const socketContext = createContext<SocketContextValue>(null!);

interface SocketProviderProps {
  endpoint: string;
  token: string;
}

export const SocketProvider: FC<SocketProviderProps> = (props) => {
  const { endpoint, token } = props;

  const socket = useRef(
    new Socket(endpoint, {
      params: { jwt: token },
    })
  );

  const channelManager = useRef(new ChannelManager(socket.current));

  /**
   * Attempt to connect to the socket onMount
   */
  useEffect(() => {
    socket.current.connect();
    return () => socket.current.disconnect(); // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const ctxValue = useMemo(
    () => ({ socket: socket.current, channelManager: channelManager.current }),
    []
  );

  return <socketContext.Provider value={ctxValue} {...props} />;
};

function useSocket() {
  const context = useContext(socketContext);
  if (context === undefined) {
    throw new Error(`useSocket must be used within a SocketProvider`);
  }
  return context;
}

/**
 * Binds a channel event to a handler. The handler will be called whenever the
 * event is received. The handler will be unbound when the component unmounts.
 * The handler will be rebound if the topic or eventName changes.
 *
 * The fourth argument is a dependency array, which is used to memoize the
 * handler. If the handler is a lambda, to prevent it from being recreated on
 * every render, an empty array is the default value for the dependency array.
 *
 * @param topic
 * @param eventName
 * @param handler
 * @param deps
 */
export function useChannelEvent<Data extends JSONObject = JSONObject>(
  topic: string | void,
  eventName: string,
  handler: Handler<Data>,
  deps: React.DependencyList = []
) {
  const componentId = useComponentId();
  const { channelManager } = useSocket();

  const memoizedHandler = useMemo(() => handler, deps); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (topic) {
      channelManager.bindEvent(componentId, topic, eventName, memoizedHandler as any);
      return () => channelManager.unbindEvent(componentId, topic, eventName);
    }
    return;
  }, [componentId, topic, eventName, memoizedHandler, channelManager]);

  useEffect(() => {
    if (topic) {
      return () => channelManager.unsubscribeEvents(componentId, topic);
    }
    return;
  }, [componentId, topic, channelManager]);
}

export function useChannelPresence(topic: string | void) {
  const componentId = useComponentId();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { channelManager } = useSocket();

  useEffect(() => {
    if (topic) {
      channelManager.bindPresence(componentId, topic, forceUpdate);
      return () => channelManager.unbindPresence(componentId, topic);
    }
    return;
  }, [componentId, topic, channelManager]);

  return topic && channelManager.getChannelPresenceData(topic);
}
