export function removeVoidKeys<T extends Record<string, any>>(
  objWithNulls: Partial<Record<keyof T, any>>
): T {
  let obj: Record<string, any> = {};
  for (const key in objWithNulls) {
    if (isPresent(objWithNulls[key])) {
      obj[key] = objWithNulls[key];
    }
  }
  return obj as T;
}

function isPresent(value: any): boolean {
  return ![null, undefined, ""].includes(value);
}
