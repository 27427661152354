import { FC, useCallback, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/Button";
import { ScreenTitle } from "context/ScreenTitle";
import toast from "react-hot-toast";
import { Table, TableContainer, TD, TH } from "components/Table";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import { useLogStream } from "context/LogStreamContext";

const LIST_MODALITIES = gql`
  query ListModalities {
    modalities {
      id
      name
      skills {
        id
        name
        iexId
        iexName
        caseSystem
      }
    }
  }
`;

interface Data {
  modalities: ModalityModel[];
}

interface ModalityModel {
  id: string;
  name: string;
  skills: SkillModel[];
}

interface SkillModel {
  id: string;
  name: string;
  iexId: string;
  iexName: string;
  caseSystem: string;
}

const SYNC_OVICARE_MODALITIES = gql`
  mutation SyncOvicareModalities {
    syncOvicareModalities {
      success
      error {
        key
        message
      }
    }
  }
`;

interface MutationData {
  syncOvicareModalities: {
    success: boolean;
    error: {
      key: string;
      message: string;
    } | null;
  };
}

interface ModalitiesScreenProps { }

export const ModalitiesScreen: FC<ModalitiesScreenProps> = (props) => {
  const [syncError, setSyncError] = useState<InputError | null>(null);
  const { setLogStreamId } = useLogStream();

  const { data, loading, error, refetch } = useQuery<Data>(LIST_MODALITIES);

  const [syncOvicareModalitiesMut, { loading: isQueueingSync }] =
    useMutation<MutationData>(SYNC_OVICARE_MODALITIES);

  const syncOvicareModalities = useCallback(async () => {
    setSyncError(null);
    setLogStreamId("modality_sync");
    try {
      const { data } = await syncOvicareModalitiesMut();
      if (data?.syncOvicareModalities.error) {
        setSyncError(data.syncOvicareModalities.error);
        toast.error("Modality sync failed.");
      } else if (data?.syncOvicareModalities.success) {
        toast.success("Modality sync succeeded.");
        return refetch();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
      return e;
    }
  }, [setLogStreamId, syncOvicareModalitiesMut, refetch]);

  return (
    <>
      <ScreenTitle title="Modalities" />
      <div className="_ModalitiesScreen sm:rounded-md sm:overflow-hidden shadow">
        <div className="sm:p-6 px-4 py-6 bg-white">

          <div className="flex items-center justify-between pb-4">
            <div>
              <h2
                id="modalities_heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Program Skills (Modalities)
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Manage system modalities
              </p>
            </div>
            <div className="pt-6 pl-6">
              <Button
                onClick={syncOvicareModalities}
                color="gold"
                kind="primary"
                isLoading={isQueueingSync}
                disabled={isQueueingSync}
              >
                Run Modality Sync
              </Button>
            </div>
          </div>
          {syncError ? (
            <p className="bg-red-50 p-2 text-red-700 border border-red-300 rounded">
              {syncError.message}
            </p>
          ) : null}

          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.modalities ? (
            <p>Failed to load.</p>
          ) : data.modalities.length === 0 ? (
            <NoResults icon="user-md" text="No matching modalities" />
          ) : (
            <TableContainer>
              <Table className="text-left">
                <thead>
                  <tr>
                    <TH>Name</TH>
                    <TH>Skills</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.modalities.map((m) => (
                    <tr key={m.id}>
                      <TD>{m.name}</TD>
                      <TD>
                        <ul>
                          {m.skills.map((s) => (
                            <li key={s.id}>{s.iexName} ({s.caseSystem})</li>
                          ))}
                        </ul>
                      </TD>
                      <TD></TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};
