import { Presence } from "phoenix";
import { useChannelPresence } from "context/ChannelContext";

interface AgentModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export function useOnlineAgents(): AgentModel[] {
  const presence = useChannelPresence("room:lobby") || {};

  const agents = Presence.list(presence, (_id, { metas }) => metas[0].agent);

  return agents;
}
