import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { ImportSSRulesForm } from "./ImportSSRulesForm";

interface AddSSRulesModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const AddSSRulesModal: FC<AddSSRulesModalProps> = (props) => {
  const { isOpen, onClose, onSuccess } = props;

  return (
    <Modal
      className="_AddSSRulesModal"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalHeader
        icon="plus"
        title="Add Similar Specialty Rules"
        onClose={onClose}
      />
      <div className="p-4">
        <div className="mt-4">
          <ImportSSRulesForm onSuccess={onSuccess} />
        </div>
      </div>
    </Modal>
  );
};
