import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
// import { PillTabs, PillTab } from "components/PillTabs";
import { ImportSSLRulesForm } from "./ImportSSLRulesForm";

interface AddSSLRulesModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const AddSSLRulesModal: FC<AddSSLRulesModalProps> = (props) => {
  const { isOpen, onClose, onSuccess } = props;
  // const [activeTab, setActiveTab] = useState("single");

  return (
    <Modal
      className="_AddSSLRulesModal"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalHeader
        icon="plus"
        title="Add Same State Licensure Rules"
        onClose={onClose}
      />
      <div className="p-4">
        {/* <div className="flex items-center justify-center">
          <PillTabs activeTab={activeTab} onChange={setActiveTab}>
            <PillTab tab="single">Single Rule</PillTab>
            <PillTab tab="bulk">Bulk Import</PillTab>
          </PillTabs>
        </div> */}

        <div className="mt-4">
          {/* {activeTab === "single" ? (
            <div>Single Rule Form Content</div>
          ) : ( */}
          <ImportSSLRulesForm onSuccess={onSuccess} />
          {/* )} */}
        </div>
      </div>
    </Modal>
  );
};
