import {
  Children,
  useCallback,
  createContext,
  FocusEvent,
  forwardRef,
  useState,
  useMemo,
  useRef,
  useContext,
  useEffect,
  ReactElement,
} from "react";
import styled from "styled-components";

const RadioGroupElement = styled.div<{ inline?: boolean }>`
  padding: 0;
  margin: 0 -0.5rem;
  list-style: none;
  display: flex;
  flex-direction: ${(props) => (props.inline ? "row" : "column")};
  align-items: ${(props) => (props.inline ? "center" : "flex-start")};

  &:focus {
    outline: none;
  }
`;

const RadioElement = styled.div`
  border: 2px solid transparent;
  border-radius: 5px;
  // display: inline-block;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.125em;
  // padding-left: 1.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  cursor: default;
  outline: none;

  margin-left: 0.5rem;
  margin-right: 0.5rem;

  // &::before,
  // &::after {
  //   position: absolute;
  //   top: 50%;
  //   left: 7px;
  //   transform: translate(-20%, -50%);
  //   content: "";
  // }

  // &::before {
  //   width: 14px;
  //   height: 14px;
  //   border: 1px solid hsl(0, 0%, 66%);
  //   border-radius: 100%;
  //   background-image: linear-gradient(to bottom, hsl(300, 3%, 93%), #fff, 60%);
  // }

  // &:active::before {
  //   background-image: linear-gradient(
  //     to bottom,
  //     hsl(300, 3%, 73%),
  //     hsl(300, 3%, 93%)
  //   );
  // }

  // &[aria-checked="true"]::before {
  //   border-color: hsl(216, 80%, 50%);
  //   background: hsl(217, 95%, 68%);
  //   background-image: linear-gradient(
  //     to bottom,
  //     hsl(217, 95%, 68%),
  //     hsl(216, 80%, 57%)
  //   );
  // }

  // &[aria-checked="true"]::after {
  //   display: block;
  //   border: 0.1875em solid #fff;
  //   border-radius: 100%;
  //   transform: translate(25%, -50%);
  // }

  // &[aria-checked="mixed"]:active::before,
  // &[aria-checked="true"]:active::before {
  //   background-image: linear-gradient(
  //     to bottom,
  //     hsl(216, 80%, 57%),
  //     hsl(217, 95%, 68%) 60%
  //   );
  // }

  // &[aria-checked="true"] > p {
  //   color: red;
  // }

  &:hover::before {
    border-color: hsl(216, 94%, 65%);
  }

  &[data-preferral-radio-focus="true"] {
    border-color: hsl(216, 94%, 73%);
    background-color: hsl(216, 80%, 97%);
  }

  &:hover {
    background-color: hsl(216, 80%, 92%);
    cursor: pointer;
  }
`;

const Circle = styled.span`
  position: relative;
  display: block;
  float: left;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid #c8ccd4;
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;

  &:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #225cff;
    transform: scale(0);
    transition: all 0.2 ease;
    opacity: 0.08;
    pointer-events: none;
  }

  [aria-checked="false"]:hover &:after {
    transform: scale(3.6);
  }

  [aria-checked="true"] > & {
    border-color: #225cff;
  }
  [aria-checked="true"] > &:after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }
`;

const codes = {
  RETURN: 13,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export interface RadioGroupCtx<V, Siblings = V[]> {
  value: V;
  otherRadioValues: Siblings;
  setChecked(value: any): void;
}

const RadioGroupContext = createContext<RadioGroupCtx<any>>({} as any);

export interface RadioProps<V> {
  value: V;
  children: any;
  onFocus?(e: FocusEvent<any>): void;
  onBlur?(e: any): void;
  className?: string;
}

export interface RadioGroupProps<V> {
  labelledBy?: string;
  children: ReactElement<RadioProps<V>>[];
  value: V;
  onChange(value: V): void;
  className?: string;
  inline?: boolean;
}

export function RadioGroup<V>(props: RadioGroupProps<V>) {
  const { labelledBy, children, value, className, inline, onChange } = props;

  const setChecked = useCallback(
    (v) => {
      if (onChange) onChange(v);
    },
    [onChange]
  );

  const otherRadioValues = Children.map<any, any>(
    children,
    (child) => child.props.value
  );

  const ctx = useMemo(
    () => ({
      value,
      otherRadioValues,
      setChecked,
    }),
    [setChecked, otherRadioValues, value]
  );

  return (
    <RadioGroupContext.Provider value={ctx}>
      <RadioGroupElement
        role="radiogroup"
        aria-labelledby={labelledBy}
        data-preferral-radio-group
        className={className}
        inline={inline}
      >
        {children}
      </RadioGroupElement>
    </RadioGroupContext.Provider>
  );
}

export const Radio = forwardRef<HTMLDivElement | null, RadioProps<any>>(
  ({ children, className, ...props }, maybeOuterRef: any) => {
    const [focus, setFocus] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    const ctx = useContext(RadioGroupContext);
    const { otherRadioValues, value, setChecked } = ctx;
    const index = otherRadioValues.findIndex((i) => i === props.value);
    const count = otherRadioValues.length - 1;

    useEffect(() => {
      if (
        value === props.value &&
        document.activeElement?.tagName !== "INPUT"
      ) {
        if (maybeOuterRef && maybeOuterRef.current !== null) {
          maybeOuterRef.current.focus();
        } else if (ref.current !== null) {
          ref.current.focus();
        }
      }
    }, [value, props.value, maybeOuterRef]);

    const handleKeyDown = useCallback(
      (event) => {
        event.persist();
        let flag = false;
        function setPrevious() {
          if (index === 0) {
            setChecked(otherRadioValues[count]);
          } else {
            setChecked(otherRadioValues[index - 1]);
          }
        }

        function setNext() {
          if (index === count) {
            setChecked(otherRadioValues[0]);
          } else {
            setChecked(otherRadioValues[index + 1]);
          }
        }

        switch (event.keyCode) {
          case codes.SPACE:
          case codes.RETURN:
            setChecked(props.value);
            flag = true;
            break;
          case codes.UP:
          case codes.LEFT:
            setPrevious();
            flag = true;
            break;
          case codes.DOWN:
          case codes.RIGHT:
            setNext();
            flag = true;
            break;
          default:
            break;
        }

        if (flag) {
          event.stopPropagation();
          event.preventDefault();
        }
      },
      [setChecked, otherRadioValues, props.value, count, index]
    );

    const handleClick = useCallback(() => {
      setChecked(props.value);
    }, [setChecked, props.value]);

    const { onFocus, onBlur } = props;
    const handleBlur = useCallback(
      (e) => {
        if (onBlur) {
          onBlur(e);
        }
        setFocus(false);
      },
      [onBlur]
    );

    const handleFocus = useCallback(
      (e) => {
        if (onFocus) {
          onFocus(e);
        }
        setFocus(true);
      },
      [onFocus, setFocus]
    );

    return (
      <RadioElement
        {...props}
        role="radio"
        tabIndex={value === props.value || (!value && index === 0) ? 0 : -1}
        aria-checked={value === props.value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        data-preferral-radio
        data-preferral-radio-focus={focus}
        ref={(el) => {
          if (maybeOuterRef) {
            maybeOuterRef.current = el;
          }
          ref.current = el;
        }}
        className={className}
      >
        <Circle />
        {children}
      </RadioElement>
    );
  }
);
