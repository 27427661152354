import React, { FC } from "react";

interface ForgotPasswordScreenProps {}

export const ForgotPasswordScreen: FC<ForgotPasswordScreenProps> = (props) => {
  return (
    <div className="ForgotPasswordScreen">
      <p>ForgotPasswordScreen</p>
    </div>
  );
};
