import { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { NoMatchScreen } from "screens/NoMatchScreen";
import { SignInScreen } from "screens/SignInScreen";
import { ForgotPasswordScreen } from "screens/ForgotPasswordScreen";

interface UnauthenticatedAppProps { }

export const UnauthenticatedApp: FC<UnauthenticatedAppProps> = () => {
  const match = useRouteMatch();

  return (
    <div className="_UnauthenticatedApp w-screen h-screen">
      <Switch>
        <Route exact path={`${match.path}/sign_in`}>
          <SignInScreen />
        </Route>
        <Route exact path={`${match.path}/forgot_password`}>
          <ForgotPasswordScreen />
        </Route>

        <Route path="*">
          <NoMatchScreen />
        </Route>
      </Switch>
    </div>
  );
};
