import { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Button } from "components/Button";
import { ScreenTitle } from "context/ScreenTitle";
import { Table, TableContainer, TD, TH } from "components/Table";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import { hMmA, mmDdYy } from "utils/dateFormatters";
import { AddSSLRulesModal } from "./AddSSLRulesModal/AddSSLRulesModal";

const LIST_SSL_RULES = gql`
  query ListSameStateLicensureRules($first: Int, $after: UUID4, $filter: ListSameStateLicensureRulesFilter) {
    sameStateLicensureRules(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        stateAbbreviation
        state {
          id
          abbreviation
        }
        healthPlan {
          id
          name
        }
        lineOfBusiness
        typeOfInsurance
        result
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  sameStateLicensureRules: Paginated<SameStateLicensureRule>;
}

interface SameStateLicensureRule {
  id: string;
  stateAbbreviation: string | null;
  state: {
    id: string;
    abbreviation: string;
  } | null;
  healthPlan: {
    id: string;
    name: string;
  } | null;
  lineOfBusiness: string | null;
  typeOfInsurance: string | null;
  result: boolean;
  insertedAt: string;
  updatedAt: string;
}

type ActiveModal = { type: "ADD_RULES" };

interface SameStateLicensureRulesScreenProps { }

export const SameStateLicensureRulesScreen: FC<SameStateLicensureRulesScreenProps> = (props) => {
  const { data, loading, error, refetch } = useQuery<Data>(LIST_SSL_RULES);

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  return (
    <>
      <ScreenTitle title="Same State Licensure Rules" />
      <AddSSLRulesModal
        isOpen={activeModal?.type === "ADD_RULES"}
        onClose={() => setActiveModal(null)}
        onSuccess={() => {
          refetch();
          setActiveModal(null);
        }}
      />
      <div className="_SameStateLicensureRulesScreen sm:rounded-md ">
        <div className="sm:p-6 px-4 py-6 bg-white">

          <div className="flex items-center justify-between pb-4">
            <div>
              <h2
                id="health_plans_heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Same State Licensure Rules
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Manage system SSL rules
              </p>
            </div>
            <div className="flex pt-6 pl-6">
              <Button
                type="button"
                color="gold"
                kind="primary"
                onClick={() => setActiveModal({ type: "ADD_RULES" })}
              >
                Add Rules
              </Button>
            </div>
          </div>

          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.sameStateLicensureRules ? (
            <p>Failed to load.</p>
          ) : data.sameStateLicensureRules.items.length === 0 ? (
            <NoResults icon="list-alt" text="No matching SSL rules" />
          ) : (
            <TableContainer>
              <Table className="text-left">
                <thead>
                  <tr>
                    <TH>State</TH>
                    <TH>Health Plan</TH>
                    <TH>Line of Business</TH>
                    <TH>Type of Insurance</TH>
                    <TH>SSL Required</TH>
                    <TH>Created At</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.sameStateLicensureRules.items.map((r) => (
                    <tr key={r.id}>
                      <TD className="px-3 py-1 text-lg">{r.stateAbbreviation || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.healthPlan?.name || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.lineOfBusiness || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.typeOfInsurance || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.result ? <Yes /> : <No />}</TD>
                      <TD className="px-3 py-1">{mmDdYy(r.insertedAt)} {hMmA(r.insertedAt)}</TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};

function Wildcard() {
  return (
    <span className="font-mono text-xl italic text-purple-400">*</span>
  )
}

function Yes() {
  return (
    <span className="font-mono text-xl font-bold text-green-800">Yes</span>
  )
}

function No() {
  return (
    <span className="font-mono text-xl font-bold text-red-800">No</span>
  )
}
