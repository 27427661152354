import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "components/Spinner";
import { Button } from "components/Button";
import { ActivityEligibility } from "components/ActivityEligibility";
import { FAIcon } from "components/FAIcon";
import { Badge } from "components/Badge";

const P2P_APPOINTMENTS = gql`
  query ListP2PAppointments($caseNumber: String!) {
    p2pHost
    p2pAppointments(caseNumber: $caseNumber) {
      id
      appointmentRequestId
      requiresReschedule
      startTime
      startTimeString
      startDateString
      finishTime
      duration
      completed
      cancelled
      missed
      levelOfReview
      episodeId
      replacedByAppointmentId
      provider {
        id
        email
        iexId
        domainId
        firstName
        middleInitial
        lastName
        primarySpecialty
        evicorePhoneId
      }
    }
  }
`;

interface Data {
  p2pHost: string;
  p2pAppointments: {
    id: string;
    appointmentRequestId: string;
    requiresReschedule: boolean;
    startTime: string;
    startTimeString: string;
    startDateString: string;
    finishTime: string;
    duration: number;
    completed: boolean;
    cancelled: boolean;
    missed: boolean;
    levelOfReview: string;
    episodeId: string;
    replacedByAppointmentId: string | null;
    provider: {
      id: string;
      email: string;
      iexId: string;
      domainId: string;
      firstName: string;
      middleInitial: string | null;
      lastName: string
      primarySpecialty: string | null;
      evicorePhoneId: string | null;
    }
  }[];
}

interface Variables {
  caseNumber: string
}

interface P2PAppointmentListProps {
  caseNumber: string;
};

export const P2PAppointmentList: FC<P2PAppointmentListProps> = props => {
  const { caseNumber } = props;
  const { data, loading, error, refetch } = useQuery<Data, Variables>(P2P_APPOINTMENTS, { variables: { caseNumber } });

  return (
    <div className="_P2PAppointmentList">
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !(data?.p2pAppointments) ? (
        <div className="p-2 text-center">
          <p className="py-2 text-sm text-gray-700">Failed to load P2P Appointments</p>
          <Button type="button" size="xs" onClick={() => refetch()}>
            Retry
          </Button>
        </div>
      ) : data.p2pAppointments.length === 0 ? (
        <div className="flex justify-center py-5">

          <ActivityEligibility
            className="mt-1"
            allowed
            label="No scheduled P2Ps"
          />
        </div>
      ) : (
        <div>
          {data.p2pAppointments.map(appointment => (
            <div key={appointment.id} className="flex items-center px-4 py-3 m-2 border rounded-lg shadow-md">
              <div className="_P2PAppointmentList__appointment flex items-center justify-between flex-1">
                <div className="_info text-sm">
                  <div className="flex items-center">
                    <FAIcon icon={"highlighter"} className="mr-2" />
                    <p>{lorLabel(appointment.levelOfReview)}</p>
                  </div>

                  <div className="flex items-center pt-1">
                    <FAIcon icon={["far", "clock"]} className="mr-2" />
                    <p>{appointment.startDateString} {appointment.startTimeString}</p>
                  </div>

                  <div className="flex items-center pt-1">
                    <FAIcon icon="user-md" className="mr-2" />
                    <p>{appointment.provider.firstName} {appointment.provider.lastName}</p>
                  </div>
                </div>

                <div className="_status pt-1 text-right">
                  {
                    appointment.completed ? (
                      <Badge color="green">Completed</Badge>
                    ) : appointment.cancelled ? (
                      <Badge color="red">Cancelled</Badge>
                    ) : appointment.missed ? (
                      <Badge color="orange">Missed</Badge>
                    ) : (
                      <Badge color="blue">Scheduled</Badge>
                    )
                  }
                  {
                    appointment.requiresReschedule ? (
                      <div className="pt-1">
                        <Badge color="yellow">Requires Reschedule</Badge>
                      </div>
                    ) : null
                  }
                </div>
              </div>
              <div className="flex-grow-0 flex-shrink-0 p-1 text-2xl">
                <a
                  href={`${data.p2pHost}/o/requests/${appointment.appointmentRequestId}`}
                  className="hover:text-blue-700 focus:outline-none focus:text-blue-700 p-1 text-sm font-medium leading-5 text-blue-500 transition duration-150 ease-in-out border-b-2 border-transparent"
                  target="_blank"
                  rel="noref noreferrer nofollow"
                >
                  <FAIcon icon="external-link-alt" className="ml-2" />
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const LEVELS_OF_REVIEW = {
  ce_spine_corepath: "CE Spine CorePath",
  open_case_p2p: "Open Case P2P / PDC",
  informal_p2p: "Informal P2P",
  ime_p2p_recommendation_only: "IME P2P Recommendation Only",
  reconsideration: "Reconsideration P2P",
  peer_to_peer: "Peer-to-Peer",
  "1st_level_appeal_p2p": "1st Level Appeal P2P",
  "2nd_level_appeal_p2p": "2nd Level Appeal P2P",
  p2p_new_case_required_if_approved: "P2P - New Case Required if Approved",
  p2p_consult_only: "P2P Consult Only",
  restart: "Restart",
  appeal_p2p_pilot: "Appeal P2P Pilot",
  appeal_p2p_pilot_husky_level1: "Appeal P2P Pilot - Husky Level 1",
  appeal_p2p_pilot_husky_level2: "Appeal P2P Pilot - Husky Level 2",
}

function lorLabel(value: string): string {
  return LEVELS_OF_REVIEW[value] || value;
}
