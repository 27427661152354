import { useRef, useCallback, useEffect } from "react";
import { Handler } from "utils/reactHelpers";

/** Returns a setTimeout-like function that clears the previous timeout when re-invoked or when the component unmounts. */
export function useTimeout() {
  const handleRef = useRef<NodeJS.Timeout>();

  useEffect(() => () => clearTimeout(handleRef.current), []);

  return useCallback((handler: Handler, timeout?: number) => {
    clearTimeout(handleRef.current);
    handleRef.current = setTimeout(handler, timeout);
  }, []);
}
