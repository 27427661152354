import {
  FC,
  createContext,
  useMemo,
  Children,
  isValidElement,
  cloneElement,
  useCallback,
  useContext,
} from "react";

interface StageTabCtx<T extends string = string> {
  activeTab: T;
  setTab(tab: T): void;
}

const StageTabContext = createContext<StageTabCtx | null>(null);
/**
 * - Draft (drafts auto-closed after appeal period)
 * - Intake
 * - Validation
 * - Nurse Review
 * - MD Review
 * - Wrap-up
 * - Closed
 */

/**
 * Chevron.
 */

const Chevron: FC<{ active?: boolean }> = ({ active }) => {
  const bgClassName = active ? "bg-gray-100" : "";
  return (
    <div
      className={`hidden md:block absolute top-0 right-0 h-full w-5 ${bgClassName}`}
      aria-hidden="true"
    >
      <svg
        className="w-full h-full text-gray-300"
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
      >
        {active ? (
          <path
            d="M0 -2L20 40L0 82"
            className="text-blue-200"
            fill="currentcolor"
          />
        ) : null}
        <path
          d="M0 -2L20 40L0 82"
          vectorEffect="non-scaling-stroke"
          stroke="currentcolor"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const BackChevron: FC<{ active?: boolean }> = ({ active }) => {
  const bgClassName = active ? "bg-blue-200" : "";
  const fgClassName = "text-gray-100";
  return (
    <div
      className={`hidden md:block absolute top-0 left-0 transform -translate-x-full h-full w-5 ${bgClassName}`}
      aria-hidden="true"
    >
      <svg
        className="w-full h-full text-gray-300"
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
      >
        {active ? (
          <path
            d="M0 -2L20 40L0 82"
            className="text-blue-200"
            fill="currentcolor"
          />
        ) : null}
        <path
          d="M0 -2L20 40L0 82"
          vectorEffect="non-scaling-stroke"
          className={fgClassName}
          fill="currentcolor"
          stroke="currentcolor"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

/**
 * StageTabs.
 */

interface StageTabsProps {
  activeTab: string;
  onChange(tab: string): void;
}

export const StageTabs: FC<StageTabsProps> = (props) => {
  const { activeTab, onChange, children } = props;

  const handleTabClick = useCallback(
    (tab: string) => {
      onChange(tab);
    },
    [onChange]
  );

  const ctx = useMemo(
    () => ({
      activeTab,
      setTab: handleTabClick,
    }),
    [activeTab, handleTabClick]
  );

  const tabCount = Children.count(children);
  const tabChildren = Children.map<any, any>(children, (child, idx) => {
    if (isValidElement(child)) {
      const isFirst = idx === 0;
      const isLast = idx + 1 === tabCount;
      return cloneElement<any>(child, {
        isActive: (child.props as any).activeTab,
        showChevron: !isLast && !(child.props || ({} as any)).isLeft,
        showBackChevron: !isFirst,
        isFirst,
        isLast,
      });
    }
    return child;
  });

  return (
    <StageTabContext.Provider value={ctx}>
      <nav aria-label="Progress">
        <ol className="md:flex md:divide-y-0 border border-gray-300 divide-y divide-gray-300 rounded-md">
          {tabChildren}
        </ol>
      </nav>
    </StageTabContext.Provider>
  );
};

/**
 * StageTab.
 */
interface StageTabProps {
  tab: string;
  isActive?: boolean;
  showChevron?: boolean;
  showBackChevron?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

export const StageTab: FC<StageTabProps> = (props) => {
  const {
    tab,
    showChevron = false,
    showBackChevron = false,
    isFirst = false,
    isLast = false,
    children,
  } = props;
  const { activeTab, setTab } = useContext(StageTabContext)!;
  const positionClassName = isFirst
    ? "rounded-tl-md rounded-bl-md"
    : isLast
      ? "rounded-tr-md rounded-br-md"
      : "";
  const isActive = tab === activeTab;
  const bgClassName = isActive ? "bg-blue-200" : "bg-gray-100";

  return (
    <li
      className={`relative ${positionClassName} md:flex-1 md:flex cursor-pointer ${bgClassName}`}
      onClick={() => setTab(tab)}
    >
      {isActive && showBackChevron && <BackChevron active={isActive} />}
      {/* <!-- Completed Step --> */}
      {isActive ? (
        <div className="flex items-center px-6 py-4 text-sm font-medium">
          <span className={`${isFirst ? "ml-4" : ""} text-sm font-medium text-indigo-600`}>
            {children}
          </span>
        </div>
      ) : (
        <div className="group flex items-center">
          <span className="flex items-center px-6 py-4 text-sm font-medium">
            <span className={`${isFirst ? "ml-4" : ""} group-hover:text-gray-900 text-sm font-medium text-gray-500`}>
              {children}
            </span>
          </span>
        </div>
      )}

      {showChevron && <Chevron active={isActive} />}
    </li>
  );
};
