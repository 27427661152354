import React, { FC, ReactNode } from "react";
import ReactDOM from "react-dom";
import { useDropdown } from "hooks/useDropdown";
import { Link } from "react-router-dom";
import { Manager, Reference, Popper } from "react-popper";
import { FAIcon } from "../FAIcon";
import { Icon } from "../Icon";
import { useComponentId } from "hooks/useComponentId";
import { ZoomIn } from "components/Animations";
import { Spinner } from "components/Spinner";

export type DropdownItemColor = "gray" | "blue" | "red";

const colorClassNames: Record<DropdownItemColor, string> = {
  gray: "text-gray-700 hover:bg-gray-100 hover:text-gray-900",
  blue: "text-gray-700 hover:bg-blue-100 hover:text-blue-900",
  red: "text-red-700 hover:bg-red-100 hover:text-red-900",
};

/**
 * DropdownButton.
 */

interface DropdownButtonProps {
  id?: string;
  label?: ReactNode;
}

export const DropdownButton: FC<DropdownButtonProps> = (props) => {
  const { id: idProp, label, children } = props;
  const componentId = useComponentId();
  const id = idProp ? idProp : `DropdownButton__${componentId}`;
  const { triggerRef, contentRef, isOpen, toggle } = useDropdown();

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div ref={ref} className="inline-block text-left">
            <button
              id={id}
              ref={triggerRef}
              onClick={toggle}
              type="button"
              className="hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 inline-flex justify-center w-full px-2 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm"
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : "false"}
            >
              {!label ? (
                <Icon icon="dots-horizontal" />
              ) : (
                <p className="flex items-center px-1 text-sm">
                  {label}
                  <span className="mt-1 ml-2 text-xs text-gray-700">
                    <FAIcon icon="chevron-down" />
                  </span>
                </p>
              )}
            </button>
          </div>
        )}
      </Reference>
      {isOpen &&
        ReactDOM.createPortal(
          <Popper placement="bottom-end">
            {({ placement, ref, style }) => (
              <div
                ref={ref}
                style={style}
                data-placement={placement}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby={id}
              >
                <ZoomIn
                  show
                  className="ring-1 ring-black ring-opacity-5 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg"
                  ref={contentRef}
                >
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    {children}
                  </div>
                </ZoomIn>
              </div>
            )}
          </Popper>,
          document.querySelector("#dropdown-button-action-portal")!
        )}
    </Manager>
  );
};

/**
 * DropdownItemButton.
 */

interface DropdownItemButtonProps {
  onClick?(): void;
  className?: string;
  color?: DropdownItemColor;
  isLoading?: boolean;
}

export const DropdownItemButton: FC<DropdownItemButtonProps> = (props) => {
  const { className, color = "gray", onClick, children, isLoading = false } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`block w-full bg-white text-left cursor-pointer px-4 py-2 text-sm ${className || ""
        } ${colorClassNames[color]}`}
    >
      {children}
      {isLoading ? (
        <span className="ml-2"><Spinner /></span>
      ) : null}
    </button>
  );
};

/**
 * DropdownItemLink.
 */

interface DropdownItemLinkProps {
  to: string;
  className?: string;
  color?: DropdownItemColor;
}

export const DropdownItemLink: FC<DropdownItemLinkProps> = (props) => {
  const { className, to, color = "gray", children } = props;

  return (
    <Link
      to={to}
      className={`block w-full bg-white text-sm font-semibold text-left cursor-pointer rounded mt-1 px-2 py-1 ${className || ""
        } ${colorClassNames[color]}`}
    >
      {children}
    </Link>
  );
};
