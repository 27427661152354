import { FC } from "react";
import { gql, useQuery } from '@apollo/client';
import { Spinner } from "components/Spinner";
import { Button } from "components/Button";
import { PdfViewer } from "components/PdfViewer";

const APPEAL_REQUEST_DOCUMENTS = gql`
  query AppealRequest($id: UUID4!) {
    appealRequest(id: $id) {
      id
      caseProfile {
        id
        documents {
          id
          status
          kind
          filename
          versions {
            id
            url
            status
            filetype
            errorMessage
          }
        }
      }
    }
  }
`;

interface Data {
  appealRequest: AppealRequestModel;
}

interface AppealRequestModel {
  id: string;
  caseProfile: CaseProfileModel;
}

interface CaseProfileModel {
  id: string;
  documents: DocumentModel[];
}

interface DocumentModel {
  id: string;
  status: string;
  kind: string;
  filename: string;
  versions: DocumentVersionModel[];
}

interface DocumentVersionModel {
  id: string;
  url: string;
  status: string;
  filetype: string;
  errorMessage: string;
}

interface AppealRequestDocumentViewerProps {
  appealRequestId: string;
};

export const AppealRequestDocumentViewer: FC<AppealRequestDocumentViewerProps> = props => {
  const { appealRequestId } = props;
  const { data, loading, error, refetch } = useQuery<Data>(APPEAL_REQUEST_DOCUMENTS, { variables: { id: appealRequestId } })

  const isBlank = getFaxDocument(data) === null;
  const isProcessing = getFaxPdfIsProcessing(data);
  const faxPdfUrl = getFaxPdfUrl(data);
  // const faxPdfUrl = "https://storage.googleapis.com/aaas-dev-private/documents/8ff1c91d-4a27-411d-85ec-948c0eb6275e/A188448201_20230413_070956.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=aaas-dev-sa%40ovicare-dev.iam.gserviceaccount.com%2F20230726%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20230726T000207Z&X-Goog-Expires=1800&X-Goog-SignedHeaders=host&response-content-disposition=inline&X-Goog-Signature=85d75db509f23a10b687d4a1c4ad18866c428551efae679e392fbb30321433ac852daef617ca1ec3d0c2159a96b7904efae4362fd4e3e6a247ac46643928adf8e20d6af3ef521cbd76bb07210f7849d97292ed1bfe4af4b76d3c324055efb35567008b668dc95dddb92ac2e7050b0f11eaa4a69fe30d9612f2d71aeecfce44870939bf905ea9911d7dd9137fcb6bcaf16851283de290bc7ff7d93b2774060e8c456a537f5aa28e99665d28178781d59ed88ca40a5099aec3dae8c303d82e29fda70be76348b76f11375cdc71f7e758ed5d3ff37abb7340e5e6e0bdad1ac6e836d8d6cc93d69209b828f4c7a8fd8dae53c1a754d0020a51f8eac10ebaf4163b81";

  return (
    <div className="_AppealRequestDocumentViewer flex-1 min-h-0 overflow-y-auto">
      {/* Thread section*/}
      <div className="pt-4">
        {
          loading ? (
            <div className="p-6 text-center">
              <Spinner />
            </div>
          ) : error || !data?.appealRequest ? (
            <div>
              <p>Failed to load</p>
              <Button type="button" onClick={refetch}>
                Retry
              </Button>
            </div>
          ) : isBlank ? (
            unavailable("blank")
          ) : isProcessing ? (
            unavailable("processing")
          ) : !faxPdfUrl ? (
            unavailable("other")
          ) : (
            <PdfViewer
              url={faxPdfUrl}
              className="w-full"
              style={{ height: "calc(100vh - 188px)" }}
            />
            // <iframe
            //   title="Fax PDF"
            //   src={faxPdfUrl}
            //   className="w-full"
            //   style={{ height: "calc(100vh - 188px)" }}
            // />
          )
        }
      </div>
    </div>
  );
};

function unavailable(reasonArg: string): JSX.Element {
  let reason;

  switch (reasonArg) {
    case "blank":
      reason = "No document uploaded";
      break;
    case "uploading":
    case "processing":
      reason = "Processing document";
      break;
    case "failed":
      reason = "Failed to process document";
      break;
    default:
      reason = "Document currently unavailable";
  }

  return (
    <div className="flex flex-col items-center justify-center flex-1 p-6 text-lg">
      <p className="text-gray-700">{reason}</p>
    </div>
  );
}

function getFaxPdfIsProcessing(data?: Data): boolean {
  if (!data) return false;

  const faxDocument = getFaxDocument(data);
  if (!faxDocument) return false;

  const pdfVersion = faxDocument.versions.find(v => v.filetype === "pdf");
  if (!pdfVersion) return false;

  return ["uploading", "processing"].includes(pdfVersion.status);
}

function getFaxPdfUrl(data?: Data): string | null {
  if (!data) return null;

  const faxDocument = getFaxDocument(data);
  if (!faxDocument) return null;

  const pdfVersion = faxDocument.versions.find(v => v.filetype === "pdf");
  if (!pdfVersion) return null;

  return pdfVersion.url;
}

function getFaxDocument(data?: Data): DocumentModel | null {
  if (!data) return null;

  return data.appealRequest.caseProfile.documents.find(d => d.kind === "fax") || null;
}
