import { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Button } from "components/Button";
import { ScreenTitle } from "context/ScreenTitle";
import { Table, TableContainer, TD, TH } from "components/Table";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import { hMmA, mmDdYy } from "utils/dateFormatters";
import { AddSSRulesModal } from "./AddSSRulesModal";

const LIST_SS_RULES = gql`
  query ListSimilarSpecialtyRules($first: Int, $after: UUID4, $filter: ListSimilarSpecialtyRulesFilter) {
    similarSpecialtyRules(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        stateAbbreviation
        state {
          id
          abbreviation
        }
        healthPlan {
          id
          name
        }
        lineOfBusiness
        typeOfInsurance
        specificity
        result
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  similarSpecialtyRules: Paginated<SimilarSpecialtyRule>;
}

interface SimilarSpecialtyRule {
  id: string;
  stateAbbreviation: string | null;
  state: {
    id: string;
    abbreviation: string;
  } | null;
  healthPlan: {
    id: string;
    name: string;
  } | null;
  lineOfBusiness: string | null;
  typeOfInsurance: string | null;
  specificity: number;
  result: boolean;
  insertedAt: string;
  updatedAt: string;
}

type ActiveModal = { type: "ADD_RULES" };

interface SimilarSpecialtyRulesScreenProps { }

export const SimilarSpecialtyRulesScreen: FC<SimilarSpecialtyRulesScreenProps> = (props) => {
  const { data, loading, error, refetch } = useQuery<Data>(LIST_SS_RULES);

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  return (
    <>
      <ScreenTitle title="Similar Specialty Rules" />
      <AddSSRulesModal
        isOpen={activeModal?.type === "ADD_RULES"}
        onClose={() => setActiveModal(null)}
        onSuccess={() => {
          refetch();
          setActiveModal(null);
        }}
      />
      <div className="_SimilarSpecialtyRulesScreen sm:rounded-md ">
        <div className="sm:p-6 px-4 py-6 bg-white">
          <div className="flex items-center justify-between pb-4">
            <div>
              <h2
                id="similar_specialty_rules_heading"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Similar Specialty Rules
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Manage system Similar Specialty rules
              </p>
            </div>
            <div className="flex pt-6 pl-6">
              <Button
                type="button"
                color="gold"
                kind="primary"
                onClick={() => setActiveModal({ type: "ADD_RULES" })}
              >
                Add Rules
              </Button>
            </div>
          </div>

          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.similarSpecialtyRules ? (
            <p>Failed to load.</p>
          ) : data.similarSpecialtyRules.items.length === 0 ? (
            <NoResults icon="list-alt" text="No matching Similar Specialty rules" />
          ) : (
            <TableContainer>
              <Table className="text-left">
                <thead>
                  <tr>
                    <TH>State</TH>
                    <TH>Health Plan</TH>
                    <TH>Line of Business</TH>
                    <TH>Type of Insurance</TH>
                    <TH>Specificity</TH>
                    <TH>Similar Specialty Required</TH>
                    <TH>Created At</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.similarSpecialtyRules.items.map((r) => (
                    <tr key={r.id}>
                      <TD className="px-3 py-1 text-lg">{r.stateAbbreviation || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.healthPlan?.name || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.lineOfBusiness || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.typeOfInsurance || <Wildcard />}</TD>
                      <TD className="px-3 py-1">{r.specificity}</TD>
                      <TD className="px-3 py-1">{r.result ? <Yes /> : <No />}</TD>
                      <TD className="px-3 py-1">{mmDdYy(r.insertedAt)} {hMmA(r.insertedAt)}</TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};

function Wildcard() {
  return (
    <span className="font-mono text-xl italic text-purple-400">*</span>
  )
}

function Yes() {
  return (
    <span className="font-mono text-xl font-bold text-green-800">Yes</span>
  )
}

function No() {
  return (
    <span className="font-mono text-xl font-bold text-red-800">No</span>
  )
}
