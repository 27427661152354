import { InputSelect, StandardOption } from "components/formik/SelectField";
import React, {
  FC,
  createContext,
  useMemo,
  useCallback,
  useContext,
  Children,
  ReactElement,
  isValidElement,
} from "react";

/**
 * PillTabContext.
 */
interface PillTabCtx<T extends string = string> {
  activeTab: T;
  setTab(tab: T): void;
}

const PillTabContext = createContext<PillTabCtx | null>(null);

/**
 * PillTab.
 */

interface PillTabProps {
  tab: string;
  children: string;
}

export const PillTab: FC<PillTabProps> = (props) => {
  const { tab, children } = props;
  const { activeTab, setTab } = useContext(PillTabContext)!;
  const isActive = activeTab === tab;

  const activeClassName = isActive
    ? "bg-indigo-100 text-indigo-700"
    : "text-gray-500 hover:text-gray-700";
  return (
    <button
      type="button"
      role="tab"
      aria-selected={isActive ? "true" : "false"}
      id={tab}
      onClick={() => setTab(tab)}
      className={`${activeClassName} px-3 py-2 font-medium text-sm rounded-md`}
    >
      {children}
    </button>
  );
};

/**
 * PillTabs.
 */
interface PillTabsProps {
  activeTab: string;
  onChange(tab: string): void;
  children: ReactElement<PillTabProps>[];
}

export const PillTabs: FC<PillTabsProps> = (props) => {
  const { activeTab, onChange, children } = props;

  const handleTabClick = useCallback(
    (tab: string) => {
      onChange(tab);
    },
    [onChange]
  );

  const ctx = useMemo(
    () => ({
      activeTab,
      setTab: handleTabClick,
    }),
    [activeTab, handleTabClick]
  );

  const tabOptions: StandardOption[] = Children.map<any, any>(
    children,
    (child: ReactElement<PillTabProps>) => {
      if (isValidElement(child)) {
        return {
          value: child.props.tab,
          label: child.props.children,
        };
      }
      return null;
    }
  ).filter(Boolean);

  return (
    <PillTabContext.Provider value={ctx}>
      <div>
        <div className="sm:hidden">
          <label htmlFor="pillTabs" className="sr-only">
            Select a tab
          </label>
          <InputSelect
            id="pillTabs"
            name="pillTabs"
            value={activeTab}
            onChange={handleTabClick}
            options={tabOptions}
          />
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {children}
          </nav>
        </div>
      </div>
    </PillTabContext.Provider>
  );
};
