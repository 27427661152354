import { FC } from "react";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  UsersIcon,
  CircleStackIcon as CollectionIcon,
  ViewColumnsIcon as ViewGridAddIcon,
  BriefcaseIcon,
  QueueListIcon
} from '@heroicons/react/24/outline'
import { ProfileSettingsScreen } from "./Personal/ProfileSettingsScreen";
import { HealthPlansScreen } from "./System/HealthPlansScreen";
import { Container } from "components/Container";
import { ProvidersScreen } from "./System/ProvidersScreen";
import { ModalitiesScreen } from "./System/ModalitiesScreen";
import { useCurrentAgent } from "hooks/useCurrentAgent";
import { SameStateLicensureRulesScreen } from "./System/SameStateLicensureRulesScreen";
import { SimilarSpecialtyRulesScreen } from "./System/SimilarSpecialtyRulesScreen";
import { StateUMSettingsScreen } from "./System/StateUMSettingsScreen";

interface SettingsScreenProps { }

export const SettingsScreen: FC<SettingsScreenProps> = (props) => {
  const match = useRouteMatch();

  const currentAgent = useCurrentAgent();

  return (
    <Container>
      <main className="max-w-7xl lg:py-12 lg:px-8 pb-10 mx-auto">
        <div className="lg:grid lg:grid-cols-10 lg:gap-x-5">
          <aside className="sm:px-6 lg:py-0 lg:px-0 lg:col-span-2 px-2 py-6">
            {/* <div>
              <h3 className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                Personal
              </h3>
              <nav className="mt-3 space-y-1">
                <Link
                  to={`${match.path}/profile`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <UserCircleIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Profile</span>
                </Link>

                <Link
                  to={`${match.path}/account`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <CogIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Account</span>
                </Link>

                <Link
                  to={`${match.path}/password`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <KeyIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Password</span>
                </Link>

                <Link
                  to={`${match.path}/notifications`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <BellIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Notifications</span>
                </Link>
              </nav>
            </div> */}
            <div className="mt-10">
              <h3 className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                System
              </h3>
              <nav className="mt-3 space-y-1">
                <Link
                  to={`${match.path}/providers`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <UsersIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Providers</span>
                </Link>

                <Link
                  to={`${match.path}/program_skills`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <CollectionIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Modalities</span>
                </Link>

                <Link
                  to={`${match.path}/health_plans`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <BriefcaseIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Health Plans</span>
                </Link>

                <Link
                  to={`${match.path}/ssl_rules`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <QueueListIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Same State Licensure Rules</span>
                </Link>

                <Link
                  to={`${match.path}/ss_rules`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <QueueListIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Similar Specialty Rules</span>
                </Link>

                <Link
                  to={`${match.path}/state_um_settings`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <QueueListIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">State UM Settings</span>
                </Link>

                {/* <Link
                  to={`${match.path}/integrations`}
                  className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                >
                  <ViewGridAddIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                  <span className="truncate">Integrations</span>
                </Link> */}

                {
                  currentAgent?.role === "system_admin" ? (
                    <>
                      <Link
                        to={`${match.path}/jobs`}
                        className="hover:text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium text-gray-900 rounded-md"
                      >
                        <ViewGridAddIcon className="group-hover:text-gray-500 flex-shrink-0 inline-block w-6 h-6 mr-3 -ml-1 text-gray-400" />
                        <span className="truncate">Jobs</span>
                      </Link>
                    </>
                  ) : null
                }
              </nav>
            </div>
          </aside>

          <div className="sm:px-6 lg:px-0 lg:col-span-8 space-y-6">
            <Switch>
              <Route exact path={`${match.path}/profile`}>
                <ProfileSettingsScreen />
              </Route>
              <Route exact path={`${match.path}/program_skills`}>
                <ModalitiesScreen />
              </Route>
              <Route exact path={`${match.path}/health_plans`}>
                <HealthPlansScreen />
              </Route>
              <Route exact path={`${match.path}/ssl_rules`}>
                <SameStateLicensureRulesScreen />
              </Route>
              <Route exact path={`${match.path}/ss_rules`}>
                <SimilarSpecialtyRulesScreen />
              </Route>
              <Route exact path={`${match.path}/state_um_settings`}>
                <StateUMSettingsScreen />
              </Route>
              <Route exact path={`${match.path}/providers`}>
                <ProvidersScreen />
              </Route>
              <Route path="*">
                <Redirect to={`${match.path}/profile`} />
              </Route>
            </Switch>
          </div>
        </div>
      </main>
    </Container>
  );
};
