import { FC } from "react";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Formik } from "formik";
import { Button } from "components/Button";
import { HorizontalTextField } from "components/formik/TextField";
import { HorizontalRadioGroupField } from "components/formik/RadioGroupField";
import { StandardOption } from "components/formik/SelectField";
import { FAIcon } from "components/FAIcon";
import { HorizontalTextAreaField } from "components/formik/TextAreaField";

export type CallOutcome = "successful" | "answering_machine" | "no_answer" | "busy_signal" | "party_not_reached" | "fax_signal" | "outgoing_signal" | "wrong_number" | "other";
export interface FormValues {
  whoCalling: string;
  speakingWith: string;
  attemptOutcome: "" | CallOutcome;
  otherOutcome: string;
}

const whoCallingOptions: StandardOption[] = [
  { value: "provider", label: "Provider" },
  { value: "member", label: "Member" },
  { value: "site", label: "Site" },
];

const attemptOutcomeOptions: StandardOption<CallOutcome>[] = [
  { value: "successful", label: "Yes" },
  { value: "answering_machine", label: "No - Reached Answering Machine" },
  { value: "no_answer", label: "No - Call Went Unanswered" },
  { value: "busy_signal", label: "No - Busy Signal (Line or Circuit)" },
  { value: "party_not_reached", label: "No - Party Not Reached (Spoke with someone other than intended party)" },
  { value: "fax_signal", label: "No - Fax Signal" },
  { value: "outgoing_signal", label: "No - Outgoing Signal (No voicemail capability)" },
  { value: "wrong_number", label: "No - Invalid/Wrong Number" },
  { value: "other", label: "No - Other" }
];

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    whoCalling: Yup.string().required("Required"),
    speakingWith: Yup.string().required("Required"),
    attemptOutcome: Yup.string().required("Required"),
    otherOutcome: Yup.string()
  })
  .required();

interface LogVerbalNotificationFormProps {
  initialValues: FormValues;
  onSubmit(values: FormValues): void;
};

export const LogVerbalNotificationForm: FC<LogVerbalNotificationFormProps> = props => {
  const { initialValues, onSubmit } = props;

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, status, values }) => (

        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <HorizontalRadioGroupField
            label="Who are you calling?"
            name="whoCalling"
            options={whoCallingOptions}
          />

          <div className="mt-3">
            <HorizontalTextField
              label="Who are you speaking with?"
              name="speakingWith"
              placeholder="Name of person you are speaking with"
            />
          </div>

          <div className="mt-3">
            <HorizontalRadioGroupField
              label="Call attempt successful?"
              name="attemptOutcome"
              options={attemptOutcomeOptions}
            />
          </div>

          {values.attemptOutcome === "other" ? (
            <HorizontalTextAreaField
              name="otherOutcome"
              label="Other Outcome"
              placeholder="Please describe the outcome of the call"
            />
          ) : null}

          <div className="py-4 mt-6 text-center">
            <Button
              type="submit"
              color="gold"
              size="lg"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Next <FAIcon icon="arrow-right" className="ml-2" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
