import { FC } from "react";
import { Button } from "components/Button";
import {
  SelectInput,
  StandardOption,
} from "components/formik/SelectField";
import { TextInput } from "components/formik/TextField";
import { ZERO_WIDTH_SPACE } from "constants/constants";
import { gql, useQuery } from "@apollo/client";
import {
  FilterModel,
  HealthPlanModel,
  LineOfBusinessModel,
  ProviderModel,
} from "./model";
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";

const UM_REQUESTS_FILTER_DATA = gql`
  query UMRequestsFilterData {
    providers(first: 600) {
      items {
        id
        nameWithAppellation
      }
    }
    healthPlans(first: 300) {
      items {
        id
        name
      }
    }
    linesOfBusiness(first: 100) {
      items {
        id
        name
      }
    }
  }
`;

interface Data {
  providers: Paginated<ProviderModel>;
  healthPlans: Paginated<HealthPlanModel>;
  linesOfBusiness: Paginated<LineOfBusinessModel>;
}

export const defaultValue = {
  caseNumber: "",
  healthPlanId: "",
  lineOfBusinessId: "",
};

interface FilterPanelProps {
  value?: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value = defaultValue, onChange, isLoading = false } = props;

  const { data, loading } = useQuery<Data>(UM_REQUESTS_FILTER_DATA);

  const healthPlanOptions =
    data?.healthPlans.items.map(healthPlanAsOption) || [];
  const lineOfBusinessOptions =
    data?.linesOfBusiness.items.map(lineOfBusinessAsOption) || [];
  const providerOptions =
    data?.providers.items.map(providerAsOption) || [];

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="assignedPhysicianId" icon="filter" label="Assigned Physician" className="flex-grow">
          <SelectInput
            name="assignedPhysicianId"
            options={providerOptions}
            isLoading={loading}
            placeholder="Any"
            isClearable
          />
        </FilterField>

        <FilterField htmlFor="healthPlanId" icon="filter" label="Health Plan" className="flex-grow">
          <SelectInput
            name="healthPlanId"
            options={healthPlanOptions}
            isLoading={loading}
            placeholder="Any"
            isClearable
          />
        </FilterField>

        <FilterField htmlFor="lineOfBusinessId" icon="filter" label="Line of Business" className="flex-grow">
          <SelectInput
            name="lineOfBusinessId"
            options={lineOfBusinessOptions}
            isLoading={loading}
            placeholder="Any"
            isClearable
          />
        </FilterField>

        <FilterField htmlFor="caseNumber" icon="search" label="Case Number / Episode ID" className="flex-grow">
          <TextInput
            name="caseNumber"
            icon="search"
            placeholder="Case Number / Episode ID"
          />
        </FilterField>

        <FilterField label={ZERO_WIDTH_SPACE}>
          <Button
            type="submit"
            color="gold"
            disabled={loading || isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm >
  );
};

function healthPlanAsOption(hp: HealthPlanModel): StandardOption {
  return {
    value: hp.id,
    label: hp.name,
  };
}

function lineOfBusinessAsOption(lob: LineOfBusinessModel): StandardOption {
  return {
    value: lob.id,
    label: lob.name,
  };
}

function providerAsOption(prov: ProviderModel): StandardOption {
  return {
    value: prov.id,
    label: prov.nameWithAppellation,
  };
}

/**
 * Returns filter without keys that have value "ANY" or "ALL".
 * This is useful because the GraphQL API for AristaMD ID fields (e.g. specialtyId)
 * are integers, but we use "ANY" and "ALL" as the "unselected" value.
 */
export function removeUnusedFilterKeys(filter: Partial<Record<keyof FilterModel, any>>): FilterModel {
  let newFilter: FilterModel = {};
  for (const key in filter) {
    if (filter[key as keyof FilterModel] !== "ANY" && filter[key as keyof FilterModel] !== "ALL") {
      newFilter[key as keyof FilterModel] = filter[key as keyof FilterModel];
    }
  }
  return newFilter;
}
