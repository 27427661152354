import { FC, useState, createContext, useContext, useCallback, useEffect } from 'react';
import { useChannelEvent } from './ChannelContext';

const LOG_BUFFER_SIZE = 1000;

export interface LogEntry {
  level: "debug" | "info" | "warning" | "error";
  message: string;
  timestamp: string;
}

interface Ctx {
  logStreamId: string | null;
  logs: LogEntry[];
  setLogStreamId(id: string): void;
}

const LogStreamSubscriber: FC<{ logStreamId: string, onLog: (log: LogEntry) => void }> = props => {
  const { logStreamId, onLog } = props;
  useChannelEvent<LogEntry>(`log_stream:${logStreamId}`, "log", (data?: LogEntry) => {
    data && onLog(data);
  });
  return null;
};

const LogStreamContext = createContext<Ctx>(null as any);
interface LogStreamProviderProps { };

export const LogStreamProvider: FC<LogStreamProviderProps> = props => {
  const { children } = props;
  const [logStreamId, setLogStreamId] = useState<Ctx["logStreamId"]>(null);
  const [logs, setLogs] = useState<Ctx["logs"]>([]);

  const addLog = useCallback((log: LogEntry) => {
    log.level = log.level || "info";
    log.timestamp = log.timestamp || new Date().toISOString();
    setLogs(prev => {
      const next = [...prev, log];
      if (next.length > LOG_BUFFER_SIZE) {
        next.shift();
      }
      return next;
    });
  }, []);

  useEffect(() => {
    // When a new job is selected, clear the logs
    if (logStreamId) {
      setLogs([]);
    }
  }, [logStreamId]);

  return (
    <LogStreamContext.Provider value={{
      logStreamId,
      setLogStreamId,
      logs
    }}>
      {logStreamId ? (
        <LogStreamSubscriber logStreamId={logStreamId} onLog={addLog} />
      ) : null}

      {children}
    </LogStreamContext.Provider>
  )
};

export function useLogStream() {
  const context = useContext(LogStreamContext);
  if (!context) throw new Error("useLogStream must be used within a LogStreamProvider");
  return context;
}
