import { FC, useCallback, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { FadeUpIn } from "components/Animations";
import { CircleIcon } from "components/CircleIcon";
import { CircleIconHeader } from "components/CircleIconHeader";
import { ScreenTitle } from "context/ScreenTitle";
import { AppealRequestInfoPanel } from "../AppealRequestInfoPanel";
import { QuestionsForm } from "./QuestionsForm";
import { PillTab, PillTabs } from "components/PillTabs";
import { AppealRequestDocumentViewer } from "../AppealRequestDocumentViewer";
import { PostDecisionOptionsModal } from "./PostDecisionOptionsModal";
import toast from "react-hot-toast";

interface QuestionsStepProps { }

export const QuestionsStep: FC<QuestionsStepProps> = (props) => {
  const match = useRouteMatch<{ appealRequestId: string }>();
  const { appealRequestId } = match.params;

  const history = useHistory();

  const goBack = useCallback(() => history.goBack(), [history]);

  // const onSuccess = useCallback(() => {
  //   return history.push(`/ip/drafts/${appealRequestId}/confirm`);
  // }, [appealRequestId, history]);
  const onSuccess = useCallback(() => {
    toast.success("Intake qualifiers submitted!");
    return history.push(`/ip/appeals`);
  }, [history]);

  const [pdOptionsModalOpen, setPdOptionsModalOpen] = useState(true); // OPEN on page load.
  const [activeTab, setActiveTab] = useState<"CASE_INFO" | "ATTACHMENTS">("CASE_INFO")

  function closeModal() {
    setPdOptionsModalOpen(false);
  }

  return (
    <>
      <PostDecisionOptionsModal
        appealRequestId={appealRequestId}
        isOpen={pdOptionsModalOpen}
        onClose={closeModal}
      />
      <ScreenTitle title={["New Post-Decision Request", "Request Info"]} />

      <div className="_QuestionsStep px-4 pt-6">
        <FadeUpIn show>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <div className="rounded-xl top-4 sticky p-4 bg-white shadow-lg">
                <div className="flex items-center gap-3">
                  <CircleIcon size={32} icon="file-alt" />
                  <h4 className="text-xl font-black text-gray-600">
                    Case Info
                  </h4>
                </div>

                <div className="flex justify-center pt-6">
                  <PillTabs
                    activeTab={activeTab}
                    onChange={setActiveTab as (tab: string) => void}
                  >
                    <PillTab tab="CASE_INFO">Case Details</PillTab>
                    <div />
                    <PillTab tab="ATTACHMENTS">Attachments</PillTab>
                  </PillTabs>
                </div>

                {
                  activeTab === "CASE_INFO" ? (
                    <AppealRequestInfoPanel appealRequestId={appealRequestId} />
                  ) : (
                    <AppealRequestDocumentViewer appealRequestId={appealRequestId} />
                  )
                }
              </div>
            </div>

            <div className="col-span-1">
              <div className="rounded-xl p-4 bg-white shadow-xl">
                <CircleIconHeader icon="file-alt">Post-Decision Request Info</CircleIconHeader>
                <QuestionsForm
                  appealRequestId={appealRequestId}
                  onBack={goBack}
                  onSuccess={onSuccess}
                />
              </div>
            </div>
          </div>
        </FadeUpIn>
      </div>
    </>
  );
};
