import { FC, useCallback, useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import {
  Layout,
  Sidebar,
  SidebarLink,
  PageContent,
} from "layouts/PortalLayout";
import { NoMatchScreen } from "screens/NoMatchScreen";
import { AppealQueuesScreen } from "screens/InternalPortal/AppealQueuesScreen/AppealQueuesScreen";
import { AppealShowScreen } from "screens/InternalPortal/AppealShowScreen";
import { PlusIcon } from '@heroicons/react/24/outline'
import { StatusBoardScreen } from "screens/InternalPortal/StatusBoardScreen";
import { ReportsScreen } from "screens/InternalPortal/ReportsScreen";
import { SettingsScreen } from "screens/InternalPortal/SettingsScreen";
import { NewAppealFromChannelModal } from "screens/InternalPortal/NewAppealFromChannelModal";
import { DraftAppealWizardScreen } from "screens/InternalPortal/DraftAppealWizardScreen";
import { ButtonLink } from "components/Button";
import { CaseProfileShowScreen } from "screens/InternalPortal/CaseProfileShowScreen";
import { UMWarningsScreen } from "screens/InternalPortal/UMWarningsScreen";
import { AppealWarningsScreen } from "screens/InternalPortal/AppealWarningsScreen";
import { useCurrentAgent } from "hooks/useCurrentAgent";
import { CaseEventFeedScreen } from "screens/InternalPortal/CaseEventFeedScreen";
import { useEnabledFeatures } from "hooks/useEnabledFeatures";

/**
 * ActionButton.
 */

// interface ActionButtonProps {
//   onClick(): void;
// }

// const ActionButton: FC<ActionButtonProps> = (props) => {
//   const { onClick, children } = props;
//   const { sidebarOpen, toggleSidebar } = useLayout();

//   function maybeCloseSidebar() {
//     if (sidebarOpen) {
//       toggleSidebar();
//     }
//   }

//   function handleClick() {
//     maybeCloseSidebar();
//     onClick();
//   }

//   return (
//     <Button
//       type="button"
//       color="gold"
//       className="inline-flex items-center"
//       onClick={handleClick}
//     >
//       {children}
//     </Button>
//   );
// };

/**
 * DraftAppealModal.
 */

type ActiveModal = "NEW_APPEAL_FROM_CHANNEL";

interface InternalPortalProps { }

export const InternalPortal: FC<InternalPortalProps> = (props) => {
  const match = useRouteMatch();
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const currentAgent = useCurrentAgent();
  const enabledFeatures = useEnabledFeatures();

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  const displayName =
    [currentAgent?.firstName, currentAgent?.lastName].filter(Boolean).join(" ") ||
    "Loading...";

  return (
    <>
      <NewAppealFromChannelModal
        isOpen={activeModal === "NEW_APPEAL_FROM_CHANNEL"}
        onClose={closeModal}
      />
      <Layout>
        <Sidebar userDisplayName={displayName}>
          <div className="py-2 text-center">
            {/* <ActionButton
              onClick={() => setActiveModal("NEW_APPEAL_FROM_CHANNEL")}
            >
              <PlusIcon className="w-5 h-5 mr-2 -ml-1" />
              New Post-Decision Request
            </ActionButton> */}

            <ButtonLink to="/ip/drafts/new" color="gold" size="sm">
              <PlusIcon className="w-5 h-5 mr-2 -ml-1" />
              New Post-Decision Request
            </ButtonLink>
          </div>
          {/* <SidebarLink to="/ip/dashboard" icon="home">
          Dashboard
        </SidebarLink> */}
          {/* <SidebarLink to="/ip/projects" icon="folder">
          Projects
        </SidebarLink>
        <SidebarLink to="/ip/calendar" icon="calendar">
          Calendar
        </SidebarLink> */}
          {
            enabledFeatures.includes("um_md_assignments") ? (
              <SidebarLink to="/ip/um_warnings" icon="exclamationCircle">
                UM Warnings
              </SidebarLink>
            ) : null
          }
          <SidebarLink to="/ip/appeal_warnings" icon="exclamationCircle">
            PDR Warnings
          </SidebarLink>
          {/* <SidebarLink to="/ip/um_requests" icon="inbox">
            UM Inventory
          </SidebarLink> */}
          <SidebarLink to="/ip/appeals" icon="inbox">
            PDR Inventory
          </SidebarLink>
          <SidebarLink to="/ip/status_board" icon="lightningBolt">
            Status Board
          </SidebarLink>
          <SidebarLink to="/ip/case_events" icon="signal">
            Case Event Feed
          </SidebarLink>
          <SidebarLink to="/ip/reports" icon="chartBar">
            Reports
          </SidebarLink>
          <SidebarLink to="/ip/settings" icon="cog">
            Settings
          </SidebarLink>
        </Sidebar>
        <PageContent>
          <Switch>
            <Route exact path={`${match.path}um_warnings`}>
              <UMWarningsScreen />
            </Route>
            <Route exact path={`${match.path}appeal_warnings`}>
              <AppealWarningsScreen />
            </Route>
            <Route exact path={`${match.path}appeals`}>
              <AppealQueuesScreen />
            </Route>
            <Route path={`${match.path}drafts`}>
              <DraftAppealWizardScreen />
            </Route>
            <Route path={`${match.path}appeals/:id`}>
              <AppealShowScreen />
            </Route>
            <Route path={`${match.path}cases/:id`}>
              <CaseProfileShowScreen />
            </Route>
            <Route exact path={`${match.path}case_events`}>
              <CaseEventFeedScreen />
            </Route>
            <Route exact path={`${match.path}status_board`}>
              <StatusBoardScreen />
            </Route>
            <Route path={`${match.path}reports`}>
              <ReportsScreen />
            </Route>
            <Route path={`${match.path}settings`}>
              <SettingsScreen />
            </Route>
            <Route path="*">
              <NoMatchScreen />
            </Route>
          </Switch>
        </PageContent>
      </Layout>
    </>
  );
};
