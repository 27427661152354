import { FC, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { ScreenTitle } from "context/ScreenTitle";
import { Table, TableContainer, TD, TH } from "components/Table";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import toast from "react-hot-toast";

const GET_STATE_UM_SETTINGS = gql`
  query StateUMSettings {
    states {
      id
      name
      abbreviation
      umConfig {
        id
        autoAssignPercent
      }
    }
  }
`;

interface Data {
  states: State[];
}

const CREATE_UM_CONFIG = gql`
  mutation CreateUmConfig($input: UmConfigInput!) {
    createUmConfig(input: $input) {
      errors {
        key
        message
      }
      umConfig {
        id
        autoAssignPercent
        stateAbbreviation
      }
    }
  }
`;

interface MutationData {
  createUmConfig: {
    errors?: InputError[];
    umConfig?: {
      id: string;
      autoAssignPercent: number;
      stateAbbreviation: string;
    };
  };
}

interface State {
  id: string;
  name: string;
  abbreviation: string;
  umConfig: {
    id: string;
    autoAssignPercent: number;
  } | null;
}

interface StateUMSettingsScreenProps { }

export const StateUMSettingsScreen: FC<StateUMSettingsScreenProps> = (props) => {
  const { data, loading, error } = useQuery<Data>(GET_STATE_UM_SETTINGS);
  const [savingStates, setSavingStates] = useState<Set<string>>(new Set());
  const [localValues, setLocalValues] = useState<Record<string, number>>({});

  const [updateUmConfig] = useMutation<MutationData>(CREATE_UM_CONFIG, {
    refetchQueries: [{ query: GET_STATE_UM_SETTINGS }]
  });

  const handleSliderChange = async (state: State, newValue: number) => {
    setSavingStates(prev => new Set([...prev, state.id]));

    try {
      const result = await updateUmConfig({
        variables: {
          input: {
            stateAbbreviation: state.abbreviation,
            autoAssignPercent: newValue
          }
        }
      });

      if (result.data?.createUmConfig.errors) {
        const errorMessages = result.data.createUmConfig.errors
          .map(e => e.message)
          .join(", ");
        toast.error(`Failed to update: ${errorMessages}`);
      } else {
        toast.success(`Successfully updated ${state.name} UM assignment percentage`);
      }
    } catch (err) {
      toast.error("Failed to update UM assignment percentage");
    } finally {
      setSavingStates(prev => {
        const next = new Set(prev);
        next.delete(state.id);
        return next;
      });
    }
  };

  return (
    <>
      <ScreenTitle title="State UM Assignment Settings" />
      <div className="_StateUMSettingsScreen sm:rounded-md">
        <div className="sm:p-6 px-4 py-6 bg-white">
          <div className="pb-4">
            <h2
              id="state_um_sliders_heading"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              State UM Assignment Settings
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Manage UM auto-assignment percentages by state
            </p>
          </div>

          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.states ? (
            <p>Failed to load.</p>
          ) : data.states.length === 0 ? (
            <NoResults icon="list-alt" text="No states found" />
          ) : (
            <TableContainer>
              <Table className="text-left">
                <thead>
                  <tr>
                    <TH>State</TH>
                    <TH>UM Assignment %</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.states.map((state) => (
                    <tr
                      key={state.id}
                      className={savingStates.has(state.id) ? "bg-blue-100" : ""}
                    >
                      <TD className="px-3 py-1">{state.name}</TD>
                      <TD className="px-3 py-1">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          value={localValues[state.id] ?? state.umConfig?.autoAssignPercent ?? 100}
                          className="w-48"
                          onChange={(e) => setLocalValues(prev => ({
                            ...prev,
                            [state.id]: parseInt(e.target.value)
                          }))}
                          onMouseUp={(e) => handleSliderChange(state, parseInt((e.target as HTMLInputElement).value))}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              handleSliderChange(state, parseInt((e.target as HTMLInputElement).value));
                            }
                          }}
                        />
                        <span className="ml-2">
                          {localValues[state.id] ?? state.umConfig?.autoAssignPercent ?? 100}%
                        </span>
                      </TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};
