import { gql, useQuery } from "@apollo/client";
import uniqBy from "lodash.uniqby";
import groupBy from "lodash.groupby";
import { HealthPlanModel, FlatHealthPlanModel } from "./model";

const HEALTH_PLANS_DATA = gql`
  query HealthPlans {
    healthPlans(first: 300) {
      items {
        id
        name
        healthPlanPrograms {
          id
          caseSystem
          caseSystemHealthPlanAlias
        }
      }
    }
  }
`;

interface Data {
  healthPlans: Paginated<HealthPlanModel>;
}

export function useHealthPlanOptions() {
  const queryResult = useQuery<Data>(HEALTH_PLANS_DATA);
  const healthPlanOptions = flattenHealthPlans(
    queryResult.data?.healthPlans.items || []
  );

  return {
    ...queryResult,
    healthPlanOptions,
  };
}

export function flattenHealthPlans(
  healthPlans: HealthPlanModel[]
): FlatHealthPlanModel[] {
  return healthPlans.flatMap((hp) => {
    // NB: Assumption: There are no health plans with 0 programs.
    const grouped = groupBy(
      hp.healthPlanPrograms,
      (hpp) => hpp.caseSystemHealthPlanAlias ?? hp.name
    );
    const mapped = Object.keys(grouped).map((displayName) => ({
      id: hp.id,
      displayName,
      canonicalName: hp.name,
      aliasName: displayName !== hp.name ? displayName : null,
      caseSystems: uniqBy(
        grouped[displayName].map((hpp) => hpp.caseSystem),
        (caseSystem) => caseSystem
      ),
    }));
    return mapped;
  });
}
