import { FC, useCallback, useState } from "react";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalTextAreaField } from "components/formik/TextAreaField";
import { Formik } from "formik";
import { Button } from "components/Button";
import { HorizontalField } from "components/FieldStructure";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";


const LOG_VERBAL_NOTIFICATION = gql`
  mutation LogVerbalNotification($appealRequestId: UUID4!, $input: CaseHistoryInput!) {
    logVerbalNotification(appealRequestId: $appealRequestId, input: $input) {
      errors {
        key
        message
      }
      appealRequest {
        id
      }
    }
  }
`;

interface MutationData {
  logVerbalNotification: {
    errors?: InputError[];
    appealRequest?: {
      id: string;
    };
  };
}


export interface FormValues {
  journalNote: string;
  historyMessage: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    journalNote: Yup.string().required("Required"),
    historyMessage: Yup.string().required("Required"),
  })
  .required();

interface ConfirmVerbalNotificationFormProps {
  appealRequestId: string;
  initialValues: FormValues;
  onSuccess(): void;
};

export const ConfirmVerbalNotificationForm: FC<ConfirmVerbalNotificationFormProps> = props => {
  const { appealRequestId, initialValues, onSuccess } = props;
  const [isEditing, setIsEditing] = useState(false);

  const [logVerbalNotificationMut] = useMutation<MutationData>(LOG_VERBAL_NOTIFICATION);

  const onSubmit = useCallback(
    async (values: FormValues, { setStatus }) => {

      setStatus({ errors: null })

      if (appealRequestId) {
        const { data } = await logVerbalNotificationMut({
          variables: {
            appealRequestId: appealRequestId,
            input: values,
          },
        });

        if (data?.logVerbalNotification.errors) {
          setStatus({ errors: data.logVerbalNotification.errors });
        } else if (data?.logVerbalNotification.appealRequest) {
          toast.success("Verbal Notification Logged");
          onSuccess();
        }
      }
    },
    [logVerbalNotificationMut, appealRequestId, onSuccess]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, status, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <div className="mt-3">
            {
              isEditing ? (
                <HorizontalTextAreaField
                  label="Journal Note"
                  name="journalNote"
                  placeholder="Journal Note"
                />
              ) : (
                <HorizontalField label="Journal Note">
                  <p>{values.journalNote}</p>
                  <Button
                    type="button"
                    kind="tertiary"
                    size="xs"
                    color="purple"
                    onClick={() => setIsEditing(!isEditing)}
                  >
                    Edit
                  </Button>
                </HorizontalField>
              )
            }
          </div>

          <div className="mt-3">
            {
              isEditing ? (
                <HorizontalTextAreaField
                  label="History Message"
                  name="historyMessage"
                  placeholder="History Message"
                />
              ) : (
                <HorizontalField label="History Message">
                  <p>{values.historyMessage}</p>
                  <Button
                    type="button"
                    kind="tertiary"
                    size="xs"
                    color="purple"
                    onClick={() => setIsEditing(!isEditing)}
                  >
                    Edit
                  </Button>
                </HorizontalField>
              )
            }

            <div className="p-4 text-center">
              <Button
                type="submit"
                color="gold"
                size="lg"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>

  );
};
