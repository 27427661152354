import { useLayoutEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { DEFAULT_AFTER_SIGN_IN_PATH } from "config/constants";

/**
 * This implements a `useLayoutEffect` which redirects the user when they're
 * signed in. The path/page that the user is redirected to is either the `from`
 * in router location state, or the `defaultPath` passed as the argument.
 *
 * @param defaultPath the default path to forward the user to if signed in
 */
export function useFriendlyForward(
  defaultPath: string = DEFAULT_AFTER_SIGN_IN_PATH
) {
  const { token } = useAuth();
  const location = useLocation<any>();
  const history = useHistory();

  const destination = useMemo(
    () => location.state?.from || { pathname: defaultPath },
    [location.state?.from, defaultPath]
  );

  return useLayoutEffect(() => {
    if (token) {
      history.replace(destination);
    }
  }, [destination, history, token]);
}
