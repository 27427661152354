import React, { FC } from "react";
import styled from "styled-components";

const Container = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const Circle = styled.circle`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: round;
  stroke-dash-offset: ${(props) => props.strokeDashoffset};
`;

const BackgroundCircle = styled.circle`
  stroke: rgba(0, 0, 0, 0.3);
`;

type CircleProgressProps = {
  radius?: number;
  stroke?: number;
  progress: number;
};

export const CircleProgress: FC<CircleProgressProps> = (props) => {
  const { radius = 24, stroke = 2, progress } = props;

  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <Container size={radius * 2}>
      <svg
        height={radius * 2}
        width={radius * 2}
        style={{ position: "absolute" }}
      >
        <BackgroundCircle
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <Circle
          stroke="green"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + " " + circumference}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <p className="font-black text-green-800 text-xs">
        {Math.round(progress)}%
      </p>
    </Container>
  );
};
