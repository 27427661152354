import styled from "styled-components";

export const SpinnerContainer = styled.span`
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
