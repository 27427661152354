import React, { FC } from "react";
import {
  useLocation,
  Switch,
  useRouteMatch,
  Redirect,
  Route,
} from "react-router-dom";
// import qs from 'query-string';
import { CaseInfoStep } from "./CaseInfoStep";
import { QuestionsStep } from "./QuestionsStep";
import { ConfirmationStep } from "./ConfirmationStep";
import {
  NavigationSteps,
  NavigationStepModel,
} from "components/NavigationSteps";
import { ScreenTitle } from "context/ScreenTitle";
import { Container } from "components/Container";

const steps: NavigationStepModel[] = [
  {
    label: "Case Lookup",
    icon: "search",
  },
  {
    label: "Questions",
    icon: "clipboardList",
  },
  {
    label: "Confirmation",
    icon: "checkCircle",
  },
];

function getCurrentStep(pathname: string) {
  if (pathname.includes("/new")) return 0;
  if (pathname.includes("/questions")) return 1;
  if (pathname.includes("/confirm")) return 2;
  return -1;
}

interface DraftAppealWizardScreenProps { }

export const DraftAppealWizardScreen: FC<DraftAppealWizardScreenProps> = (
  props
) => {
  const location = useLocation();
  const match = useRouteMatch();
  // const { casePayloadId } = qs.parse(location.search);

  return (
    <div className="_DraftAppealWizardScreen">
      <ScreenTitle title="New Post-Decision Request" />
      <Container>
        <div className="md:px-12 px-4 text-center">
          <div className="sm:max-w-xl max-w-full mx-auto">
            <NavigationSteps
              steps={steps}
              currentStep={getCurrentStep(location.pathname)}
            />
          </div>
        </div>
      </Container>
      <Switch>
        <Route path={`${match.path}/new`}>
          <CaseInfoStep />
        </Route>
        <Route path={`${match.path}/:appealRequestId/questions`}>
          <QuestionsStep />
        </Route>
        <Route path={`${match.path}/:appealRequestId/confirm`}>
          <ConfirmationStep />
        </Route>
        <Route path="*">
          <Redirect to={`${match.path}/new`} />
        </Route>
      </Switch>
    </div >
  );
};
