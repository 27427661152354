import { FC, useState, ComponentType, useCallback } from "react";
import { ScreenTitle } from "context/ScreenTitle";
import qs from "query-string";
import {
  BanknotesIcon as CashIcon,
  ChevronRightIcon,
  FlagIcon,
  EnvelopeOpenIcon,
  ScaleIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline'
import { Link } from "react-router-dom";
import healthPlanData from "data/healthPlans.json";
import uniqBy from "lodash.uniqby";
import { Badge } from "components/Badge";
import { OnlineAgentsDrawer } from "components/OnlineAgentsDrawer";
import { DropdownButton, DropdownItemButton } from "components/DropdownButton";

type IconName = "scale" | "mailOpen" | "flag" | "userGroup";

const iconMap: Record<IconName, ComponentType<{ className?: string }>> = {
  scale: ScaleIcon,
  mailOpen: EnvelopeOpenIcon,
  flag: FlagIcon,
  userGroup: UserGroupIcon,
};

interface CardProps {
  icon: IconName;
  label: string;
  link?: string;
  linkLabel?: string;
  onLinkClick?(): void;
}

const Card: FC<CardProps> = (props) => {
  const {
    icon,
    label,
    link,
    onLinkClick,
    children,
    linkLabel = "View all",
  } = props;

  const Icon = iconMap[icon];

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow">
      <div className="sm:p-6 px-4 py-5">
        <div className="flex items-start">
          <div className="bg-gold-600 flex-shrink-0 p-3 rounded-md">
            {/* <!-- Heroicon name: users --> */}
            <Icon className="inline-block w-6 h-6 text-white" />
          </div>
          <div className="flex-1 w-0 ml-5">
            <dt className="text-sm font-medium text-gray-500 truncate">
              {label}
            </dt>
            {children}
          </div>
        </div>
      </div>
      {link && (
        <div className="bg-gray-50 sm:px-6 px-4 py-4">
          <div className="text-sm">
            <Link
              to={link}
              className="text-cyan-700 hover:text-cyan-900 font-medium"
            >
              {linkLabel}
            </Link>
          </div>
        </div>
      )}
      {onLinkClick && (
        <div className="bg-gray-50 sm:px-6 px-4 py-4">
          <div className="text-sm">
            <button
              type="button"
              onClick={onLinkClick}
              className="text-cyan-700 hover:text-cyan-900 font-medium"
            >
              {linkLabel}
            </button>
          </div>
        </div>
      )}
    </div>
  );

  // return (
  //   <div className="overflow-hidden bg-white rounded-lg shadow">
  //     <div className="p-5">
  //       <div className="flex items-center">
  //         <div className="flex-shrink-0">
  //           {/* <!-- Heroicon name: scale --> */}
  //           <Icon className="inline-block w-6 h-6 text-gray-400" />
  //         </div>
  //         <div className="flex-1 w-0 ml-5">
  //           <dl>
  //             <dt className="text-sm font-medium text-gray-500 truncate">
  //               {label}
  //             </dt>
  //             <dd>{children}</dd>
  //           </dl>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="bg-gray-50 px-5 py-3">
  //       <div className="text-sm">
  //         <Link
  //           to={link}
  //           className="text-cyan-700 hover:text-cyan-900 font-medium"
  //         >
  //           View all
  //         </Link>
  //       </div>
  //     </div>
  //   </div>
  // );
};

interface StatusBoardScreenProps { }

type ActiveModal = "ONLINE_USERS";

export const StatusBoardScreen: FC<StatusBoardScreenProps> = (props) => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  return (
    <>
      <ScreenTitle title="Status Board" />
      <OnlineAgentsDrawer
        isOpen={activeModal === "ONLINE_USERS"}
        onClose={closeModal}
      />
      <div className="StatusBoardScreen">
        <div className="mt-8">
          <div className="sm:px-6 lg:px-8 max-w-6xl px-4 mx-auto">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Overview
            </h2>
            <dl className="sm:grid-cols-2 lg:grid-cols-3 grid grid-cols-1 gap-5 mt-2">
              {/* <!-- Card --> */}
              <Card label="Open appeals" icon="mailOpen">
                <table className="table-auto">
                  <tbody>
                    <tr>
                      <td className="text-right">
                        <div className="text-2xl font-semibold text-gray-900">
                          482
                        </div>
                      </td>
                      <td>
                        <p className="flex items-baseline ml-2 text-sm font-semibold text-green-600">
                          On Track
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right">
                        <div className="text-2xl font-semibold text-gray-900">
                          5
                        </div>
                      </td>
                      <td>
                        <p className="flex items-baseline ml-2 text-sm font-semibold text-yellow-600">
                          At Risk
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right">
                        <div className="text-2xl font-semibold text-gray-900">
                          0
                        </div>
                      </td>
                      <td>
                        <p className="flex items-baseline ml-2 text-sm font-semibold text-red-600">
                          Overdue
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card label="Health plan SLAs" icon="flag">
                <table className="table-auto">
                  <tbody>
                    <tr>
                      <td className="text-right">
                        <div className="text-2xl font-semibold text-gray-900">
                          160
                        </div>
                      </td>
                      <td>
                        <p className="flex items-baseline ml-2 text-sm font-semibold text-green-600">
                          Meeting SLA
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right">
                        <div className="text-2xl font-semibold text-gray-900">
                          2
                        </div>
                      </td>
                      <td>
                        <p className="flex items-baseline ml-2 text-sm font-semibold text-yellow-600">
                          At Risk
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-right">
                        <div className="text-2xl font-semibold text-gray-900">
                          0
                        </div>
                      </td>
                      <td>
                        <p className="flex items-baseline ml-2 text-sm font-semibold text-red-600">
                          Unmet
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                onLinkClick={() => setActiveModal("ONLINE_USERS")}
                label="Online teammates"
                icon="userGroup"
              >
                <div className="text-lg font-medium text-gray-900">
                  <p>
                    209 / 312{" "}
                    <span className="font-thin text-gray-600">
                      (<span className="text-yellow-600">67%</span>)
                    </span>
                    <span className="ml-1 text-xs font-semibold text-gray-600">
                      agents
                    </span>
                  </p>
                  <p>
                    121 / 160{" "}
                    <span className="font-thin text-gray-600">
                      (<span className="text-green-600">76%</span>)
                    </span>
                    <span className="ml-1 text-xs font-semibold text-gray-600">
                      nurses
                    </span>
                  </p>
                  <p>
                    180 / 211{" "}
                    <span className="font-thin text-gray-600">
                      (<span className="text-green-600">85%</span>)
                    </span>
                    <span className="ml-1 text-xs font-semibold text-gray-600">
                      providers
                    </span>
                  </p>
                </div>
              </Card>

              {/* <!-- More cards... --> */}
            </dl>
          </div>

          <h2 className="sm:px-6 lg:px-8 max-w-6xl px-4 mx-auto mt-8 text-lg font-medium leading-6 text-gray-900">
            Health Plans
          </h2>

          {/* <!-- Activity list (smallest breakopoint only) --> */}
          <div className="sm:hidden shadow">
            <ul className="sm:hidden mt-2 overflow-hidden divide-y divide-gray-200 shadow">
              <li>
                <span className="_link hover:bg-gray-50 block px-4 py-4 bg-white">
                  <span className="flex items-center space-x-4">
                    <span className="flex flex-1 space-x-2 truncate">
                      {/* <!-- Heroicon name: cash --> */}
                      <CashIcon className="flex-shrink-0 inline-block w-5 h-5 text-gray-400" />

                      <span className="flex flex-col text-sm text-gray-500 truncate">
                        <span className="truncate">CIGNA HEALTHCARE</span>
                        <span>
                          <Badge color="green">Meeting SLA</Badge>
                        </span>
                        <span>3 open / 35 closed</span>
                      </span>
                    </span>
                    {/* <!-- Heroicon name: chevron-right --> */}
                    <ChevronRightIcon className="flex-shrink-0 inline-block w-5 h-5 text-gray-400" />
                  </span>
                </span>
              </li>

              {/* <!-- More items... --> */}
            </ul>

            <nav
              className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200"
              aria-label="Pagination"
            >
              <div className="flex justify-between flex-1">
                <span className="_link hover:text-gray-500 relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md">
                  Previous
                </span>
                <span className="_link hover:text-gray-500 relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md">
                  Next
                </span>
              </div>
            </nav>
          </div>

          {/* <!-- Activity table (small breakopoint and up) --> */}
          <div className="sm:block hidden">
            <div className="sm:px-6 lg:px-8 max-w-6xl px-4 mx-auto">
              <div className="flex flex-col mt-2">
                <div className="sm:rounded-lg min-w-full overflow-hidden overflow-x-auto align-middle shadow">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="bg-gray-50 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                          Health Plan
                        </th>
                        <th className="bg-gray-50 md:block hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                          Status
                        </th>
                        <th className="bg-gray-50 px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase">
                          #
                        </th>

                        <th className="bg-gray-50 px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                          Snapshot
                        </th>
                        <th className="bg-gray-50 px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {healthPlans.map((hp) => (
                        <tr key={hp.id} className="bg-white">
                          <td className="max-w-0 whitespace-nowrap w-full px-6 py-4 text-sm text-gray-900">
                            <div className="flex">
                              <Link
                                to={`/ip/appeals?${qs.stringify({
                                  healthPlanId: hp.id,
                                })}`}
                                className="group inline-flex space-x-2 text-sm truncate"
                              >
                                <p className="group-hover:text-gray-900 font-semibold text-gray-700 truncate">
                                  {hp.name}
                                </p>
                              </Link>
                            </div>
                          </td>
                          <td className="whitespace-nowrap md:block hidden px-6 py-4 text-sm text-gray-500">
                            {hp.slaStatus === "met" ? (
                              <Badge color="green">Meeting SLA</Badge>
                            ) : hp.slaStatus === "at_risk" ? (
                              <Badge color="yellow">SLA At Risk</Badge>
                            ) : (
                              <Badge color="red">SLA Unmet</Badge>
                            )}
                            {/* <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">
                              success
                            </span> */}
                          </td>
                          <td className="whitespace-nowrap px-6 text-sm text-right text-gray-500">
                            <table className="table-auto">
                              <tbody>
                                <tr>
                                  <td className="text-right">
                                    {hp.openAppealsCount}
                                  </td>
                                  <td className="pl-1 text-left">open</td>
                                </tr>
                                <tr>
                                  <td className="text-right">
                                    {hp.closedAppealsCount}
                                  </td>
                                  <td className="pl-1 text-left">closed</td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <span className="font-medium text-gray-900">
                              $20,000{" "}
                            </span>
                            USD */}
                          </td>

                          <td className="whitespace-nowrap px-6 py-4 text-sm text-right text-gray-500">
                            a few seconds ago
                          </td>
                          <td>
                            <DropdownButton label="Actions">
                              <DropdownItemButton
                                color="red"
                                onClick={() => { }}
                              >
                                De-prioritize health plan
                              </DropdownItemButton>
                            </DropdownButton>
                          </td>
                        </tr>
                      ))}

                      {/* <!-- More rows... --> */}
                    </tbody>
                  </table>
                  {/* <!-- Pagination --> */}
                  <nav
                    className="sm:px-6 flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200"
                    aria-label="Pagination"
                  >
                    <div className="sm:block hidden">
                      <p className="text-sm text-gray-700">
                        Showing
                        <span className="font-medium">1</span>
                        to
                        <span className="font-medium">10</span>
                        of
                        <span className="font-medium">20</span>
                        results
                      </p>
                    </div>
                    <div className="sm:justify-end flex justify-between flex-1">
                      <span className="_link hover:bg-gray-50 relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md">
                        Previous
                      </span>
                      <span className="_link hover:bg-gray-50 relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md">
                        Next
                      </span>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const healthPlans = uniqBy(healthPlanData, (h) => h.name).map((hp, idx) => {
  let slaStatus: "met" | "at_risk" | "unmet";
  const rand = Math.random();
  if (rand < 0.2) {
    slaStatus = "at_risk";
  } else if (rand >= 0.2 && rand < 0.25) {
    slaStatus = "unmet";
  } else {
    slaStatus = "met";
  }
  const openAppealsCount = Math.floor(Math.random() * 40);
  const closedAppealsCount = Math.floor(Math.random() * 100);
  return {
    ...hp,
    slaStatus,
    openAppealsCount,
    closedAppealsCount,
  };
});
