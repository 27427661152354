import { FC, useCallback } from "react";
import { Formik } from "formik";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useReports } from "screens/InternalPortal/ReportsScreen/ReportsContext";
import { mmDdYyyy } from "utils/dateFormatters";
import { Button } from "components/Button";
import { DatePickerField } from "components/formik/DatePickerField";
import { RadioGroupField } from "components/formik/RadioGroupField";
import { TextField } from "components/formik/TextField";
import { VerticalField } from "components/FieldStructure";
import { ToggleSwitch } from "components/ToggleSwitch";
import { useToggles } from "hooks/useToggles";
import { SelectField, StandardOption } from "components/formik/SelectField";
import { localDateToISO } from "utils/localDateToISO";
import { HealthPlanSelectField } from "components/formik/HealthPlanSelectField";
import toast from "react-hot-toast";

const FORM_DATA = gql`
  query SLAComplianceFormData {
    linesOfBusiness(first: 100) {
      cursor
      endOfList
      items {
        id
        name
      }
    }
    caseSystems
  }
`;

interface Data {
  caseSystems: string[];
  linesOfBusiness: Paginated<LineOfBusinessModel>;
}

interface LineOfBusinessModel {
  id: string;
  name: string;
}

const EXPORT_SLA_COMPLIANCE = gql`
  mutation CreateSLAComplianceExport(
    $input: SlaComplianceExportInput!
    $filter: SlaComplianceReportFilterInput
  ) {
    createSlaComplianceExport(input: $input, filter: $filter) {
      errors {
        key
        message
      }
      reportExport {
        id
      }
    }
  }
`;

const encryptionMethodOptions = ["AES", "PGP"].map((t) => ({
  value: t,
  label: t,
}));

/**
 * ToggleCard.
 */

interface ToggleCardProps {
  toggleId: string;
  toggleSize?: number;
  checked: boolean;
  onToggle(): void;
}

export const ToggleCard: FC<ToggleCardProps> = (props) => {
  const { toggleId, toggleSize = 24, checked, onToggle, children } = props;

  return (
    <div
      className={`flex items-center transition-colors duration-200 rounded-md border ${checked ? "shadow-md bg-white border-blue-300" : "bg-gray-100"
        }`}
    >
      <div className="p-3">
        <ToggleSwitch
          id={toggleId}
          size={toggleSize}
          checked={checked}
          onChange={onToggle}
          showLabel
        />
      </div>
      <div className="flex-grow">{children}</div>
    </div>
  );
};

/**
 * DownloadSLAComplianceForm.
 */

interface DownloadSLAComplianceFormProps {
  onSuccess(): void;
}

type FilterKey = "HEALTH_PLAN" | "LINE_OF_BUSINESS" | "CASE_SYSTEM";

export const DownloadSLAComplianceForm: FC<DownloadSLAComplianceFormProps> = (
  props
) => {
  const { onSuccess } = props;
  const { timeRange } = useReports();
  const { data, loading } = useQuery<Data>(FORM_DATA);

  const [createExport] = useMutation(EXPORT_SLA_COMPLIANCE);

  const [, { toggle, isActive }] = useToggles<FilterKey>();

  const caseSystemOptions = data?.caseSystems.map(caseSystemAsOption) || [];
  const lineOfBusinessOptions = data?.linesOfBusiness.items.map(
    lineOfBusinessAsOption
  ) || [];

  const onSubmit = useCallback(
    (values, formikActions) => {
      function cleanFilter(
        filter: Record<string, string>
      ): Record<string, string> {
        const filterSwitches: Record<string, FilterKey> = {
          healthPlanId: "HEALTH_PLAN",
          lineOfBusinessId: "LINE_OF_BUSINESS",
          caseSystem: "CASE_SYSTEM",
        };

        return Object.keys(filterSwitches).reduce((newFilter, key) => {
          const valuePresent = !!filter[key];
          const switchOn = isActive(filterSwitches[key]);

          return valuePresent && switchOn
            ? { ...newFilter, [key]: filter[key] }
            : newFilter;
        }, {});
      }
      const input = {
        dateRange: {
          start: localDateToISO(values.dateRange.start),
          finish: localDateToISO(values.dateRange.finish),
        },
        encryptionMethod: values.encryptionMethod,
        encryptionPassword: values.encryptionPassword,
      };

      const filter = cleanFilter(values.filter);

      return createExport({
        variables: {
          input,
          filter,
        },
      }).then((resp) => {
        if (resp.data?.createSlaComplianceExport.reportExport) {
          // it worked
          toast.success("SLA Compliance report generated!", {});
          return onSuccess();
        }
      });
    },
    [onSuccess, createExport, isActive]
  );

  return (
    <div className="DownloadSLAComplianceForm">
      <Formik
        initialValues={{
          dateRange: {
            start: mmDdYyyy(timeRange.start),
            finish: mmDdYyyy(timeRange.finish),
          },
          encryptionMethod: "AES",
          encryptionPassword: "",
          filter: {
            healthPlanId: "",
            lineOfBusinessId: "",
            caseSystem: "",
          },
        }}
        onSubmit={onSubmit}
      >
        {({ values, status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <DatePickerField
                name="dateRange.start"
                label="Start Date"
                icon={["far", "calendar-alt"]}
              />

              <DatePickerField
                name="dateRange.finish"
                label="End Date"
                icon={["far", "calendar-alt"]}
              />
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <RadioGroupField
                name="encryptionMethod"
                label="Encryption Method"
                options={encryptionMethodOptions}
                inline
              />

              {values.encryptionMethod === "AES" ? (
                <TextField
                  type="password"
                  icon="lock"
                  name="encryptionPassword"
                  label="Encryption Password"
                  placeholder="••••••••••"
                />
              ) : null}
            </div>

            <div className="mt-4">
              <VerticalField label="Filters">
                <div className="mt-2">
                  <ToggleCard
                    toggleId="HEALTH_PLAN"
                    checked={isActive("HEALTH_PLAN")}
                    onToggle={() => toggle("HEALTH_PLAN")}
                  >
                    <div className="p-3">
                      <HealthPlanSelectField
                        name="filter.healthPlanId"
                        label={
                          <span
                            className={`${!isActive("HEALTH_PLAN") ? "text-gray-400" : ""
                              }`}
                          >
                            Health Plan
                          </span>
                        }
                        disabled={!isActive("HEALTH_PLAN")}
                      />
                    </div>
                  </ToggleCard>
                </div>

                <div className="mt-2">
                  <ToggleCard
                    toggleId="CASE_SYSTEM"
                    checked={isActive("CASE_SYSTEM")}
                    onToggle={() => toggle("CASE_SYSTEM")}
                  >
                    <div className="p-3">
                      <SelectField
                        name="filter.caseSystem"
                        label={
                          <span
                            className={`${!isActive("CASE_SYSTEM") ? "text-gray-400" : ""
                              }`}
                          >
                            Case System
                          </span>
                        }
                        options={caseSystemOptions}
                        disabled={!isActive("CASE_SYSTEM")}
                        isLoading={loading}
                      />
                    </div>
                  </ToggleCard>
                </div>

                <div className="mt-2">
                  <ToggleCard
                    toggleId="LINE_OF_BUSINESS"
                    checked={isActive("LINE_OF_BUSINESS")}
                    onToggle={() => toggle("LINE_OF_BUSINESS")}
                  >
                    <div className="p-3">
                      <SelectField
                        name="filter.lineOfBusinessId"
                        label={
                          <span
                            className={`${!isActive("LINE_OF_BUSINESS")
                              ? "text-gray-400"
                              : ""
                              }`}
                          >
                            Line of Business
                          </span>
                        }
                        options={lineOfBusinessOptions}
                        disabled={!isActive("LINE_OF_BUSINESS")}
                        isLoading={loading}
                      />
                    </div>
                  </ToggleCard>
                </div>
              </VerticalField>
            </div>

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <div className="mt-6 text-center">
              <Button type="submit" kind="primary" color="blue">
                Create Export
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

function lineOfBusinessAsOption(lob: LineOfBusinessModel): StandardOption {
  return {
    value: lob.id,
    label: lob.name,
  };
}

function caseSystemAsOption(caseSystem: string): StandardOption {
  return {
    value: caseSystem,
    label: caseSystem,
  };
}
