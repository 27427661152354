export interface TimeRange {
  start: Date;
  finish: Date;
}

export enum AppointmentSubset {
  All = "ALL",
  Reschedule = "RESCHEDULE",
  Scheduled = "SCHEDULED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
  Missed = "MISSED",
}

export interface AppointmentQueryFilter {
  subset: AppointmentSubset;
}
