import { FC } from "react";
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";
import { SelectInput } from "components/formik/SelectField";
import { Button } from "components/Button";
import { TextInput } from "components/formik/TextField";
import { removeVoidKeys } from "utils/removeVoidKeys";

export interface FilterModel extends Record<string, any> {
  eventName?: string;
  caseNumber?: string;
}

export const defaultFilter: FilterModel = {
  caseNumber: "",
};

const eventOptions = ["um_md_assignment_required", "intake", "nurse_review_completed", "md_review_completed", "member_dialer_call_failed", "provider_dialer_call_failed", "case_details_updated", "update_appeals_queue", "p2p_completed"].map(label => ({ value: label, label }));
const allEventOptions = [{ value: "ANY", label: "All" }, ...eventOptions];

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value, onChange, isLoading = false } = props;

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={(values) => onChange(removeVoidKeys<FilterModel>(values))}
    >
      <div className="flex items-end gap-2">
        <FilterField
          htmlFor="eventName"
          icon="filter"
          label="Event Name"
        >
          <div className="w-56">
            <SelectInput
              isClearable
              name="eventName"
              options={allEventOptions}
            />
          </div>
        </FilterField>

        <FilterField htmlFor="caseNumber" icon="search" label="Case Number">
          <div className="w-48">
            <TextInput
              name="caseNumber"
              placeholder="Case Number"
              icon="search"
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="gold"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};
