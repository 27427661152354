import { FC, useEffect, useState } from "react";
import qs from "query-string";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { Spinner } from "components/Spinner";
import { useFriendlyForward } from "screens/SignInScreen";

interface OAuthCallbackScreenProps { };

export const OAuthCallbackScreen: FC<OAuthCallbackScreenProps> = props => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [errors, setErrors] = useState<InputError[] | null>(null);
  const match = useRouteMatch<{ provider: string }>();
  const { search } = useLocation();
  const params = qs.parse(search);

  const { login } = useAuth();

  useFriendlyForward();

  useEffect(() => {
    // Constructing rather than just passing `params` in order
    // to be explicit.
    const body = {
      code: params.code,
      state: params.state
    };
    fetch(`/oauth/${match.params.provider}/callback`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      credentials: "same-origin"
    }).then(resp => resp.json()).then(data => {
      setData(data);
      setLoading(false);
      setErrors(null);

      if (data.access_token) {
        return login(data.access_token);
      } else {
        console.error("Failed to login")
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="_OAuthCallbackScreen flex items-center justify-center">
      {
        loading ? (
          <div className="p-6">
            <Spinner />
          </div>
        ) : !data ? (
          <p>Failed to load.</p>
        ) : errors?.length ? (
          <div className="text-red-700">
            <p className="py-4">Error logging in:</p>
            <ul className="list-disc">
              {
                errors.map(e => (
                  <li key={e.key}>{e.message}</li>
                ))
              }
            </ul>
          </div>
        ) : (
          <p>Logging in...</p>
        )
      }
    </div>
  );
};
