import { FC, useState, useRef, InputHTMLAttributes } from "react";
import cx from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useField, ErrorMessage } from "formik";
import { VerticalField, HorizontalField } from "components/FieldStructure";
import { FAIcon } from "components/FAIcon";
import { usePrevious } from "hooks/usePrevious";
import { IconContainer } from "../IconContainer";

/**
 * InputNumber.
 */

export interface InputNumberProps {
  value: number;
  /* Whether the input can be editted manually or only the increment/decrement buttons can be used to change the value */
  canEditManually?: boolean;
  min?: number;
  max?: number;
  step?: number;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChange(newValue: number): void;
  onBlur?(e: any): void;
}

export const InputNumber: FC<InputNumberProps> = (props) => {
  const {
    value,
    canEditManually = true,
    min = 0,
    max = Number.MAX_VALUE,
    step = 1,
    onChange,
    onBlur,
    inputProps = {},
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  /* Keep track of intermediary string values, even though we're not getting valid number out on each keystroke */
  const [rawInputValue, setRawInputValue] = useState(String(value));
  const previousValue = usePrevious(value);

  if (previousValue !== value && rawInputValue !== String(value)) {
    setRawInputValue(String(value));
  }

  const isDecrementDisabled = value <= min || inputProps.disabled;
  const isIncremenetDisabled = value >= max || inputProps.disabled;

  /**
   * @param {number} incrementBy - amount by which to increment the current value.
   */
  function add(incrementBy: number) {
    if (!inputRef.current) return;

    const currentValue = parseNumber(inputRef.current.value);
    if (
      currentValue !== undefined &&
      currentValue + incrementBy >= min &&
      currentValue + incrementBy <= max
    ) {
      inputRef.current.focus();
      onChange(currentValue + incrementBy);
    }
  }

  function onInputChange(newValue: string) {
    const number = parseNumber(newValue);

    setRawInputValue(newValue);

    if (number !== undefined && number >= min && number <= max) {
      onChange(number);
    }
  }

  function onInputBlur(evt: any) {
    if (rawInputValue !== String(value)) {
      setRawInputValue(String(value));
    }
    if (onBlur) {
      onBlur(evt);
    }
  }

  return (
    <div className="inputNumber relative w-full" style={{ maxWidth: 150 }}>
      <div className="input">
        <div className="input-wrapper relative flex items-center">
          <input
            {...inputProps}
            className={cx(inputProps.className, "appearance-none block w-full px-3 py-2 border disabled:bg-gray-100 border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5")}
            type="number"
            ref={inputRef}
            value={rawInputValue}
            min={min}
            max={max}
            step={step}
            readOnly={!canEditManually}
            onChange={(evt) => onInputChange(evt.target.value)}
            onBlur={onInputBlur}
          />
        </div>
      </div>
      <div
        className="inputButtons absolute flex bg-gray-200 rounded-r"
        style={{ top: 1, right: 1, bottom: 1, width: 60 }}
      >
        <span
          className={cx(
            "flex-1 text-center text-xl select-none border-l border-gray-300",
            {
              "disabled bg-gray-400 text-white cursor-not-allowed": isDecrementDisabled,
              "cursor-pointer hover:bg-blue-200 text-gray-600 hover:text-gray-800": !isDecrementDisabled,
            }
          )}
          onClick={() => add(-step)}
        >
          -
        </span>
        <span
          className={cx(
            "flex-1 text-center text-xl select-none border-l border-gray-300",
            {
              "disabled bg-gray-400 text-white cursor-not-allowed": isIncremenetDisabled,
              "cursor-pointer hover:bg-blue-200 text-gray-600 hover:text-gray-800": !isIncremenetDisabled,
            }
          )}
          onClick={() => add(+step)}
        >
          +
        </span>
      </div>
    </div>
  );
};

/**
 * NumberInput.
 */

export interface NumberInputProps {
  id?: string;
  name: string;
  placeholder?: string;
  icon?: IconProp;
  autoFocus?: boolean;
  className?: string;
  min?: number;
  max?: number;
}

export const NumberInput: FC<NumberInputProps> = ({
  id,
  name,
  placeholder,
  icon,
  autoFocus = false,
  className,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <div className={cx({ "has-icons-left": icon })}>
        <InputNumber
          inputProps={{
            id: id || name,
            placeholder,
            className: cx(
              meta && meta.touched && meta.error
                ? "form-input w-full border border-red-500"
                : "form-input w-full",
              className
            ),
            autoFocus,
          }}
          value={value}
          onChange={setValue}
          onBlur={onBlur}
          {...rest}
        />
        {/* <input
          type="number"
          id={id || name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus={autoFocus}
          className={cx(
            meta && meta.touched && meta.error
              ? "form-input w-full border border-red-500"
              : "form-input w-full",
            className
          )}
        /> */}
        {icon && (
          <IconContainer className="text-gray-500">
            <FAIcon icon={icon} />
          </IconContainer>
        )}
      </div>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * NumberField.
 */

export interface NumberFieldProps extends NumberInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const NumberField: FC<NumberFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <NumberInput {...props} placeholder={props.placeholder || label} />
    </VerticalField>
  );
};

export const HorizontalNumberField: FC<NumberFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <NumberInput {...rest} placeholder={rest.placeholder || label} />
    </HorizontalField>
  );
};

function parseNumber(str: string) {
  if (str === "") return undefined;
  const number = Number(str);
  if (Number.isNaN(number)) return undefined;
  return number;
}
