export const levelsOfReview = {
  open_case_p2p: "Open Case P2P / PDC",
  informal_p2p: "Informal P2P",
  reconsideration: "Reconsideration P2P",
  "1st_level_appeal_p2p": "1st Level Appeal P2P",
  "2nd_level_appeal_p2p": "2nd Level Appeal P2P",
  p2p_new_case_required_if_approved: "P2P - New Case Required if Approved",
  p2p_consult_only: "P2P Consult Only",
  restart: "Restart",
  appeal_p2p_pilot: "Appeal P2P Pilot",
  appeal_p2p_pilot_husky_level1: "Appeal P2P Pilot - Husky Level 1",
  appeal_p2p_pilot_husky_level2: "Appeal P2P Pilot - Husky Level 2",
};

export function levelOfReviewLabel(lorValue: string): string {
  return levelsOfReview[lorValue];
}
