import { Socket, Presence, Channel as PhoenixChannel } from "phoenix";

export interface Handler<T extends JSONObject = JSONObject> {
  (data?: T): void;
}

type HandlerRef = number;

type SubscriberId = string;
type EventName = string;

class EventSubscriber {
  subscriberId: SubscriberId;
  eventHandlers: Record<EventName, HandlerRef>;

  constructor(subscriberId: SubscriberId) {
    this.subscriberId = subscriberId;
    this.eventHandlers = {};
  }
}

class PresenceSubscriber {
  subscriberId: SubscriberId;
  onUpdate: Handler;

  constructor(subscriberId: SubscriberId, onUpdate: Handler) {
    this.subscriberId = subscriberId;
    this.onUpdate = onUpdate;
  }
}

class Channel {
  topic: string;
  channel: PhoenixChannel;
  eventSubscribers: EventSubscriber[];
  presenceSubscribers: PresenceSubscriber[];
  presenceData: any;

  constructor(socket: Socket, topic: string) {
    this.topic = topic;
    this.channel = socket.channel(topic);
    this.eventSubscribers = [];
    this.presenceSubscribers = [];
    this.presenceData = {};

    this.channel.join();

    this.channel.on("presence_state", (state) => {
      this.presenceData = Presence.syncState(this.presenceData, state);
      this.onPresenceUpdated();
    });

    this.channel.on("presence_diff", (diff) => {
      this.presenceData = Presence.syncDiff(this.presenceData, diff);
      this.onPresenceUpdated();
    });
  }

  hasSubscribers() {
    return (
      this.eventSubscribers.length > 0 || this.presenceSubscribers.length > 0
    );
  }

  unsubscribe(subscriberId: SubscriberId) {
    this.unsubscribeEvents(subscriberId);
    this.unsubscribePresence(subscriberId);
  }

  unsubscribeEvents(subscriberId: SubscriberId) {
    const subscriber = this.getEventSubscriber(subscriberId);

    if (subscriber) {
      for (const eventName in subscriber.eventHandlers) {
        this.channel.off(eventName, subscriber.eventHandlers[eventName]);
      }
    }

    this.removeEventSubscriber(subscriberId);
  }

  unsubscribePresence(subscriberId: SubscriberId) {}

  close() {
    return this.channel.leave();
  }

  bindEvent(subscriberId: SubscriberId, eventName: string, handler: Handler) {
    const subscriber =
      this.getEventSubscriber(subscriberId) ||
      this.createEventSubscriber(subscriberId);

    subscriber.eventHandlers[eventName] = this.channel.on(eventName, handler);
  }

  unbindEvent(subscriberId: SubscriberId, eventName: string) {
    const subscriber = this.getEventSubscriber(subscriberId);

    if (subscriber && subscriber.eventHandlers[eventName] !== undefined) {
      this.channel.off(eventName, subscriber.eventHandlers[eventName]);
      delete subscriber.eventHandlers[eventName];
    }

    if (subscriber && Object.keys(subscriber.eventHandlers).length === 0) {
      this.removeEventSubscriber(subscriberId);
    }
  }

  bindPresence(subscriberId: SubscriberId, onUpdate: Handler) {
    const subscriber =
      this.getPresenceSubscriber(subscriberId) ||
      this.createPresenceSubscriber(subscriberId, onUpdate);

    subscriber.onUpdate = onUpdate;
  }

  unbindPresence(subscriberId: SubscriberId) {
    this.unsubscribePresence(subscriberId);
  }

  private getEventSubscriber(subscriberId: SubscriberId) {
    return this.eventSubscribers.find((s) => s.subscriberId === subscriberId);
  }

  private createEventSubscriber(subscriberId: SubscriberId) {
    const subscriber = new EventSubscriber(subscriberId);
    this.eventSubscribers.push(subscriber);
    return subscriber;
  }

  private removeEventSubscriber(subscriberId: SubscriberId) {
    this.eventSubscribers = this.eventSubscribers.filter(
      (s) => s.subscriberId === subscriberId
    );
  }

  private getPresenceSubscriber(subscriberId: SubscriberId) {
    return this.presenceSubscribers.find(
      (s) => s.subscriberId === subscriberId
    );
  }

  private createPresenceSubscriber(
    subscriberId: SubscriberId,
    onUpdate: Handler
  ) {
    const subscriber = new PresenceSubscriber(subscriberId, onUpdate);
    this.presenceSubscribers.push(subscriber);
    return subscriber;
  }

  private onPresenceUpdated() {
    for (const subscriberId in this.presenceSubscribers) {
      if (this.presenceSubscribers[subscriberId]) {
        this.presenceSubscribers[subscriberId].onUpdate(this.presenceData);
      }
    }
  }
}

export class ChannelManager {
  socket: Socket;
  channels: Channel[];

  constructor(socket: Socket) {
    this.socket = socket;
    this.channels = [];
  }

  openChannel(topic: string) {
    const channel = new Channel(this.socket, topic);
    this.channels.push(channel);
    return channel;
  }

  bindEvent(
    subscriberId: SubscriberId,
    topic: string,
    eventName: string,
    handler: Handler
  ) {
    const channel = this.getChannel(topic) || this.openChannel(topic);
    channel.bindEvent(subscriberId, eventName, handler);
  }

  unbindEvent(subscriberId: SubscriberId, topic: string, eventName: string) {
    const channel = this.getChannel(topic);
    channel?.unbindEvent(subscriberId, eventName);
  }

  unsubscribeEvents(subscriberId: SubscriberId, topic: string) {
    const channel = this.getChannel(topic);
    channel?.unsubscribeEvents(subscriberId);

    if (channel && !channel.hasSubscribers()) {
      channel.close();
      this.channels = this.channels.filter((c) => c.topic !== topic);
    }
  }

  bindPresence(subscriberId: SubscriberId, topic: string, onUpdate: Handler) {
    const channel = this.getChannel(topic) || this.openChannel(topic);
    channel.bindPresence(subscriberId, onUpdate);
  }

  unbindPresence(subscriberId: SubscriberId, topic: string) {
    const channel = this.getChannel(topic);
    channel?.unbindPresence(subscriberId);
  }

  getChannelPresenceData(topic: string) {
    const channel = this.getChannel(topic);
    return channel?.presenceData;
  }

  private getChannel(topic: string) {
    return this.channels.find((c) => c.topic === topic);
  }
}
