import { FC } from "react";
import { Drawer, DrawerHeader } from "components/Drawer";
import { useProviderAvailabilityFilter } from "../AssignMDDrawer";
import { Spinner } from "components/Spinner";
import { AvailablePanelsSearch } from "./AvailablePanelsSearch";
import { JsonDebugger } from "components/JsonDebugger";

interface AssignPanelDrawerProps {
  caseProfileId?: string;
  isOpen: boolean;
  onClose(): void;
  onBookSuccess(): void;
};

export const AssignPanelDrawer: FC<AssignPanelDrawerProps> = props => {
  const { isOpen, onClose, caseProfileId, onBookSuccess } = props;

  const { data, loading, error } = useProviderAvailabilityFilter({ variables: { id: caseProfileId }, skip: !caseProfileId });

  const currentAppealRequest = data?.caseProfile.currentAppealRequest;

  return (
    <Drawer width="5xl" className="_AssignMDPanelDrawer bg-gray-50" isOpen={isOpen} onRequestClose={onClose}>
      <DrawerHeader icon="users" title="MD Panel Search" onClose={onClose} />
      <div className="bg-gray-50 p-4">
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !data?.caseProviderAvailabilityFilter ? (
          <p>Failed to load.</p>
        ) : !currentAppealRequest ? (
          <div className="px-8 py-4 font-semibold bg-gray-100 border rounded-md">
            <p>No active post-decision request for case.</p>
          </div>
        ) : (
          <>
            <AvailablePanelsSearch
              appealRequestId={currentAppealRequest.id}
              modalityId={data.caseProfile.modalityId}
              caseFilterData={data.caseProviderAvailabilityFilter}
              onBookSuccess={onBookSuccess}
            />
            <JsonDebugger data={data} />
          </>
        )}
      </div>
    </Drawer>
  );
};
