import { useEffect, useRef } from "react";

export function useScrollToBottom<T extends HTMLElement>(
  deps: React.DependencyList,
  refArg?: React.RefObject<T>
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    (refArg || ref).current?.scrollIntoView({ behavior: "smooth" });
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return refArg || ref;
}
