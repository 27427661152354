import { FC } from "react";
import { useField, ErrorMessage } from "formik";
import { VerticalField, HorizontalField } from "components/FieldStructure";
import { Radio, RadioGroup, RadioGroupProps } from "components/RadioGroup";
import { StandardOption } from "../SelectField";

/**
 * RadioGroupInput.
 */

export interface RadioGroupInputProps {
  name: string;
  id?: string;
  className?: string;
  inline?: boolean;
  options: StandardOption[];
  inputProps?: RadioGroupProps<string>;
}

export const RadioGroupInput: FC<RadioGroupInputProps> = (props) => {
  const { name, id, className, inline, options, inputProps = {} } = props;

  const [{ onChange, onBlur, ...field }] = useField(name);

  return (
    <>
      <RadioGroup
        {...field}
        labelledBy={`field--${id || name}`}
        onChange={onChange(name)}
        className={className}
        inline={inline}
        {...inputProps}
      >
        {options.map((option) => (
          <Radio key={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </RadioGroup>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

/**
 * RadioGroupField.
 */

export interface RadioGroupFieldProps extends RadioGroupInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const RadioGroupField: FC<RadioGroupFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <RadioGroupInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalRadioGroupField: FC<RadioGroupFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <RadioGroupInput {...rest} />
    </HorizontalField>
  );
};
