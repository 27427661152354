import { gql, useQuery } from "@apollo/client";

const GET_CURRENT_AGENT = gql`
  query GetCurrentAgent {
    me {
      id
      email
      firstName
      lastName
      timeZoneName
      role
    }
  }
`;

interface Data {
  me: CurrentAgentModel;
}

interface CurrentAgentModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  timeZoneName: string;
  role: "staff" | "admin" | "system_admin";
}

export function useCurrentAgent(): CurrentAgentModel | undefined {
  const { data } = useQuery<Data>(GET_CURRENT_AGENT);
  return data?.me;
}
