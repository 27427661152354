import { FC } from "react";
import { useField, ErrorMessage } from "formik";
import { VerticalField, HorizontalField } from "components/FieldStructure";
import {
  Channel,
  ChannelGroup,
  ChannelGroupProps,
} from "components/ChannelSelect";

/**
 * ChannelGroupInput.
 */

export interface ChannelGroupInputProps {
  name: string;
  id?: string;
  className?: string;
  options: string[];
  inputProps?: ChannelGroupProps<string>;
}

export const ChannelGroupInput: FC<ChannelGroupInputProps> = (props) => {
  const { name, id, className, options, inputProps = {} } = props;

  const [{ onChange, onBlur, ...field }] = useField(name);

  return (
    <>
      <ChannelGroup
        {...field}
        labelledBy={`field--${id || name}`}
        onChange={onChange(name)}
        className={className}
        {...inputProps}
      >
        {options.map((option) => (
          <Channel key={option} value={option}>
            {capitalizeFirstLetter(option)}
          </Channel>
        ))}
      </ChannelGroup>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * ChannelGroupField.
 */

export interface ChannelGroupFieldProps extends ChannelGroupInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const ChannelGroupField: FC<ChannelGroupFieldProps> = (props) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <ChannelGroupInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalChannelGroupField: FC<ChannelGroupFieldProps> = (
  props
) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <ChannelGroupInput {...rest} />
    </HorizontalField>
  );
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
