import { FC } from "react";
import {
  components,
  OmitProps,
  HealthPlanOptionModel,
  getHealthPlanOptionValue,
  getHealthPlanOptionLabel,
  useHealthPlanOptions,
} from "components/HealthPlanSelect";
import {
  HorizontalSelectField,
  SelectField,
  SelectFieldProps,
} from "components/formik/SelectField";
import {
  InputSelect,
  InputSelectProps,
  SelectInput,
  SelectInputProps,
} from "../SelectField";

export type InputHealthPlanSelectProps = Omit<
  InputSelectProps<HealthPlanOptionModel>,
  OmitProps
>;

export const InputHealthPlanSelect: FC<InputHealthPlanSelectProps> = (
  props
) => {
  const { healthPlanOptions, loading } = useHealthPlanOptions();

  return (
    <InputSelect<HealthPlanOptionModel>
      {...props}
      value={props.value}
      onChange={props.onChange}
      options={healthPlanOptions}
      getOptionLabel={getHealthPlanOptionLabel}
      getOptionValue={getHealthPlanOptionValue}
      isLoading={loading || props.isLoading}
      components={
        props.components ? { ...props.components, ...components } : components
      }
    />
  );
};

export type HealthPlanSelectInputProps = Omit<
  SelectInputProps<HealthPlanOptionModel>,
  OmitProps
>;

export const HealthPlanSelectInput: FC<HealthPlanSelectInputProps> = (
  props
) => {
  const { healthPlanOptions, loading } = useHealthPlanOptions();

  return (
    <SelectInput<HealthPlanOptionModel>
      {...props}
      name={props.name}
      options={healthPlanOptions}
      getOptionLabel={getHealthPlanOptionLabel}
      getOptionValue={getHealthPlanOptionValue}
      isLoading={loading || props.isLoading}
      components={
        props.components ? { ...props.components, ...components } : components
      }
    />
  );
};

export type HealthPlanSelectFieldProps = Omit<
  SelectFieldProps<HealthPlanOptionModel>,
  OmitProps
>;

export const HealthPlanSelectField: FC<HealthPlanSelectFieldProps> = (
  props
) => {
  const { healthPlanOptions, loading } = useHealthPlanOptions();

  return (
    <SelectField<HealthPlanOptionModel>
      {...props}
      name={props.name}
      label={props.label}
      options={healthPlanOptions}
      getOptionLabel={getHealthPlanOptionLabel}
      getOptionValue={getHealthPlanOptionValue}
      isLoading={loading || props.isLoading}
      components={
        props.components ? { ...props.components, ...components } : components
      }
    />
  );
};

export const HorizontalHealthPlanSelectField: FC<HealthPlanSelectFieldProps> = (
  props
) => {
  const { healthPlanOptions, loading } = useHealthPlanOptions();

  return (
    <HorizontalSelectField<HealthPlanOptionModel>
      {...props}
      name={props.name}
      label={props.label}
      options={healthPlanOptions}
      getOptionLabel={getHealthPlanOptionLabel}
      getOptionValue={getHealthPlanOptionValue}
      isLoading={loading || props.isLoading}
      components={
        props.components ? { ...props.components, ...components } : components
      }
    />
  );
};
