import React, { FC, createContext, useContext } from "react";
import { TimeRange } from "./model";

interface State {
  timeRange: TimeRange;
}

const reportsContext = createContext<State>(null!);

interface ReportsProviderProps {
  timeRange: TimeRange;
}

export const ReportsProvider: FC<ReportsProviderProps> = (props) => {
  const { timeRange, children } = props;

  return (
    <reportsContext.Provider value={{ timeRange }}>
      {children}
    </reportsContext.Provider>
  );
};

export function useReports() {
  const context = useContext(reportsContext);
  if (context === undefined) {
    throw new Error(`useReports must be used within a ReportsProvider`);
  }
  return context;
}
