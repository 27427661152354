import { gql, useQuery } from "@apollo/client";

const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    featureFlags {
      name
      enabled
    }
  }
`;

interface Data {
  featureFlags: { name: string; enabled: boolean }[];
}

export function useEnabledFeatures(): string[] {
  const { data } = useQuery<Data>(GET_FEATURE_FLAGS);
  return (
    data?.featureFlags
      .filter((flag) => flag.enabled)
      .map((flag) => flag.name) || []
  );
}
