import React, { FC } from "react";
import { components, OptionProps } from "react-select";
import { FlatHealthPlanModel } from "./model";

const Badge: FC<{ className?: string }> = (props) => {
  const { className = "", children } = props;
  return <span className={`text-xs ${className}`}>{children}</span>;
};

export const HealthPlanOption: FC<
  OptionProps<FlatHealthPlanModel, false>
> = React.memo((props) => {
  return (
    <components.Option {...props}>
      <div className="flex">
        <p className="flex-grow">{props.data.displayName}</p>
        <Badge>{props.data.caseSystems.join(", ")}</Badge>
      </div>
    </components.Option>
  );
});
