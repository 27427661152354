import { Redirect, Route, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { NoMatchScreen } from "screens/NoMatchScreen";
import { UnauthenticatedApp } from "./UnauthenticatedApp";
import { InternalPortal } from "./InternalPortal";
import { ExternalPortal } from "./ExternalPortal";
import { HealthPlanPortal } from "./HealthPlanPortal";
// import { LandingPage } from "screens/LandingPage";
import { PrivateRoute } from "components/PrivateRoute";
import "./App.css";
import { SocketProvider } from "context/ChannelContext";
import { useAuth } from "context/AuthContext";
import { OAuthCallbackScreen } from "screens/OAuthCallbackScreen";
import { LogStreamProvider } from "context/LogStreamContext";
import { TokenRefresher } from "components/TokenRefresher";
import { IdleTimeoutHandler } from "components/IdleTimeoutHandler/IdleTimeoutHandler";

const websocketHost = process.env.REACT_APP_WEBSOCKET_HOST
  ? process.env.REACT_APP_WEBSOCKET_HOST
  : "";

const websocketEndpoint = `${websocketHost}/socket`;

function AuthenticatedApp() {
  const { token } = useAuth();

  return (
    <SocketProvider endpoint={websocketEndpoint} token={token!.jwt}>
      <LogStreamProvider>
        <InternalPortal />
        <TokenRefresher />
        <IdleTimeoutHandler />
      </LogStreamProvider>
    </SocketProvider>
  );
}

function App() {
  return (
    <div className="_App antialiased">
      <Switch>
        <Route exact path="/">
          {/* <LandingPage /> */}
          <Redirect to="/auth/sign_in" />
        </Route>
        <Route exact path="/oauth/:provider/callback">
          <OAuthCallbackScreen />
        </Route>
        <PrivateRoute path="/ip/">
          <AuthenticatedApp />
        </PrivateRoute>
        <Route path="/auth">
          <UnauthenticatedApp />
        </Route>

        {/* TODO: Make these their own apps. */}
        <Route path="/ep/">
          <ExternalPortal />
        </Route>
        <Route path="/hpp/">
          <HealthPlanPortal />
        </Route>
        <Route path="*">
          <NoMatchScreen />
        </Route>
      </Switch>
      <Toaster position="top-center" />
    </div>
  );
}

export default App;
