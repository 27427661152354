import React, { FC } from "react";
import { Drawer, DrawerHeader } from "components/Drawer";

interface DebugJSONDrawerProps {
  isOpen: boolean;
  onClose(): void;
  json: void | string | JSONObject;
}

export const DebugJSONDrawer: FC<DebugJSONDrawerProps> = (props) => {
  const { isOpen, onClose, json } = props;

  const jsonData: JSONObject | void =
    typeof json === "string" ? JSON.parse(json) : json;

  return (
    <Drawer isOpen={isOpen} onRequestClose={onClose} width="md">
      <DrawerHeader icon="code" title="Raw JSON Response" onClose={onClose} />
      <div className="_DebugJSONDrawer p-2">
        <pre className="p-2 text-xs leading-relaxed text-green-500 bg-gray-800 rounded-lg shadow">
          {JSON.stringify(jsonData, null, 2)}
        </pre>
      </div>
    </Drawer>
  );
};
