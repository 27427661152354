import { FC } from "react";
import { CheckCircleIcon } from '@heroicons/react/24/outline'

interface ActivityEligibilityProps {
  allowed: boolean;
  label: string;
  className?: string;
}

export const ActivityEligibility: FC<ActivityEligibilityProps> = (props) => {
  const { allowed, label, className = "" } = props;

  const iconClassNames = allowed ? "text-green-500" : "text-red-500";
  const labelClassNames = allowed ? "text-green-800" : "text-red-800";

  return (
    <div
      className={`_ActivityEligibility flex items-center gap-2 text-sm ${className}`}
    >
      <CheckCircleIcon className={`h-5 w-5 ${iconClassNames}`} />
      <p className={`font-semibold ${labelClassNames}`}>{label}</p>
    </div>
  );
};
