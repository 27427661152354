import { forwardRef, HTMLAttributes } from "react";
import { cn } from "lib/utils";

/**
 * TableContainer.
 */

type TableContainerProps = HTMLAttributes<HTMLDivElement>;

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
  (props, ref) => {
    const { className = "", ...rest } = props;
    return (
      <div className={cn("flex flex-col", className)}>
        <div className="sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 py-2 -my-2 overflow-x-auto">
          <div
            className="sm:rounded-lg inline-block min-w-full overflow-hidden align-middle border border-gray-200 shadow-md"
            ref={ref}
            {...rest}
          />
        </div>
      </div>
    );
  }
);

/**
 * Table.
 */

type TableProps = HTMLAttributes<HTMLTableElement>;

export const Table = forwardRef<HTMLTableElement, TableProps>((props, ref) => {
  const { className = "", ...rest } = props;
  return (
    <table className={cn("_Table min-w-full", className)} ref={ref} {...rest} />
  );
});

/**
 * TH.
 */

type THProps = HTMLAttributes<HTMLTableHeaderCellElement>;

export const TH = forwardRef<HTMLTableHeaderCellElement, THProps>(
  (props, ref) => {
    const { className = "", ...rest } = props;
    return (
      <th
        className={cn("px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider truncate", className)}
        ref={ref}
        {...rest}
      />
    );
  }
);

/**
 * TD.
 */

type TDProps = HTMLAttributes<HTMLTableCellElement>;

export const TD = forwardRef<HTMLTableCellElement, TDProps>((props, ref) => {
  const { className = "", ...rest } = props;
  return (
    <td
      className={cn("px-6 py-4 whitespace-no-wrap border-t border-gray-200 text-sm leading-5", className)}
      ref={ref}
      {...rest}
    />
  );
});
