import { FC } from "react";
import { AppealRequestEvent } from "./AppealRequestEvent";
import { AppealRequestModel } from "./CaseProfileShowScreen";

interface ActivityLogProps {
  appealRequest: AppealRequestModel;
};

export const ActivityLog: FC<ActivityLogProps> = props => {
  const { appealRequest } = props;
  const { appealRequestEvents } = appealRequest;

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {appealRequestEvents.map((appealReqEvt, idx) => (
          <AppealRequestEvent
            key={appealReqEvt.id}
            showLine={idx < appealRequestEvents.length - 1}
            appealRequestEvent={appealReqEvt}
          />
        ))}
      </ul>
    </div>
  );
};
