import { FC, useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { ScreenTitle } from "context/ScreenTitle";
import { Spinner } from "components/Spinner";
import { CircleIconHeader } from "components/CircleIconHeader";
import { Container } from "components/Container";
import { gql, useMutation, useQuery } from "@apollo/client";
import { DropdownButton, DropdownItemButton } from "components/DropdownButton";
import { AssignMDDrawer } from "./AssignMDDrawer";
import { DetailItem, DetailList } from "components/DetailList";
import { BackLink } from 'components/BackLink';
import { CopyText } from "components/CopyText";
import { ActivityEligibility } from "components/ActivityEligibility";
import { mmDdYyyy } from "utils/dateFormatters";
import { Sequence } from "../AppealShowScreen/Sequence";
import { toast } from "react-hot-toast";
import { JsonDebugger } from "components/JsonDebugger";
import { sortBy } from "utils/sortBy";
import { LogVerbalNotificationDrawer } from "./LogVerbalNotificationDrawer";
import { CloseRequestModal } from "./CloseRequestModal";
import { AppealRequestEventModel } from "./AppealRequestEvent";
import { ActivityLog } from "./ActivityLog";
import { P2PAppointmentList } from "components/P2PAppointmentList";
import { AssignPanelDrawer } from "./AssignPanelDrawer";
import { FAIcon } from "components/FAIcon";
import { Badge } from "components/Badge";
import { CancelPanelAppointmentModal } from "./CancelPanelAppointmentModal";
import { AssignUMReviewerDrawer } from "./AssignUMReviewerDrawer/AssignUMReviewerDrawer";

const CASE_PROFILE = gql`
  query GetCaseProfile($id: UUID4!) {
    caseProfile(id: $id) {
      id
      caseNumber
      episodeId
      memberId
      memberFirstName
      memberLastName
      memberDob
      dateDenied
      levelOfReview
      caseSystem
      allowedProviderDomainIds
      excludedProviderDomainIds
      pathwaySkillIds
      sameSpecialtyMatchRequired
      sameStateLicensureRequired
      insurancePlanCode
      initialMdReviewer
      icd10
      cptCodes
      healthPlan {
        id
        name
      }
      modality {
        id
        name
      }
      memberState {
        id
        abbreviation
        name
      }
      orderingPhysicianSpecialty {
        id
        name
      }
      sameStateLicensureState {
        id
        name
        abbreviation
      }
      umRequest {
        id
        dueAt
        warningMessage
        warningAt
        warningAtString
        assignedPhysician {
          id
          firstName
          lastName
          nameWithAppellation
        }
        insertedAt
        umRequestEvents {
          id
          type
          insertedAt
          rollupId
          agent {
            id
            firstName
            lastName
            email
          }
          data {
            __typename
            ... on UmRequestMdAssignedEventData {
              eventType
              providerId
              providerName
              sslMatch
              ssMatch
            }

            ... on UmRequestMdAssignmentFailedEventData {
              eventType
              providerId
              providerName
              searchFilter
              reason
              sslMatch
              ssMatch
            }
          }
        }
      }
      currentAppealRequest {
        id
        evicoreCaseStatus
      }
      caseEvents {
        id
        caseNumber
        eventName
        payload
        insertedAt
        insertedAtString(timeZone: "US/Eastern")
      }
      appealRequests {
        id
        isDraft
        isUrgent
        channel
        source
        stage
        episodeId
        requestedAt
        requestedAtString(timeZone: "US/Eastern")
        dueAt
        dueAtString(timeZone: "US/Eastern")
        unpendAt
        unpendAtString(timeZone: "US/Eastern")
        warningMessage
        warningAt
        warningAtString(timeZone: "US/Eastern")
        assignedPhysician {
          id
          firstName
          lastName
          nameWithAppellation
        }
        panelAppointments {
          id
          status
          startTime
          startTimeString
          finishTime
          finishTimeString
          duration
          additionalNotes
          providers {
            id
            nameWithAppellation
            email
          }
          insertedAt
          insertedAtString(timeZone: "US/Eastern")
        }
        insertedAt
        insertedAtString(timeZone: "US/Eastern")
        appealRequestEvents {
          id
          type
          insertedAt
          insertedAtString(timeZone: "US/Eastern")
          rollupId
          agent {
            id
            firstName
            lastName
            email
          }
          data {
            __typename
            ... on AppealRequestCasePendedEventData {
              eventType
              reason
            }

            ... on AppealRequestCaseUnpendedEventData {
              eventType
              trigger
            }

            ... on AppealRequestCommentAddedEventData {
              eventType
              comment
              visibility
            }

            ... on AppealRequestIntakeQuestionsSavedEventData {
              eventType
              qualifiers
              response
            }

            ... on AppealRequestMdAssignedEventData {
              eventType
              providerId
              providerName
            }

            ... on AppealRequestMdAssignmentFailedEventData {
              eventType
              providerId
              providerName
              searchFilter
              reason
            }

            ... on AppealRequestRequestClosedEventData {
              eventType
            }

            ... on AppealRequestVerbalNotificationFailedEventData {
              eventType
              journalNote
              historyMessage
              error
            }

            ... on AppealRequestVerbalNotificationSavedEventData {
              eventType
              journalNote
              historyMessage
            }
          }
        }
      }
      insertedAt
      insertedAtString(timeZone: "US/Eastern")
      updatedAt
      updatedAtString(timeZone: "US/Eastern")
    }
  }
`;

interface Data {
  caseProfile: CaseProfileModel;
}

interface CaseProfileModel {
  id: string;
  caseNumber: string;
  episodeId: string;
  memberId: null | string;
  memberFirstName: string;
  memberLastName: string;
  memberDob: string;
  dateDenied: string;
  levelOfReview: null | string;
  caseSystem: string;
  allowedProviderDomainIds: string[] | null;
  excludedProviderDomainIds: string[] | null;
  pathwaySkillIds: string[] | null;
  sameSpecialtyMatchRequired: boolean;
  sameStateLicensureRequired: boolean;
  insurancePlanCode: string | null;
  initialMdReviewer: string | null;
  icd10: string | null;
  cptCodes: string[] | null;
  healthPlan: {
    id: string;
    name: string;
  };
  modality: {
    id: string;
    name: string;
  };
  memberState: {
    id: string;
    abbreviation: string;
    name: string;
  };
  orderingPhysicianSpecialty: {
    id: string;
    name: string;
  } | null;
  sameStateLicensureState: {
    id: string;
    name: string;
    abbreviation: string;
  } | null;
  umRequest: {
    id: string;
    dueAt: string;
    warningMessage: null | string;
    warningAt: null | string;
    warningAtString: null | string;
    assignedPhysician: null | {
      id: string;
      firstName: string;
      lastName: string;
      nameWithAppellation: string;
    };
    insertedAt: string;
    umRequestEvents: {
      id: string;
      type: string;
    }[];
  } | null;
  currentAppealRequest: {
    id: string;
    evicoreCaseStatus: string;
  } | null;
  appealRequests: AppealRequestModel[];
  insertedAt: string;
  insertedAtString: string;
  updatedAt: string;
  updatedAtString: string;
}

export interface AppealRequestModel {
  id: string;
  isDraft: boolean;
  isUrgent: boolean;
  channel: string;
  source: string;
  stage: string;
  episodeId: null | string;
  requestedAt: null | string;
  requestedAtString: null | string;
  dueAt: null | string;
  dueAtString: null | string;
  unpendAt: null | string;
  unpendAtString: null | string;
  assignedPhysician: null | {
    id: string;
    firstName: string;
    lastName: string;
    nameWithAppellation: string;
  };
  panelAppointments: PanelAppointment[];
  warningMessage: null | string;
  warningAt: null | string;
  warningAtString: null | string;
  appealRequestEvents: AppealRequestEventModel[];
  insertedAt: string;
  insertedAtString: string;
}

interface PanelAppointment {
  id: string;
  status: string;
  startTime: string;
  startTimeString: string;
  finishTime: string;
  finishTimeString: string;
  duration: number;
  additionalNotes: string;
  providers: {
    id: string;
    nameWithAppellation: string;
    email: string;
  }[];
  insertedAt: string;
  insertedAtString: string;
}

// const lors = [
//   "Initial Review",
//   "Reconsideration 1",
//   "Reconsideration 2",
//   "Appeal 1",
//   "Appeal 2",
//   "External Appeal"
// ].reverse();

const SYNC_CASE_DETAILS = gql`
  mutation SyncExternalCaseDetails($id: UUID4!) {
    syncExternalCaseDetails(id: $id) {
      errors {
        key
        message
      }
      caseProfile {
        id
      }
    }
  }
`;

interface SyncMutationData {
  syncExternalCaseDetails: {
    errors?: InputError[];
    caseProfile?: {
      id: string;
    }
  }
}

const CLEAR_WARNING = gql`
  mutation ClearCaseAppealRequestWarning($appealRequestId: UUID4!) {
    clearAppealRequestWarning(appealRequestId: $appealRequestId) {
      errors {
        key
        message
      }
      appealRequest {
        id
        warningMessage
        warningAt
        warningAtString(timeZone: "US/Eastern")
      }
    }
  }
`;

interface ClearWarningMutationData {
  clearAppealRequestWarning: {
    errors?: InputError[];
    appealRequest: {
      id: string;
      warningMessage: null | string;
      warningAt: null | string;
      warningAtString: null | string;
    }
  }
}

interface RouteParams {
  id: string;
}

type ActiveModal =
  | { type: "ASSIGN_MD", caseProfileId: string }
  | { type: "ASSIGN_UM_REVIEWER", caseProfileId: string }
  | { type: "ASSIGN_PANEL", caseProfileId: string }
  | { type: "LOG_VERBAL_NOTIFICATION", appealRequestId: string }
  | { type: "CLOSE_REQUEST", appealRequestId: string }
  | { type: "CANCEL_PANEL_APPOINTMENT", panelAppointmentId: string }

interface CaseProfileShowScreenProps { };

export const CaseProfileShowScreen: FC<CaseProfileShowScreenProps> = props => {
  const { params: { id: caseProfileId } } = useRouteMatch<RouteParams>();

  // State
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null)
  const closeModal = useCallback(() => setActiveModal(null), []);

  // Query
  const { data, loading, error, refetch } = useQuery<Data>(CASE_PROFILE, { variables: { id: caseProfileId } });

  const panelAppointments = data?.caseProfile.appealRequests.flatMap(ar => ar.panelAppointments).sort((a, b) => a.insertedAt.localeCompare(b.insertedAt)) || [];

  // Mutations
  const [syncCaseDetailsMut, { loading: isSyncing }] = useMutation<SyncMutationData>(SYNC_CASE_DETAILS)
  const [clearWarningMut, { loading: isClearingWarning }] = useMutation<ClearWarningMutationData>(CLEAR_WARNING);

  const syncCaseDetails = useCallback(async () => {
    try {
      const { data } = await syncCaseDetailsMut({ variables: { id: caseProfileId } });
      if (data?.syncExternalCaseDetails.errors) {
        toast.error(data.syncExternalCaseDetails.errors[0].message);
      } else if (data?.syncExternalCaseDetails.caseProfile) {
        // it worked...
        toast.success("Case Sync'd");
        refetch();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.")
    }
  }, [caseProfileId, syncCaseDetailsMut, refetch])

  const clearWarning = useCallback(async (appealRequestId: string) => {
    try {
      const { data } = await clearWarningMut({ variables: { appealRequestId } });
      if (data?.clearAppealRequestWarning.errors) {
        toast.error(data.clearAppealRequestWarning.errors[0].message);
      } else if (data?.clearAppealRequestWarning.appealRequest) {
        // it worked...
        toast.success("Warning cleared");
        refetch();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.")
    }
  }, [clearWarningMut, refetch]);

  return (
    <>
      <ScreenTitle title="View Case" />
      <AssignMDDrawer
        isOpen={activeModal?.type === "ASSIGN_MD"}
        caseProfileId={caseProfileId}
        onClose={closeModal}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />
      <AssignUMReviewerDrawer
        isOpen={activeModal?.type === "ASSIGN_UM_REVIEWER"}
        caseProfileId={caseProfileId}
        onClose={closeModal}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />
      <AssignPanelDrawer
        isOpen={activeModal?.type === "ASSIGN_PANEL"}
        caseProfileId={caseProfileId}
        onClose={closeModal}
        onBookSuccess={() => {
          refetch();
          closeModal();
        }}
      />
      <CancelPanelAppointmentModal
        isOpen={activeModal?.type === "CANCEL_PANEL_APPOINTMENT"}
        panelAppointmentId={activeModal?.type === "CANCEL_PANEL_APPOINTMENT" ? activeModal.panelAppointmentId : null}
        onClose={closeModal}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />
      <LogVerbalNotificationDrawer
        isOpen={activeModal?.type === "LOG_VERBAL_NOTIFICATION"}
        appealRequestId={activeModal?.type === "LOG_VERBAL_NOTIFICATION" ? activeModal.appealRequestId : null}
        onClose={closeModal}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />
      <CloseRequestModal
        isOpen={activeModal?.type === "CLOSE_REQUEST"}
        appealRequestId={activeModal?.type === "CLOSE_REQUEST" ? activeModal.appealRequestId : null}
        onClose={closeModal}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />
      <Container className="_CaseProfileShowScreen">
        <BackLink
          to="/ip/appeals"
          label="Back to Case Inventory"
        />
        <div className="rounded-2xl flex-grow px-4 pt-4 pb-8 mt-5 bg-white border shadow-2xl">
          <CircleIconHeader icon="envelope-open-text">
            Case {data?.caseProfile.caseNumber ? (
              <>
                <span className="text-gray-400">#{data?.caseProfile.caseNumber}</span>
                <span className="ml-2 font-normal text-gray-400">(ImageOne)</span>
              </>
            ) : null}
          </CircleIconHeader>
          {loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.caseProfile ? (
            <p>Failed to load.</p>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              <div className="_CaseDetails">
                <div className="px-4 py-6 mx-4 mt-6 border rounded-lg shadow-lg">
                  <div className="flex justify-between pb-8">
                    <div>
                      <h3 className="text-xl font-semibold">Case Details</h3>
                    </div>
                    <DropdownButton label="Actions">
                      <DropdownItemButton onClick={syncCaseDetails} isLoading={isSyncing}>
                        Sync Case Details
                      </DropdownItemButton>
                      {
                        data.caseProfile.umRequest && !data.caseProfile.currentAppealRequest ? (
                          <DropdownItemButton onClick={() => setActiveModal({ type: "ASSIGN_UM_REVIEWER", caseProfileId })}>
                            Assign MD
                          </DropdownItemButton>
                        ) : null
                      }
                      {
                        data.caseProfile.currentAppealRequest ? (
                          <>
                            <DropdownItemButton onClick={() => setActiveModal({ type: "ASSIGN_MD", caseProfileId })}>
                              Assign MD
                            </DropdownItemButton>
                            <DropdownItemButton onClick={() => setActiveModal({ type: "ASSIGN_PANEL", caseProfileId })}>
                              View Available Panels
                            </DropdownItemButton>
                            <DropdownItemButton onClick={() => setActiveModal({ type: "LOG_VERBAL_NOTIFICATION", appealRequestId: data.caseProfile.currentAppealRequest!.id })}>
                              Log Verbal Notification
                            </DropdownItemButton>
                            <DropdownItemButton color="red" onClick={() => setActiveModal({ type: "CLOSE_REQUEST", appealRequestId: data.caseProfile.currentAppealRequest!.id })}>
                              Close Post-Decision Request
                            </DropdownItemButton>
                          </>
                        ) : null
                      }
                    </DropdownButton>
                  </div>
                  <div>
                    <h4 className="px-2 pt-1 pb-3 text-sm font-bold text-gray-600">
                      P2P Appointments
                    </h4>
                    <P2PAppointmentList caseNumber={data.caseProfile.caseNumber} />
                    <h4 className="px-2 pt-1 pb-3 mt-8 text-sm font-bold text-gray-600">
                      Panel Review Appointments
                    </h4>
                    <PanelAppointmentList
                      panelAppointments={panelAppointments}
                      onCancelClick={(panelAppointmentId) => setActiveModal({ type: "CANCEL_PANEL_APPOINTMENT", panelAppointmentId })}
                    />

                    <h4 className="px-2 py-5 text-sm font-bold text-gray-600">
                      Case Information
                    </h4>
                    <DetailList className="pl-4">
                      <DetailItem label="Case Ref #">
                        <CopyText text={data.caseProfile.caseNumber} />
                      </DetailItem>
                      {/* <DetailItem label="Episode ID">
                        <CopyText text={data.caseProfile.episodeId} />
                      </DetailItem> */}
                      <DetailItem label="Case Status">
                        {data.caseProfile.currentAppealRequest?.evicoreCaseStatus || "-"}
                      </DetailItem>
                      <DetailItem label="Modality">
                        {data.caseProfile.modality?.name}
                      </DetailItem>
                      <DetailItem label="Case System">
                        {data.caseProfile.caseSystem}
                      </DetailItem>
                      <DetailItem label="ICD10">
                        {data.caseProfile.icd10 ? (
                          <CopyText text={data.caseProfile.icd10} />
                        ) : (
                          <span className="text-gray-500">-</span>
                        )}
                      </DetailItem>
                      <DetailItem label="CPT Codes">
                        {data.caseProfile.cptCodes && data.caseProfile.cptCodes.length ? (
                          <ul className="pl-4 list-disc">
                            {data.caseProfile.cptCodes.map(cpt => (
                              <li key={cpt}>
                                {cpt}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <span className="text-gray-500">-</span>
                        )}
                      </DetailItem>
                      <DetailItem label="Initial MD Reviewer">
                        {data.caseProfile.initialMdReviewer || "-"}
                      </DetailItem>
                    </DetailList>

                    <h4 className="px-2 pt-1 pb-5 mt-8 text-sm font-bold text-gray-600">
                      Member Information
                    </h4>
                    <DetailList className="pl-4">
                      <DetailItem label="Patient Name">
                        {data.caseProfile.memberFirstName} {data.caseProfile.memberLastName}
                      </DetailItem>
                      <DetailItem label="DOB">
                        {mmDdYyyy(data.caseProfile.memberDob)}
                      </DetailItem>
                      <DetailItem label="State">
                        {data.caseProfile.memberState.name}
                      </DetailItem>
                      <DetailItem label="Health Plan">
                        {data.caseProfile.healthPlan.name}
                      </DetailItem>
                      <DetailItem label="Member ID">{data.caseProfile.memberId || "-"}</DetailItem>
                    </DetailList>
                  </div>
                  <JsonDebugger data={data} />
                </div>
              </div>

              {
                data.caseProfile.umRequest ? (
                  <div className="_UmRequests pt-8">
                    <h3 className="mb-6 text-xl font-semibold">UM Reviews</h3>
                    {
                      data.caseProfile.umRequest.assignedPhysician ? (
                        <p>Assigned Reviewer: {data.caseProfile.umRequest.assignedPhysician.nameWithAppellation}</p>
                      ) : (
                        <p>No assigned MD</p>
                      )
                    }
                  </div>
                ) : null
              }

              <div className="_PostDecisionRequests pt-8">
                <h3 className="mb-6 text-xl font-semibold">Post-Decision Requests</h3>
                <Sequence.Container>
                  {
                    data.caseProfile.appealRequests.length === 0 ? (
                      <div className="bg-gray-50 p-12 rounded-md">
                        <p className="text-center text-gray-700">No Post-Decision Requests</p>
                      </div>
                    ) : descending(data.caseProfile.appealRequests).map((ar, idx) => (
                      <Sequence.Step
                        key={ar.id}
                        isComplete={idx > 0}
                        isCurrent={idx === 0}
                        label={`Post-Decision Request - ${mmDdYyyy(ar.requestedAt || ar.insertedAt)}`}
                        trailingLine={idx < data.caseProfile.appealRequests.length - 1}
                      >
                        {/* trailingLine={idx < data.caseProfile.appealRequests.length - 1} */}
                        <div className="w-full px-4 py-2 mt-2 text-xs text-gray-600 border rounded shadow">
                          <div className="flex justify-between pt-2 pb-4">
                            <div />
                            <DropdownButton label="Actions">

                              {!!ar.warningMessage ? (
                                <DropdownItemButton onClick={() => clearWarning(ar.id)} isLoading={isClearingWarning}>
                                  Clear Warning
                                </DropdownItemButton>
                              ) : null}
                              <DropdownItemButton color="red" onClick={() => setActiveModal({ type: "CLOSE_REQUEST", appealRequestId: ar.id })}>
                                Close Post-Decision Request
                              </DropdownItemButton>
                            </DropdownButton>
                          </div>
                          <DetailList>
                            {
                              !!ar.warningMessage ? (
                                <DetailItem label="Warning">
                                  <span className="font-semibold text-red-800">{ar.warningMessage}</span>
                                </DetailItem>
                              ) : null
                            }
                            {
                              !!ar.warningAtString ? (
                                <DetailItem label="Warning At">
                                  {ar.warningAtString}
                                </DetailItem>
                              ) : null
                            }
                            <DetailItem label="Episode Id">
                              {ar.episodeId ? (
                                <CopyText text={ar.episodeId} />
                              ) : "-"}
                            </DetailItem>
                            <DetailItem label="Urgent?">
                              {ar.isUrgent ? "Yes" : "No"}
                            </DetailItem>
                            <DetailItem label="Source">
                              <span className="capitalize">{ar.source || "-"}</span>
                            </DetailItem>
                            <DetailItem label="Channel">
                              <span className="capitalize">{ar.channel || "-"}</span>
                            </DetailItem>
                            <DetailItem label="Requested At">
                              {ar.requestedAtString || ar.insertedAtString}
                            </DetailItem>
                            <DetailItem label="Due At">
                              {ar.dueAtString ? (
                                <span>{ar.dueAtString}</span>
                              ) : "-"}
                            </DetailItem>
                            {
                              ar.unpendAtString ? (
                                <DetailItem label="Pend Until">
                                  {ar.unpendAtString}
                                </DetailItem>
                              ) : null
                            }
                            <DetailItem label="Stage">
                              <span className="capitalize">{ar.stage || "-"}</span>
                            </DetailItem>
                            <DetailItem label="Assigned MD">
                              {ar.assignedPhysician?.nameWithAppellation || "-"}
                            </DetailItem>
                          </DetailList>

                          <div className="_ActivityPanel">
                            <div className="_ActivityLogContainer max-w-xl px-4 pt-4 pb-6 mx-auto">
                              <ActivityLog appealRequest={ar} />
                            </div>
                          </div>
                          <JsonDebugger data={ar} />
                        </div>
                      </Sequence.Step>
                    ))
                  }
                </Sequence.Container>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

function descending(appealRequests: Data["caseProfile"]["appealRequests"]): Data["caseProfile"]["appealRequests"] {
  return sortBy(appealRequests, "insertedAt");
}


function PanelAppointmentList(props: { panelAppointments: PanelAppointment[], onCancelClick(panelAppointmentId: string): void }) {
  const { panelAppointments, onCancelClick } = props;
  if (panelAppointments.length === 0) {
    return (
      <div className="flex justify-center py-5">
        <ActivityEligibility
          className="mt-1"
          allowed
          label="No scheduled P2Ps"
        />
      </div>
    )
  }
  return (
    <div>
      {panelAppointments.map(appointment => (
        <div key={appointment.id} className="flex items-center px-4 py-3 m-2 border rounded-lg shadow-md">
          <div className="_P2PAppointmentList__appointment flex items-center justify-between flex-1">
            <div className="_info text-sm">

              <div className="flex items-center pt-1">
                <FAIcon icon={["far", "clock"]} className="mr-2 text-gray-500" />
                <p>{appointment.startTimeString}</p>
              </div>

              <div className="flex items-start pt-1">
                <FAIcon icon="users" className="mt-1 mr-2 text-gray-500" />
                <ul className="pl-3 list-disc">
                  {
                    appointment.providers.map((provider, idx) => (
                      <li key={provider.id} className={idx > 0 ? "mt-1" : ""}>
                        <p>{provider.nameWithAppellation}</p>
                        <p className="text-xs leading-tight text-gray-500">{provider.email}</p>
                      </li>
                    ))
                  }
                </ul>
              </div>

            </div>

            <div className="_status flex items-center pt-1 text-right">
              {
                appointment.status === "cancelled" ? (
                  <Badge color="red">Cancelled</Badge>
                ) : (
                  <>
                    <Badge color="blue">Scheduled</Badge>
                    <div className="ml-2">
                      <DropdownButton>
                        <DropdownItemButton color="red" onClick={() => onCancelClick(appointment.id)}>
                          Cancel Appointment
                        </DropdownItemButton>
                      </DropdownButton>
                    </div>
                  </>

                )
              }
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
